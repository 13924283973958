import { useState, useEffect } from "react";
import { Dialog, Grid, FormGroup, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import {
  psychologistLanguageTagName,
  psychologistGenderTagName,
  psychologistStatusTagName,
  sessionTypeTagName,
  useUserTagsQuery,
  useMultipleTagsQueries,
} from "@src/queries/tags";
import { usePsychologistThemesQuery } from "@src/queries/experts";
import { useThemesQuery } from "@src/queries/themes";
import { colors } from "@src/theme";
import { DialogActionButtons, DialogHeader } from "./DialogToolkit";
import TagSelector from "./TagSelector";
import CustomCheckbox from "./form/CustomCheckbox";

const useStyles = makeStyles(() => ({
  form: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    overflowX: "hidden",
    overflowY: "auto",
    minHeight: 500,
  },
  tagTypeClass: {
    color: colors.primaryBlue,
  },
}));

const UpdatePsychologistTagsDialog = ({
  client,
  refetchPsychologistsList = () => {},
  ...props
}) => {
  const { t } = useTranslation();

  const { taggedData } = useMultipleTagsQueries([
    psychologistStatusTagName,
    sessionTypeTagName,
    psychologistLanguageTagName,
    psychologistGenderTagName,
  ]);
  const psychologistStatusTags = taggedData[psychologistStatusTagName];
  const sessionTypeTags = taggedData[sessionTypeTagName];
  const languageTags = taggedData[psychologistLanguageTagName];
  const genderTags = taggedData[psychologistGenderTagName];

  const { data: allThemes } = useThemesQuery();
  const { psychologistThemes, savePsychologistThemes } =
    usePsychologistThemesQuery(client.id);
  const {
    userTags: userPsychologistStatusTags,
    saveTags: savePsychologistStatusTags,
  } = useUserTagsQuery(client.id, psychologistStatusTagName);
  const { userTags: userSessionTypeTags, saveTags: saveSessionTypeTags } =
    useUserTagsQuery(client.id, sessionTypeTagName);
  const { userTags: userLanguageTags, saveTags: saveLanguageTags } =
    useUserTagsQuery(client.id, psychologistLanguageTagName);
  const { userTags: userGenderTag, saveTags: saveGenderTag } = useUserTagsQuery(
    client.id,
    psychologistGenderTagName,
  );
  const classes = useStyles();
  const [psychologistStatus, setPsychologistStatus] = useState([]);
  const [psychologistSessionTypes, setPsychologistSessionTypes] = useState([]);
  const [psychologistLanguage, setPsychologistLanguage] = useState([]);
  const [psychologistGender, setPsychologistGender] = useState([]);
  const [selectedPsychologistThemes, setSelectedPsychologistThemes] = useState(
    [],
  );

  useEffect(() => {
    setPsychologistStatus(userPsychologistStatusTags.map((tag) => tag.name));
    setPsychologistSessionTypes(userSessionTypeTags.map((tag) => tag.name));
    setPsychologistLanguage(userLanguageTags.map((tag) => tag.name));
    setPsychologistGender(userGenderTag.map((tag) => tag.name));
    setSelectedPsychologistThemes(psychologistThemes.map((theme) => theme.key));
  }, [
    userPsychologistStatusTags,
    userSessionTypeTags,
    userLanguageTags,
    userGenderTag,
    psychologistThemes,
  ]);

  const formik = useFormik({
    initialValues: {
      psychologistState: psychologistStatus,
      psychologistGender,
    },
    onSubmit: async () => {
      Promise.all([
        saveLanguageTags(psychologistLanguage),
        saveGenderTag(psychologistGender),
        savePsychologistStatusTags(psychologistStatus),
        saveSessionTypeTags(psychologistSessionTypes),
        savePsychologistThemes(selectedPsychologistThemes),
      ]).then(() => {
        props.onClose();
        refetchPsychologistsList();
      });
    },
    enableReinitialize: true,
  });

  const onChangeTheme = (theme) => {
    if (selectedPsychologistThemes.indexOf(theme) === -1) {
      // its not a selected theme - select it
      setSelectedPsychologistThemes(selectedPsychologistThemes.concat(theme));
    } else {
      // its already selected - uncheck it
      setSelectedPsychologistThemes(
        selectedPsychologistThemes.filter(
          (selectedPsychologistTheme) => selectedPsychologistTheme !== theme,
        ),
      );
    }
  };

  return (
    <Dialog
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      onClose={() => {
        formik.handleReset();
        props.onClose();
      }}
    >
      <DialogHeader
        iconLigature="psychology"
        title={`${client.firstName}, ${client.lastName}`}
        outlined
      />
      <form onSubmit={formik.handleSubmit} className={classes.form} noValidate>
        <Grid container>
          <Grid item xs={6}>
            <TagSelector
              tagTypeName={t("Tags.PsychologistStatusTagsTitle")}
              tagState={psychologistStatus}
              setTagState={setPsychologistStatus}
              tagType={psychologistStatusTags}
              singleChoice
            />
          </Grid>
          <Grid item xs={6}>
            <TagSelector
              tagTypeName={t("Tags.SessionTypeTagsTitle")}
              tagState={psychologistSessionTypes}
              setTagState={setPsychologistSessionTypes}
              tagType={sessionTypeTags}
            />
            <TagSelector
              tagTypeName={t("Common.Gender")}
              tagState={psychologistGender}
              setTagState={setPsychologistGender}
              tagType={genderTags}
              singleChoice
              formik={formik}
              formikValue="psychologistGender"
            />
          </Grid>
          <Grid item xs={12}>
            <TagSelector
              tagTypeName={t("Tags.LanguageTagsTitle")}
              tagState={psychologistLanguage}
              setTagState={setPsychologistLanguage}
              tagType={languageTags}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption" className={classes.tagTypeClass}>
              {t("SharedStrings.Themes")}
            </Typography>
            <Grid container>
              <Grid item xs={6}>
                <FormGroup>
                  {allThemes
                    .slice(0, Math.ceil(allThemes.length / 2))
                    .map((theme) => (
                      <CustomCheckbox
                        t={t}
                        key={theme.key}
                        value={theme.key}
                        onCheck={onChangeTheme}
                        isChecked={
                          selectedPsychologistThemes.indexOf(theme.key) > -1
                        }
                      />
                    ))}
                </FormGroup>
              </Grid>
              <Grid item xs={6}>
                <FormGroup>
                  {allThemes
                    .slice(Math.ceil(allThemes.length / 2))
                    .map((theme) => (
                      <CustomCheckbox
                        t={t}
                        key={theme.key}
                        value={theme.key}
                        onCheck={onChangeTheme}
                        isChecked={
                          selectedPsychologistThemes.indexOf(theme.key) > -1
                        }
                      />
                    ))}
                </FormGroup>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
      <DialogActionButtons
        onCancel={() => {
          formik.handleReset();
          props.onClose();
        }}
        onSubmit={formik.submitForm}
      />
    </Dialog>
  );
};

export default UpdatePsychologistTagsDialog;
