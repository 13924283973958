import { PropTypes } from "prop-types";
import Autocomplete from "@mui/material/Autocomplete";
import { TextField } from "@mui/material";
import { useEmployersQuery } from "@src/queries/common";
import { withSuspense } from "../wrappers/Suspendable";

const EmployersAutocomplete = ({
  onChange,
  value,
  name,
  label,
  formik,
  ...props
}) => {
  const { data: employers } = useEmployersQuery();

  return (
    <Autocomplete
      {...props}
      options={employers.sort((a, b) => a.name.localeCompare(b.name))}
      getOptionDisabled={(option) => !option.active}
      getOptionLabel={(employer) => employer?.name || ""}
      onChange={(_, newValue) => onChange(newValue)}
      value={
        employers.find((employer) => employer.id === formik.values[name]) ||
        null
      }
      renderInput={(params) => (
        <TextField
          {...params}
          id={name}
          error={Boolean(formik.errors[name])}
          helperText={formik.errors[name]}
          label={label}
          variant="standard"
        />
      )}
    />
  );
};

EmployersAutocomplete.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  formik: PropTypes.any.isRequired,
};

export default withSuspense(EmployersAutocomplete);
