import { Divider, Paper, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  container: {
    padding: "20px 0px",
  },
  margin: {
    marginTop: 16,
  },
});

const ErrorPage = ({ message }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Paper elevation={3} className={classes.container}>
      <Typography variant="h4" align="center">
        {t("Common.GenericErrorMessage")}
      </Typography>

      <Divider className={classes.margin} />

      <Typography variant="subtitle1" align="center" className={classes.margin}>
        {message}
      </Typography>

      <Divider className={classes.margin} />

      <Typography variant="subtitle1" align="center" className={classes.margin}>
        Try reloading the page first! If this error persists, please report the
        issue by sending an email to techquestions@openup.com or through the
        #help-tech-psychologists slack channel with a screenshot of this error
        message :)
      </Typography>
    </Paper>
  );
};

export default ErrorPage;
