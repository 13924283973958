import { Box, IconButton, TableCell, TableRow } from "@mui/material";
import { makeStyles } from "@mui/styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { colors } from "@src/theme";
import TableCellNoData from "./TableCellNoData";

const useStyles = makeStyles((theme) => ({
  cellBorder: ({ open }) => {
    return {
      padding: theme.spacing(2, 0),
      border: 0,
      color: colors.primaryBlue,
      textDecoration: open && "underline",
      cursor: "pointer",
    };
  },
  iconColor: {
    color: colors.primaryBlue,
  },
}));

const ClientCheckinsOverviewEmptyTableCell = ({
  theme,
  message,
  index,
  open,
  setOpen,
}) => {
  const classes = useStyles({ open });

  return (
    <TableRow role="checkbox" tabIndex={-1} key={theme.key}>
      {index === 0 ? (
        <Box
          display="flex"
          alignItems="center"
          height={97}
          onClick={(event) => {
            event.stopPropagation(); // to prevent the onlick event of the parent (row)
            setOpen(!open);
          }}
        >
          <TableCell className={classes.cellBorder}>{message}</TableCell>
          <IconButton aria-label="expand row" size="small">
            {open ? (
              <KeyboardArrowUpIcon className={classes.iconColor} />
            ) : (
              <KeyboardArrowDownIcon className={classes.iconColor} />
            )}
          </IconButton>
        </Box>
      ) : open ? (
        <TableCell className={classes.cellBorder} />
      ) : null}
      {open ? (
        <TableCellNoData theme={theme} />
      ) : index === 0 ? (
        <TableCellNoData theme={theme} />
      ) : null}
    </TableRow>
  );
};

export default ClientCheckinsOverviewEmptyTableCell;
