import { Box, Divider, Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { SESSION_NOTES_SECTIONS } from "@src/utils/constants";
import { useTranslation } from "react-i18next";
import { alpha } from "@mui/material/styles";
import { colors } from "@src/theme";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { sessionHasNotes } from "@src/utils/helpers";
import "@mdxeditor/editor/style.css";
import {
  MDXEditor,
  headingsPlugin,
  listsPlugin,
  quotePlugin,
  thematicBreakPlugin,
  linkPlugin,
} from "@mdxeditor/editor";
import { Consult } from "@src/models/Consult";

const useStyle = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2.5, 3, 5, 3),
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(4),
    },
    width: "100%",
  },
  divider: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(3),
  },
  noNotes: {
    borderRadius: "12px",
    backgroundColor: alpha(colors.primaryDarkBlue, 0.03),
    width: "100%",
    height: "104px",
    borderStyle: "dashed",
    borderWidth: "1px",
    borderColor: alpha(colors.primaryDarkBlue, 0.12),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
      backgroundColor: alpha(colors.primaryBlue, 0.12),
      borderColor: alpha(colors.primaryBlue, 0.24),
    },
  },
}));

interface SessionNotesPaperProps {
  consult: Consult;
  openNotesDialog: () => void;
}

const markdownPlugins = [
  headingsPlugin(),
  listsPlugin(),
  quotePlugin(),
  thematicBreakPlugin(),
  linkPlugin(),
];
const hashCode = (input: string | null) => {
  if (!input) return 0;
  let hash = 0;
  let i;
  let chr;
  if (input.length === 0) return hash;
  for (i = 0; i < input.length; i += 1) {
    chr = input.charCodeAt(i);
    // eslint-disable-next-line no-bitwise
    hash = (hash << 5) - hash + chr;
    // eslint-disable-next-line no-bitwise
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};
const SessionNotesPaper: React.FC<SessionNotesPaperProps> = ({
  consult,
  openNotesDialog,
}) => {
  const classes = useStyle();
  const { t } = useTranslation();

  const sessionWithoutNotes = () => {
    return (
      <Box
        className={classes.noNotes}
        onClick={() => openNotesDialog()}
        data-cy="add-notes"
      >
        <DescriptionOutlinedIcon />
        <p className="sub2">{t("Client.Notes.CreateNotes")}</p>
      </Box>
    );
  };

  const sessionWithNotes = () => {
    return (
      <>
        <p className="mb-2 sub1">
          1. {t("Consults.SessionDetails.ThemeOfSession")}
        </p>
        <p className="mb-3 body2">{t(`Themes.${consult.themeKey}.Name`)}</p>
        <Divider light className={classes.divider} />
        <p className="mb-2 sub1">
          2. {t("Consults.SessionDetails.ReasonForSession")}
        </p>

        <MDXEditor
          className="mb-3 body2"
          key={hashCode(
            consult.dossierNotes?.find(
              (note) => note.question === SESSION_NOTES_SECTIONS.REASON_BOOK,
            )?.answer,
          )}
          markdown={
            consult.dossierNotes?.find(
              (note) => note.question === SESSION_NOTES_SECTIONS.REASON_BOOK,
            )?.answer
          }
          plugins={markdownPlugins}
          readOnly
        />
        <Divider light className={classes.divider} />
        <p className="mb-2 sub1">
          3. {t("Consults.SessionDetails.SessionDiscussed")}
        </p>
        <MDXEditor
          className="mb-3 body2"
          key={hashCode(
            consult.dossierNotes?.find(
              (note) =>
                note.question === SESSION_NOTES_SECTIONS.SESSION_DISCUSSED,
            )?.answer ?? consult.notes,
          )}
          markdown={
            consult.dossierNotes?.find(
              (note) =>
                note.question === SESSION_NOTES_SECTIONS.SESSION_DISCUSSED,
            )?.answer ?? consult.notes
          }
          plugins={markdownPlugins}
          readOnly
        />

        <Divider light className={classes.divider} />
        <p className="mb-2 sub1">
          4. {t("Consults.SessionDetails.HelpClient")}
        </p>
        <MDXEditor
          className="mb-3 body2"
          key={hashCode(
            consult.dossierNotes?.find(
              (note) => note.question === SESSION_NOTES_SECTIONS.PROVIDER,
            )?.answer,
          )}
          markdown={
            consult.dossierNotes?.find(
              (note) => note.question === SESSION_NOTES_SECTIONS.PROVIDER,
            )?.answer
          }
          plugins={markdownPlugins}
          readOnly
        />

        <Divider light className={classes.divider} />
        <p className="mb-2 sub1">5. {t("Consults.SessionDetails.NextSteps")}</p>
        <MDXEditor
          className="mb-3 body2"
          key={hashCode(
            consult.dossierNotes?.find(
              (note) => note.question === SESSION_NOTES_SECTIONS.NEXT_STEPS,
            )?.answer,
          )}
          markdown={
            consult.dossierNotes?.find(
              (note) => note.question === SESSION_NOTES_SECTIONS.NEXT_STEPS,
            )?.answer
          }
          plugins={markdownPlugins}
          readOnly
        />
      </>
    );
  };

  return (
    <Paper className={classes.paper} elevation={3} data-cy="session-notes">
      <h6 className="h6 mb-3">{t("Consults.SessionDetails.Notes")}</h6>
      {sessionHasNotes(consult) ? sessionWithNotes() : sessionWithoutNotes()}
    </Paper>
  );
};

export default SessionNotesPaper;
