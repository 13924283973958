import { useMutation, useQuery, useQueryClient } from "react-query";
import axios from "axios";
import { apiPaths } from "@src/constants";

const identifier = "external-questionnaires";
const endpoint = (clientId) => `${apiPaths.clients}/${clientId}/${identifier}`;

export const useQuestionnairesQuery = (clientId) =>
  useQuery(["clients", clientId, identifier], () =>
    axios.get(endpoint(clientId)).then(({ data }) => data),
  );

export const useQuestionnaireMutation = (clientId) => {
  const queryClient = useQueryClient();

  return useMutation(
    (questionnaire) => axios.post(endpoint(clientId), questionnaire),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["clients", clientId, identifier]);
      },
    },
  );
};
