import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { ThemeProvider, StyledEngineProvider } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import Page from "@src/components/Page";
import authentificationService, {
  useAuthentication,
} from "@src/services/authentication.service";
import theme from "@src/theme";
import WelcomePage from "./components/WelcomePage";
import FactorAuthentification from "./components/FactorAuthentification";
import GoogleAuthenticator from "./components/GoogleAuthenticator";
import RequestAccessDashboard from "./components/RequestAccessDashboard";

const STEPS = {
  ROOT: "login",
  WELCOME: "step1",
  FACTOR_AUTHENTIFICATION: "step2",
  REQUEST_PAGE: "step3",
};

const LoginView = () => {
  const { t } = useTranslation();
  const { isLoggedIn } = useAuthentication();
  let { step } = useParams();
  const navigate = useNavigate();
  const [twoFactorEnabled, setTwoFactorEnabled] = useState();

  useEffect(() => {
    setTwoFactorEnabled(authentificationService.twoFactorEnabled());
  }, [step]);

  if (isLoggedIn) {
    return <Navigate to="/" />;
  }

  step = Object.values(STEPS).find((value) => t(value) === step);

  const selectStep = (step) => {
    switch (step) {
      case STEPS.WELCOME:
        return (
          <WelcomePage
            moveToNextStep={() =>
              navigate(`/${t(STEPS.ROOT)}/${t(STEPS.FACTOR_AUTHENTIFICATION)}`)
            }
          />
        );
      case STEPS.FACTOR_AUTHENTIFICATION:
        if (twoFactorEnabled) {
          return (
            <GoogleAuthenticator
              moveToNextStep={() =>
                navigate(`/${t(STEPS.ROOT)}/${t(STEPS.REQUEST_PAGE)}`)
              }
            />
          );
        }
        return (
          <FactorAuthentification
            moveToNextStep={() =>
              navigate(`/${t(STEPS.ROOT)}/${t(STEPS.FACTOR_AUTHENTIFICATION)}`)
            }
            twoFactorEnabled={twoFactorEnabled}
            setTwoFactorEnabled={setTwoFactorEnabled}
          />
        );

      case STEPS.REQUEST_PAGE: {
        return <RequestAccessDashboard />;
      }
      default:
        return (
          <WelcomePage
            moveToNextStep={() =>
              navigate(`/${t(STEPS.ROOT)}/${t(STEPS.FACTOR_AUTHENTIFICATION)}`)
            }
          />
        );
    }
  };

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Page title={t("LoginView.Title")}>{selectStep(step)}</Page>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default LoginView;
