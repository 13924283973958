import { useEmployerReferralToolkitsQuery } from "@src/queries/employers";
import { apiPathsWithParams, USER_AVAILABLE_LANGUAGES } from "@src/constants";
import type { FC } from "react";
import Alert from "@openup/shared/components/Alert/Alert";
import { InfoOutlined } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { EmployerReferralToolkitFile } from "@src/models/EmployerReferralToolkit";
import ButtonOrLink from "@openup/shared/components/ButtonOrLink/ButtonOrLink";
import { getApiBaseUrl } from "@src/utils/urlHelpers";
import {
  WithSnackbarProps,
  withSnackbar,
} from "@src/components/SnackBarComponent";

interface EmployerReferralToolkitBannerProps extends WithSnackbarProps {
  employerId: string;
}
const EmployerReferralToolkitBanner: FC<EmployerReferralToolkitBannerProps> = ({
  employerId,
  snackbarShowMessage,
}) => {
  const { t } = useTranslation();
  const { data: employerReferralToolkits } =
    useEmployerReferralToolkitsQuery(employerId);

  const getFileLable = (languageCode: string) => {
    return USER_AVAILABLE_LANGUAGES.find(
      (language) => language.value === languageCode,
    )?.label;
  };

  if (!employerReferralToolkits) return null;

  if (!employerReferralToolkits.files?.length)
    snackbarShowMessage(t("EmployerReferralToolkit.Error.NotFound"), "error");

  return (
    <Alert icon={<InfoOutlined />} variant="info">
      {t("EmployerReferralToolkit.Banner.Header")}

      <ul>
        <li>{employerReferralToolkits?.description}</li>
        <li>
          {`${t("EmployerReferralToolkit.Banner.Title")}: `}
          {employerReferralToolkits.files?.map(
            (file: EmployerReferralToolkitFile) => (
              <ButtonOrLink
                key={file.fileName}
                className="cursor-pointer !underline text-white decoration-white mr-2"
                href={`${getApiBaseUrl()}${apiPathsWithParams.downloadEmployerRefferalToolkit(
                  employerId,
                  file.fileName,
                )}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {getFileLable(file.languageCode)}
              </ButtonOrLink>
            ),
          )}
        </li>
      </ul>
    </Alert>
  );
};

export default withSnackbar(EmployerReferralToolkitBanner);
