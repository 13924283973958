import { env } from "@src/env";
import { apiPaths } from "@src/constants";

export function getApiBaseUrl() {
  let hostUrl = window.location.host;
  const urlParts = new URL(`https://${hostUrl}`).hostname.split(".");

  if (urlParts.length > 1) {
    hostUrl = urlParts.slice(1).join(".");
    return `https://${env.REACT_APP_API_SUBDOMAIN}.${hostUrl}/api/`;
  }
  return `https://${hostUrl}/api/`;
}

export function getExternalLoginUrl() {
  return `${getApiBaseUrl()}${apiPaths.providerChallenge}`;
}
