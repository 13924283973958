import type { FC } from "react";
import { Box, Button, Typography, Dialog } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CancelIcon from "@mui/icons-material/Cancel";
import { useEffect, useState } from "react";
import { ReactComponent as WomanWorking } from "@src/components/images/woman_desk_laptop.svg";
import { alpha } from "@mui/material/styles";
import {
  useGetNotificationsQuery,
  useReadNotificationMutation,
} from "@src/queries/notifications";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme?: any) => ({
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: theme.spacing(2.5),
    paddingBottom: theme.spacing(0),
  },
  closeIcon: {
    color: theme.colors.primaryDarkBlue,
    "&:hover": {
      color: alpha(theme.colors.primaryDarkBlue, 0.1),
      cursor: "pointer",
    },
  },
  dialogHeader: {
    padding: theme.spacing(2.5),
  },
  titleTypography: {
    fontSize: "26px",
    fontWeight: 700,
    "line-height": "40px",
  },
  buttonTypography: {
    letterSpacing: 1.25,
  },
  contentContainer: {
    padding: theme.spacing(1, 2.5, 0, 2.5),
  },
  notificationText: {
    marginBottom: theme.spacing(3),
  },
  imageContainer: {
    display: "flex",
    justifyContent: "end",
    alignItems: "end",
    width: "100%",
    height: "100%",
  },
  image: {
    width: 143,
    height: 165,
  },
}));

const NotificationDialog: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [currentNotification, setCurrentNotification] = useState<any | null>();
  const { data: notifications } = useGetNotificationsQuery();
  const { mutateAsync: readNotificationMutation } =
    useReadNotificationMutation();

  const handleClose = (reason: any) => {
    if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
      onClose();
    }
  };

  useEffect(() => {
    if (notifications.length === 0) return;
    setCurrentNotification(notifications[0]);
  }, [notifications]);

  const onClose = async () => {
    await readNotificationMutation({ id: notifications[0].id });
    notifications.shift();

    if (notifications.length > 0) {
      setCurrentNotification(notifications[0]);
    } else {
      setCurrentNotification(null);
    }
  };

  return (
    <>
      {currentNotification ? (
        <Dialog
          open
          onClose={(event, reason) => handleClose(reason)}
          sx={{ "& .MuiPaper-root": { width: 364, height: 464 } }}
        >
          <Box className={classes.headerContainer}>
            <Typography variant="h3">
              {t(`Notifications.${currentNotification.key}.Title`)}
            </Typography>
            <CancelIcon className={classes.closeIcon} onClick={handleClose} />
          </Box>
          <Box className={classes.contentContainer}>
            <Typography
              variant="subtitle2"
              className={classes.notificationText}
            >
              {t(`Notifications.${currentNotification.key}.Content`)}
            </Typography>
            <Button variant="outlined" onClick={handleClose}>
              <Typography variant="subtitle2">Got it, thank you!</Typography>
            </Button>
          </Box>
          <Box className={classes.imageContainer}>
            <WomanWorking className={classes.image} />
          </Box>
        </Dialog>
      ) : (
        <></>
      )}
    </>
  );
};

export default NotificationDialog;
