import { createContext } from "react";
import { Theme } from "@src/models/Theme";

export const SessionDetailsContext = createContext<{
  allConsults: never[];
  allSessionThemes: Theme[];
  allSessionTypeTags: never[];
  allLanguageTags: never[];
  allPsychologists: never[];
  allTopicsTags: never[];
}>({
  allConsults: [],
  allSessionThemes: [],
  allSessionTypeTags: [],
  allLanguageTags: [],
  allPsychologists: [],
  allTopicsTags: [],
});

export const SessionNotesContext = createContext({
  setIsNewConsult: () => {},
  selectedConsultIndex: 0,
  totalNumberOfConsults: 0,
});
