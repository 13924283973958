/* eslint-disable react/prop-types */
import { InputBase } from "@mui/material";
import { makeStyles } from "@mui/styles";
import SearchIcon from "@mui/icons-material/Search";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  search: {
    position: "relative",
    borderRadius: 20,
    backgroundColor: "white",
    width: "100%",
    height: 48,
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
    height: 48,
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      // width: '12ch',
      // '&:focus': {
      //   width: '20ch',
      // },
    },
  },
}));

const SearchBox = ({
  onChange,
  placeholder = "Search...",
  className,
  refCancel,
}: {
  onChange: (string) => void;
  placeholder: string;
  className: string;
  refCancel?: Ref<any>;
}) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.search, className)}>
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>
      <InputBase
        name="search-bar"
        placeholder={placeholder}
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        inputProps={{ "aria-label": "search" }}
        onChange={(e) => onChange(e.target.value)}
        inputRef={refCancel}
      />
    </div>
  );
};

export default SearchBox;
