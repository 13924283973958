import { Button, Dialog, DialogContent } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { getCurrentUser } from "@src/services/authentication.service";
import PsychologistSelectorDialog from "./PsychologistSelectorDialog";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  formItem: {
    flexBasis: "100%",
    height: 72,
  },
  button: {
    borderRadius: 24,
    padding: theme.spacing(0, 4),
    textTransform: "none",
  },
  buttonColumn: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    flexBasis: "100%",
    "& button": {
      marginLeft: "auto",
    },
    "& button:last-child": {
      paddingRight: 0,
    },
  },
}));

const AgendaSelector = ({ clientId, ...props }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isSelectPsychologistOpen, setIsSelectPsychologistOpen] =
    useState(false);
  const currentUser = getCurrentUser();
  const psychologistId = currentUser.id;

  const handleBookSession = () => {
    navigate(`/book-session/${clientId}/${psychologistId}`);
  };

  const handleSelectPsychologist = () => {
    setIsSelectPsychologistOpen(true);
  };

  return (
    <>
      {isSelectPsychologistOpen && (
        <PsychologistSelectorDialog
          setIsOpen={setIsSelectPsychologistOpen}
          clientId={clientId}
        />
      )}

      <Dialog open={props.open} onClose={props.onClose} {...props.open}>
        <DialogContent>{t("AgendaSelection.Question")}</DialogContent>

        <div className={classes.buttonColumn}>
          <Button data-cy="my-agenda" onClick={() => handleBookSession()}>
            {t("AgendaSelection.MyAgenda")}
          </Button>
          <Button
            data-cy="other-agenda"
            onClick={() => handleSelectPsychologist()}
          >
            {t("AgendaSelection.ColleagueAgenda")}
          </Button>
          <Button onClick={props.onClose}>{t("AgendaSelection.Cancel")}</Button>
        </div>
      </Dialog>
    </>
  );
};

export default AgendaSelector;
