import axios from "axios";
import { EmployerReferralToolkit } from "@src/models";
import { useQuery } from "react-query";
import { apiPathsWithParams } from "@src/constants";

export const useEmployerReferralToolkitsQuery = (employerId: string) =>
  useQuery(
    ["referral-toolkit", employerId],
    async () => {
      const { data } = await axios.get<EmployerReferralToolkit>(
        apiPathsWithParams.getEmployerReferralToolkits(employerId),
      );
      return data;
    },
    { enabled: !!employerId },
  );
