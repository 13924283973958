import { useScrollTrigger, Slide, Toolbar, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import AppBarMui from "@mui/material/AppBar";
import LanguageSelector from "@src/components/LanguageSelector";
import OpenUp from "@src/components/logos/OpenUp";
import UserSettings from "@src/components/UserSettings";

const HideOnScroll = ({ children }) => {
  const trigger = useScrollTrigger();
  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    appbar: {
      zIndex: theme.zIndex.drawer + 1,
      filter: "drop-shadow(0px 0px 10px rgba(51, 51, 51, 0.1))",
      boxShadow: "none",
      padding: "0",
    },
    toolbar: {
      backgroundColor: "white",
      display: "flex",
      flexDirection: "row",
      padding: theme.spacing(0, 3),
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%",
    },
    logo: {
      height: 24,
    },
  }),
);

const AppBar = () => {
  const classes = useStyles();
  return (
    <HideOnScroll>
      <AppBarMui position="fixed" className={classes.appbar}>
        <Toolbar className={classes.toolbar}>
          <OpenUp className={classes.logo} />
          <Box display="flex" alignItems="center">
            <UserSettings />
            <LanguageSelector />
          </Box>
        </Toolbar>
      </AppBarMui>
    </HideOnScroll>
  );
};

export default AppBar;
