import { colors } from "./colors";

const typography = {
  h1: {
    fontSize: "49px",
    fontStyle: "normal",
    color: colors.primaryDarkBlue,
    fontWeight: 900,
    fontFamily: "'Noto Serif JP', serif",
    lineHeight: "64px",
  },
  h2: {
    fontSize: "35px",
    fontStyle: "normal",
    color: colors.primaryDarkBlue,
    fontWeight: 900,
    fontFamily: "'Noto Serif JP', serif",
    lineHeight: "48px",
    letterSpacing: "0.25px",
  },
  h3: {
    fontSize: "25px",
    fontStyle: "normal",
    color: colors.primaryDarkBlue,
    fontWeight: 900,
    fontFamily: "'Noto Serif JP', serif",
    lineHeight: "32px",
  },
  h4: {
    fontSize: "21px",
    fontStyle: "normal",
    color: colors.primaryDarkBlue,
    fontWeight: 900,
    fontFamily: "'Noto Serif JP', serif",
    lineHeight: "32px",
    letterSpacing: "0.25px",
  },
  h5: {
    fontSize: "20px",
    fontStyle: "normal",
    color: colors.primaryDarkBlue,
    fontWeight: 500,
    fontFamily: "Montserrat",
    lineHeight: "24px",
    letterSpacing: "0.15px",
  },
  h6: {
    fontSize: "20px",
    fontStyle: "normal",
    color: colors.primaryDarkBlue,
    fontWeight: 600,
    fontFamily: "Montserrat",
    lineHeight: "24px",
    letterSpacing: "0.15px",
  },
  subtitle1: {
    fontWeight: 500,
    fontSize: "16px",
    fontStyle: "normal",
    color: colors.primaryDarkBlue,
    fontFamily: "Montserrat",
    lineHeight: "24px",
    mixBlendMode: "normal",
    letterSpacing: "0.15px",
  },
  subtitle2: {
    fontWeight: 500,
    fontSize: "14px",
    color: colors.primaryDarkBlue,
    fontFamily: "Montserrat",
    fontStyle: "normal",
    lineHeight: "24px",
    mixBlendMode: "normal",
    letterSpacing: "0.1px",
  },
  overline: {
    fontWeight: 500,
    fontFamily: "'Noto Serif JP', serif",
  },
  body1: {
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    color: colors.primaryDarkBlue,
    fontFamily: "Montserrat",
    lineHeight: "32px",
    letterSpacing: "0.5px",
  },
  body2: {
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "normal",
    color: colors.primaryDarkBlue,
    fontFamily: "Montserrat",
    lineHeight: "24px",
    letterSpacing: "0.25px",
  },
  caption: {
    fontSize: "12px",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    color: colors.primaryDarkBlue,
    fontWeight: "normal",
    lineHeight: "16px",
    letterSpacing: "0.4px",
  },
};

export default typography;
