/* eslint-disable react/prop-types */
import { QueryErrorResetBoundary } from "react-query";
import { APPLICATION_INSIGHTS_APP_NAME } from "@src/constants";
import initializeAppInsights from "@openup/shared/helpers/appInsights/AppInsights";
import reportWebVitals from "@openup/shared/helpers/appInsights/reportWebVitals";
import AppInsightsWebVitalsReporter from "@openup/shared/helpers/appInsights/AppInsightsWebVitalsReporter";
import { Metric } from "web-vitals";
import {
  AppInsightsContext,
  AppInsightsErrorBoundary,
} from "@microsoft/applicationinsights-react-js";
import { env } from "@src/env";
import ErrorPage from "../ErrorPage";

const ai = initializeAppInsights(
  env.REACT_APP_APPINSIGHTS_CONNECTION_STRING,
  APPLICATION_INSIGHTS_APP_NAME,
  env.REACT_APP_VERSION,
);
reportWebVitals((m: Metric) => AppInsightsWebVitalsReporter(m, ai.appInsights));

const ErrorPageWithAppInsights = () => (
  <ErrorPage
    message={`Session Id: ${ai.appInsights.context.getSessionId()}, Operation Id: ${ai.appInsights.context.telemetryTrace.traceID}`}
  />
);

const ErrorBoundary = ({ children }) => (
  <QueryErrorResetBoundary>
    {() => (
      <AppInsightsErrorBoundary
        appInsights={ai.reactPlugin}
        onError={ErrorPageWithAppInsights}
      >
        <AppInsightsContext.Provider value={ai.reactPlugin}>
          {children}
        </AppInsightsContext.Provider>
      </AppInsightsErrorBoundary>
    )}
  </QueryErrorResetBoundary>
);

export default ErrorBoundary;
