import { Divider } from "@mui/material";
import Button from "@openup/shared/components/Button/Button";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { useTheme, makeStyles } from "@mui/styles";
import { colors } from "@src/theme";
import { TypographyWithIcon } from "./utils/TypographyWithIcon";

const useStyles = makeStyles(({ spacing }) => ({
  title: {
    marginBottom: 0,
    lineHeight: "unset",
    display: "flex",
    alignItems: "center",
  },
  deleteButton: {
    marginLeft: "auto",
    paddingRight: 0,
  },
  topDivider: {
    marginBottom: spacing(3),
  },

  dividerFullWidth: {
    marginLeft: spacing(-3),
    marginRight: spacing(-3),
  },
  buttonRow: {
    marginTop: spacing(1.5),
    marginBottom: spacing(1.5),
    display: "flex",
    flexBasis: "100%",
    justifyContent: "space-between",
  },
  submitButton: {
    padding: spacing(0, 4),
    width: 114,
    fontFamily: "Montserrat",
    fontSize: "14px",
    height: 48,
    fontWeight: 500,
    textAlign: "center",
    background: colors.primaryBlue,
    "&:hover": {
      backgroundColor: colors.primaryBlue,
    },
    "&:disabled": {
      backgroundColor: colors.primaryBlue,
    },
    borderRadius: 30,
    textTransform: "none",
    color: "white",
  },
}));

export const DialogHeader = ({
  iconLigature,
  title,
  outlined,
  onDelete,
  onFilter,
  textRightButton,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { palette, spacing } = useTheme();

  return (
    <>
      <TypographyWithIcon
        variant="h5"
        className={classes.title}
        iconLigature={iconLigature}
        outlined={outlined}
        rightButtonText={textRightButton || t("Common.Delete")}
        rightButtonProps={
          onDelete
            ? {
                startIcon: <DeleteOutline style={{ marginTop: -4 }} />,
                onClick: onDelete,
                variant: "contained",
                style: {
                  backgroundColor: palette.error.main,
                  fontSize: "unset",
                  lineHeight: "unset",
                  textTransform: "uppercase",
                  fontWeight: "bold",
                  borderRadius: 4,
                  padding: spacing(1, 2),
                },
              }
            : onFilter
              ? {
                  startIcon: <DeleteOutline />,
                  onClick: onFilter,
                  variant: "subtitle2",
                  style: {
                    color: colors.primaryBlue,
                    fontSize: "unset",
                    lineHeight: "unset",
                  },
                }
              : undefined
        }
      >
        {title}
      </TypographyWithIcon>
      <Divider className={clsx(classes.topDivider, classes.dividerFullWidth)} />
    </>
  );
};

export const DialogActionButtons = ({
  onCancel,
  onCancelText,
  onSubmit,
  onSubmitText,
  disabled,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <>
      <Divider className={classes.dividerFullWidth} />
      <div className={classes.buttonRow}>
        {onCancel && (
          <Button disabled={disabled} onClick={onCancel} variant="tertiary">
            {onCancelText || t("Common.Cancel")}
          </Button>
        )}
        <Button
          variant="primary"
          type="submit"
          onClick={onSubmit}
          disabled={disabled}
          data-cy="submit-button"
        >
          {onSubmitText || t("Common.Submit")}
        </Button>
      </div>
    </>
  );
};
