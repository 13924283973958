import {
  Box,
  Button,
  Grid,
  Icon,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  IconButton,
} from "@mui/material";
import { alpha } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpen";
import { ReactComponent as AnonymizeClientIcon } from "@src/resources/icons/anonymize-client.svg";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import AddClientButton from "@src/components/AddClientButton";
import NothingFound from "@src/components/NothingFound";
import SearchBox from "@src/components/SearchBox";
import { pageSize } from "@src/constants";
import { env } from "@src/env";
import { isEmptyQueryResult } from "@src/queries/utils";
import { createChat } from "@src/services/chat.service";
import { colors } from "@src/theme";
import { useDebouncedCallback } from "use-debounce";
import { useClientUnlockMutation } from "@src/queries/clients";
import authenticationService from "@src/services/authentication.service";
import { Client } from "@src/models";
import { useState } from "react";
import AnonymizeClientDialog from "@src/components/AnonymizeClientDialog";
import { QueryObserverBaseResult } from "react-query";

const columns = [
  { id: "Common.UserNumber", minWidth: 100 },
  { id: "Common.LastName", minWidth: 170 },
  { id: "Common.FirstName", minWidth: 170 },
  { id: "Common.Email", minWidth: 100 },
  { id: "", minWidth: 50 },
  { id: "", minWidth: 50 },
  { id: "", minWidth: 50 },
];

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    padding: spacing(2, 3),
    borderRadius: 16,
  },
  clientLink: {
    color: colors.primaryDarkBlue,
    textDecoration: "none",
  },
  searchBox: {
    marginRight: spacing(3),
    width: "unset",
    flexGrow: 1,
  },
  clientsIcon: {
    marginRight: spacing(1),
  },
  cell: {
    padding: spacing(2, 0),
  },
  row: {
    opacity: 0.7,
    cursor: "pointer",
  },
  hover: {
    "&:hover": {
      backgroundColor: `${alpha(colors.primaryBlue, 0.1)} !important`,
      borderRadius: 4,
    },
  },
  icon: {
    marginLeft: spacing(1),
  },
  cursorDefault: {
    cursor: "default",
  },
  buttonInitiate: {
    whiteSpace: "nowrap",
    marginLeft: "5px",
    marginRight: "5px",
  },
  buttonIcon: {
    marginLeft: "5px",
  },
  lockIcon: {
    display: "flex",
    alignItems: "center",
    marginRight: spacing(1),
    fontSize: 32,
    borderRadius: "4px",
    backgroundColor: colors.primaryDarkBlue,
    color: "white",
    "&:hover": {
      backgroundColor: colors.primaryBlue,
    },
    "$hover:hover &": {
      opacity: 0.7,
    },
  },
}));

export interface ClientsListProps {
  data: {
    data: Client[];
    pagination: {
      total: number;
      hasNextPage: boolean;
    };
  };
  isFetching: boolean;
  refetchData: QueryObserverBaseResult["refetch"];
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  snackbarShowMessage: (message: string, severity: "success" | "error") => void;
  showClientDetails?: boolean;
  ableToInitiateChat?: boolean;
  ableToAddClient?: boolean;
  ableToAnonymizeClient?: boolean;
}

const ClientsList: React.FC<ClientsListProps> = ({
  data,
  isFetching,
  refetchData,
  page,
  setPage,
  setSearch,
  snackbarShowMessage,
  showClientDetails = true,
  ableToInitiateChat = true,
  ableToAddClient = true,
  ableToAnonymizeClient = false,
}) => {
  const navigate = useNavigate();
  const { isSupport } = authenticationService;
  const { mutateAsync: unlockMutation } = useClientUnlockMutation();
  const classes = useStyles();
  const { t } = useTranslation();
  const [isAnonymizeClientDialogOpen, setIsAnonymizeClientDialogOpen] =
    useState<boolean>(false);
  const [selectedClient, setSelectedClient] = useState<Client>();

  const copyEmailToClipboard = (email, event) => {
    event.stopPropagation();
    navigator.clipboard.writeText(email).then(() => {
      snackbarShowMessage(t("Common.EmailClipboard"), "success");
    });
  };

  const handleClickStarChat = async (event, client) => {
    const button = event.currentTarget;
    event.stopPropagation();
    if (button.disabled) {
      return;
    }
    button.disabled = true;
    button.style.opacity = 0.4;
    button.style.cursor = "default";
    try {
      await createChat(client);
      snackbarShowMessage(
        t("Clients.ClientChatInitiated", { userEmail: client.email }),
        "success",
      );
    } catch (error) {
      snackbarShowMessage(
        t("Clients.ClientChatInitiateError", { userEmail: client.email }),
        "error",
      );
      console.error(error);
    }
  };

  const onSearchChange = useDebouncedCallback((searchValue) => {
    setSearch(searchValue);
    setPage(0);
  }, 500);

  const handleClickClientRow = (event, client) => {
    event.stopPropagation();
    if (showClientDetails) {
      navigate(`/clients/${client.id}`);
    }
  };

  const handleUnlockUser = (event, clientId) => {
    event.stopPropagation();
    try {
      unlockMutation(clientId);
      snackbarShowMessage("", "success");
    } catch (error) {
      snackbarShowMessage("", "error");
    }
  };

  const handleAnonymizeClient = (event, client: Client) => {
    event.stopPropagation();
    setSelectedClient(client);
    setIsAnonymizeClientDialogOpen(true);
  };

  const {
    data: clients,
    pagination: { total, hasNextPage },
  } = data;

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Box display="flex">
            <SearchBox
              onChange={onSearchChange}
              placeholder={t("Clients.List.SearchPlaceholder")}
              className={classes.searchBox}
            />
            {ableToAddClient ? (
              <AddClientButton data-cy="add-client-button" />
            ) : null}
          </Box>
        </Grid>

        {isEmptyQueryResult(data) ? (
          <NothingFound translationKey="Clients.List.NotFound" />
        ) : (
          <Grid item xs={12} data-cy="clients-table">
            <Paper className={classes.root}>
              <Box display="flex" alignItems="center">
                <Icon className={classes.clientsIcon}>groups</Icon>
                <Typography variant="h5">{t("Clients.Pagetitle")}</Typography>
                <TablePagination
                  component="div"
                  style={{
                    marginLeft: "auto",
                    marginRight: -8,
                  }}
                  count={total}
                  rowsPerPage={pageSize}
                  rowsPerPageOptions={[10]}
                  page={page}
                  onPageChange={(_, newPage) => setPage(newPage)}
                  nextIconButtonProps={{
                    disabled: isFetching || !hasNextPage,
                  }}
                  backIconButtonProps={{
                    disabled: isFetching || page === 0,
                  }}
                />
              </Box>
              <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          style={{ minWidth: column.minWidth }}
                          className={classes.cell}
                        >
                          <Typography variant="subtitle1">
                            {t(column.id)}
                          </Typography>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {clients.map((client) => (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={client.id}
                        classes={{ hover: classes.hover, root: classes.row }}
                        onClick={(e) => handleClickClientRow(e, client)}
                      >
                        <TableCell className={classes.cell}>
                          <Typography variant="subtitle1">
                            OU-{client.userNumber}
                          </Typography>
                        </TableCell>
                        <TableCell className={classes.cell}>
                          <Typography variant="subtitle1">
                            {client.lastName}
                          </Typography>
                        </TableCell>
                        <TableCell className={classes.cell}>
                          <Typography variant="subtitle1">
                            {client.firstName}
                          </Typography>
                        </TableCell>
                        <TableCell className={classes.cell}>
                          <Box display="flex">
                            <Typography variant="subtitle1">
                              {client.email}
                            </Typography>
                            <FileCopyOutlinedIcon
                              className={classes.icon}
                              onClick={(event) =>
                                copyEmailToClipboard(client.email, event)
                              }
                            />
                          </Box>
                        </TableCell>
                        {env.REACT_APP_CHAT_FEATURE_ENABLED &&
                        ableToInitiateChat ? (
                          <TableCell
                            className={clsx(
                              classes.cell,
                              classes.cursorDefault,
                            )}
                            onClick={(clickEvent) =>
                              clickEvent.stopPropagation()
                            }
                          >
                            <Button
                              className={classes.buttonInitiate}
                              onClick={(event) =>
                                handleClickStarChat(event, client)
                              }
                              disabled={!ableToAddClient}
                            >
                              <Typography variant="subtitle1">
                                {t("Clients.ClientChatInitiate")}
                              </Typography>
                              <Icon className={classes.buttonIcon}>
                                play_circle_outlined
                              </Icon>
                            </Button>
                          </TableCell>
                        ) : (
                          <TableCell className={classes.cell} />
                        )}
                        {client.isLockedOut && isSupport() ? (
                          <TableCell>
                            <LockOpenOutlinedIcon
                              data-testid={`lock-icon-${client.id}`}
                              className={clsx(classes.icon, classes.lockIcon)}
                              onClick={(event) =>
                                handleUnlockUser(event, client.id)
                              }
                            />
                          </TableCell>
                        ) : (
                          <TableCell className={classes.cell} />
                        )}
                        {ableToAnonymizeClient ? (
                          <TableCell>
                            <Tooltip
                              title={t("Clients.List.AnonymizeClient")}
                              placement="top"
                              data-cy="clients-list-anonymize-client"
                            >
                              <IconButton
                                onClick={(event) =>
                                  handleAnonymizeClient(event, client)
                                }
                                data-testid={`anonymize-icon-${client.id}`}
                              >
                                <AnonymizeClientIcon />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        ) : (
                          <TableCell className={classes.cell} />
                        )}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>
        )}
      </Grid>
      {ableToAnonymizeClient ? (
        <AnonymizeClientDialog
          open={isAnonymizeClientDialogOpen}
          client={selectedClient}
          onClose={() => {
            setIsAnonymizeClientDialogOpen(false);
          }}
          onSuccess={() => {
            setIsAnonymizeClientDialogOpen(false);
            refetchData();
          }}
          snackbarShowMessage
          data-cy="clients-list-anonymize-dialog"
        />
      ) : null}
    </>
  );
};

export default ClientsList;
