import { Button, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";

const useStyles = makeStyles(({ spacing, colors }) => ({
  buttonContainer: {
    backgroundColor: colors.primaryBlue,
    borderColor: colors.primaryBlue,
    "&:hover": {
      backgroundColor: colors.primaryBlue,
      filter: "brightness(0.95)",
    },
    "&:disabled": {
      borderColor: colors.disabledBlue,
    },
    border: "2px solid",
    borderRadius: "100px",
    padding: spacing(0.1, 2, 0.1),
    margin: spacing(2, 3, 2, 0),
  },
  buttonText: {
    color: colors.almostWhite,
    fontWeight: 500,
    fontSize: "14px",
  },
}));

/**
 * Primary Button Component
 */
export const PrimaryButton = ({ disabled, value, action, type }) => {
  const classes = useStyles();

  return (
    // TODO detect if form has changed, and show dialog if it has
    <Button
      disabled={disabled}
      className={classes.buttonContainer}
      onClick={action}
      type={type}
    >
      <Typography className={classes.buttonText}>{value}</Typography>
    </Button>
  );
};

PrimaryButton.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.string,
  type: PropTypes.string,
  action: PropTypes.func,
};

PrimaryButton.defaultProps = {
  disabled: false,
  value: "",
  type: "button",
  action: () => {},
};
