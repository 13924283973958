import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { ReactComponent as WomanBag } from "./images/woman_box_main.svg";

const useStyles = makeStyles(() => ({
  typography: {
    opacity: 0.7,
  },
}));

const ClientCheckinsEmpty = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box mt={6} mb={2} display="flex">
      <WomanBag />
      <Box ml={8}>
        <Typography variant="body1" className={classes.typography}>
          {t("Clients.CheckinOverview.Text.Part1")}
        </Typography>
        <Typography variant="body1" className={classes.typography}>
          {t("Clients.CheckinOverview.Text.Part2")}
        </Typography>
      </Box>
    </Box>
  );
};

export default ClientCheckinsEmpty;
