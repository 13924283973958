export const queryIdentifiers = {
  psychologists: "psychologists",
  psychologistManagement: "psychologist-management",
  consults: "consults",
  consult: "consult",
  reassignPsychologist: "reassign-psychologist",
  makePsychologist: "make-psychologist",
  employee: "employee",
  openupEmployees: "openup-employees",
  sessions: "sessions",
  allSessions: "all-sessions",
  profile: "profile",
  googleCalendar: "google-calendar",
  getCalendar: "get-calendars",
  registerCalendar: "register-calendar",
  clients: "clients",
  mySessions: "my-sessions",
  myClients: "clients/my",
  questionListAnswers: "question-list-answers",
  themes: "themes",
  dossierNote: "dossier-note",
  employers: "employers",
  notes: "notes",
  externalQuestionnaires: "external-questionnaires",
  scheduler: "scheduler",
  availableTimeSlots: "scheduler/time-slots/available",
  cancel: "cancel",
  reschedule: "reschedule",
  tags: "tags",
  notifications: "notifications",
  timezones: "scheduler/timezones",
  topics: "Topics",
  bookConsultWithClient: "book-consult-with-client",
  unlock: "unlock",
  exprtDetails: "expert-details",
};

export const getExternalHealthProviders = (t) => {
  return [
    {
      value: "fysio",
      label: t("Consults.SessionDetails.HelpClient.fysio"),
    },
    {
      value: "nutritionist",
      label: t("Consults.SessionDetails.HelpClient.nutritionist"),
    },
    {
      value: "lifestyleCoach",
      label: t("Consults.SessionDetails.HelpClient.lifestyleCoach"),
    },
    {
      value: "executiveCoach",
      label: t("Consults.SessionDetails.HelpClient.executiveCoach"),
    },
    {
      value: "doctor",
      label: t("Consults.SessionDetails.HelpClient.doctor"),
    },
    {
      value: "specialistMentalHealthcare",
      label: t("Consults.SessionDetails.HelpClient.specialistMentalHealthcare"),
    },
    {
      value: "other",
      label: t("Consults.SessionDetails.HelpClient.other"),
    },
  ];
};

export const getNextStepsForSessionNotes = (t) => {
  return [
    {
      value: "weeks",
      label: t("Consults.SessionDetails.NextSteps.Weeks"),
    },
    {
      value: "months",
      label: t("Consults.SessionDetails.NextSteps.Months"),
    },
    {
      value: "break",
      label: t("Consults.SessionDetails.NextSteps.Break"),
    },
  ];
};

export const SESSION_TYPE = {
  GENERAL: "general",
  MINDFULNESS: "mindfulness",
  MEDICAL: "medical",
};

export const THEME_KEY = {
  CONFIDENCE: "Confidence",
  STRESS: "Stress",
  WORK: "Work",
  PURPOSE: "Purpose",
  RELATIONSHIPS: "Relationships",
  HEALTH: "Health",
  SLEEP: "Sleep",
  MINDFULNESS: "Mindfulness",
};

export const MEDICAL_THEME_KEY = {
  BREATHING: "Breathing",
  DAILY_ENERGY: "DailyEnergy",
  INTOXICATION: "Intoxications",
  MENTAL_HEALTH: "MentalHealth",
  NUTRITION: "Nutrition",
  PHYSICAL_ACTIVITY: "PhysicalActivity",
  SLEEP_SATISFACTION: "SleepSatisfaction",
};

export const SESSION_NOTES_SECTIONS = {
  REASON_BOOK: "reasonBook",
  SESSION_DISCUSSED: "sessionDiscussed",
  HELP_NEEDED: "helpNeeded",
  PROVIDER: "provider",
  PROVIDER_NAME: "providerName",
  NEXT_STEPS: "nextSteps",
};
