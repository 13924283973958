import { ThemeProvider, Theme, StyledEngineProvider } from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { useRoutes } from "react-router-dom";
import { env } from "@src/env";
import LanguageProvider from "@src/contexts/LanguageContext";
import theme from "./theme";
import {
  AuthenticationProvider,
  useAuthentication,
} from "./services/authentication.service";
import routes from "./routes";
import { consultStaleTime } from "./queries/staletimes";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      useErrorBoundary: true, // Throw errors so we can use catch them using QueryErrorResetBoundary in the withSuspense
      suspense: true, // Suspend query while loading so we can display Loader using withSuspense
      keepPreviousData: true,
      refetchOnWindowFocus: env.REACT_APP_HOSTING_ENV === "production",
      retry: false,
      staleTime: consultStaleTime,
    },
  },
});

const Routes = () => {
  const { isLoggedIn, hasRole } = useAuthentication();

  return useRoutes(routes(isLoggedIn, hasRole));
};

const Wrapped = () => (
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <GoogleOAuthProvider clientId={env.REACT_APP_GOOGLE_CLIENT_ID}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <QueryClientProvider client={queryClient}>
            <AuthenticationProvider>
              <LanguageProvider>
                <Routes />
                <ReactQueryDevtools initialIsOpen={false} />
              </LanguageProvider>
            </AuthenticationProvider>
          </QueryClientProvider>
        </LocalizationProvider>
      </GoogleOAuthProvider>
    </ThemeProvider>
  </StyledEngineProvider>
);

export default Wrapped;
