import { Box, Card, CardActions, CardContent, CardMedia } from "@mui/material";

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  boxCard: ({ isMultipleCardsShown }) => {
    return {
      display: !isMultipleCardsShown && "flex",
      [theme.breakpoints.down("md")]: {
        display: "block",
      },
    };
  },
  cardContent: ({ isMultipleCardsShown }) => {
    return {
      display: isMultipleCardsShown && "flex",
      justifyContent: isMultipleCardsShown && "space-between",
      flex: 1,
      padding: theme.spacing(4, 3),
      [theme.breakpoints.down("md")]: {
        padding: theme.spacing(3, 2),
      },
    };
  },
  cardImage: ({ isMultipleCardsShown }) => {
    return {
      [theme.breakpoints.down("md")]: {
        marginTop: isMultipleCardsShown && theme.spacing(0),
      },
      flex: 1,
      marginTop: isMultipleCardsShown && theme.spacing(2),
      "& img:nth-child(1)": {
        float: "right",
      },
    };
  },
  cardActions: {
    padding: theme.spacing(3),
    justifyContent: "center",
    borderTop: `solid 2px rgba(21, 35, 97, .05);`,
    width: "100%",
    [theme.breakpoints.down("md")]: {
      paddingRight: theme.spacing(4),
      position: "fixed",
      bottom: 0,
      right: 0,
      backgroundColor: "#ffffff",
      padding: theme.spacing(2),
      boxShadow: "0px 0 16px rgba(21, 35, 97, 30% )",
    },
  },
  card: ({ isMultipleCardsShown, useSecondaryColor }) => {
    return {
      backgroundColor: useSecondaryColor ? "#FFF8ED" : "inherit",
      marginBottom: isMultipleCardsShown
        ? theme.spacing(4)
        : theme.spacing(8.5),
      height: isMultipleCardsShown && 184,
      [theme.breakpoints.down("md")]: {
        height: isMultipleCardsShown && 152,
      },
    };
  },
}));

const BookingCard = ({
  image,
  actions,
  children,
  isMultipleCardsShown = false,
  useSecondaryColor = false,
}) => {
  const classes = useStyles({ isMultipleCardsShown, useSecondaryColor });

  return (
    <Card className={classes.card}>
      <Box className={classes.boxCard}>
        <CardContent className={classes.cardContent}>{children}</CardContent>
        {image && <CardMedia className={classes.cardImage}>{image}</CardMedia>}
      </Box>
      {actions && (
        <CardActions className={classes.cardActions}>{actions}</CardActions>
      )}
    </Card>
  );
};

export default BookingCard;
