import { googleLogout } from "@react-oauth/google";
import axios from "axios";
import i18n from "i18next";
import { createContext, useContext, useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router";
import Loader from "@src/components/Loader";
import {
  AuthenticatorTwoFactorCodeRequestMethod,
  RESPONSE_STATUS,
  apiPaths,
} from "@src/constants";
import { User } from "@src/models/User";

import { OpenUpRoles } from "@src/services/OpenUpRoles";
import { getApiBaseUrl } from "../utils/urlHelpers";

export function setUserToStorage(user) {
  sessionStorage.setItem("user", JSON.stringify(user));
}

export function clearUserFromStorage() {
  sessionStorage.removeItem("user");
}

const isTwoFactorPendingResponse = (response) => {
  return (
    response.status === RESPONSE_STATUS.OK &&
    response.data.twoFactorVerification &&
    response.data.twoFactorVerification === "pending"
  );
};

const getUserProfile = () => {
  return axios.get(apiPaths.profile).then((response) => {
    setUserToStorage(response.data);
    return response.data;
  });
};

const refreshAccessToken = (email) => {
  return axios.post(`${apiPaths.refreshToken}?email=${email}`, {});
};

const grantCalendarAccess = (code) => {
  let { hostname: domain } = window.location;
  if (!!window.location.port && window.location.port !== "443") {
    domain = `${domain}:${window.location.port}`;
  }

  return axios.post(apiPaths.grantCalendarAccess, {
    code,
    domain,
  });
};

const registerExternalUser = (code, provider) => {
  let { hostname: domain } = window.location;
  if (!!window.location.port && window.location.port !== "443") {
    domain = `${domain}:${window.location.port}`;
  }
  return axios
    .post(apiPaths.externalSignUp, {
      code,
      provider,
      domain,
      RequestSource: 2,
    })
    .then((response) => {
      if (isTwoFactorPendingResponse(response)) {
        setUserToStorage(response.data);
      } else {
        return getUserProfile();
      }
    })
    .catch((error) => {
      throw error;
    });
};

const loginExternalUser = (code, provider) => {
  let domain = window.location.hostname;
  if (!!window.location.port && window.location.port !== "443") {
    domain = `${domain}:${window.location.port}`;
  }
  return axios
    .post(apiPaths.externalLogin, {
      code,
      provider,
      domain,
      RequestSource: 2,
    })
    .then((response) => {
      if (isTwoFactorPendingResponse(response)) {
        setUserToStorage(response.data);
      } else {
        return getUserProfile();
      }
    })
    .catch((error) => {
      throw error;
    });
};

const getExternalUser = (idToken, provider) => {
  return axios
    .post(apiPaths.externalLogin, {
      idToken,
      provider,
    })
    .then((response) => {
      if (isTwoFactorPendingResponse(response)) {
        setUserToStorage(response.data);
      } else {
        return getUserProfile();
      }
    })
    .catch((error) => {
      throw error;
    });
};

const signUpExternalUser = (idToken, provider) => {
  return axios
    .post(apiPaths.externalSignUp, {
      idToken,
      provider,
    })
    .then((response) => {
      if (isTwoFactorPendingResponse(response)) {
        setUserToStorage(response.data);
      } else {
        return getUserProfile();
      }
    })
    .catch((error) => {
      throw error;
    });
};

const getTwoFactorRegistration = () => {
  return axios
    .get(apiPaths.getTwoFactorRegistration)
    .then((response) => {
      return response.data;
    })
    .catch(() => false);
};

const setTwoFactorLogin = () => {
  return axios.patch(apiPaths.enableTwoFactorLogin, {}).then((response) => {
    sessionStorage.setItem("user", JSON.stringify(response.data));
    return response.data;
  });
};

const authenticateTwoFactorCode = (
  value,
  method = AuthenticatorTwoFactorCodeRequestMethod,
) => {
  return axios
    .post(apiPaths.verifyTwoFactorCode, { code: value, method })
    .then(() => {
      return getUserProfile();
    });
};

export const getCurrentUser = () => {
  const user = sessionStorage.getItem("user");
  return (user ? JSON.parse(user) : null) as User;
};

const hasRole = (...roles) => {
  const userRoles = getCurrentUser()?.roles;

  try {
    if (userRoles) {
      if (Array.isArray(userRoles)) {
        return roles.some((role) => userRoles.includes(role));
      }
      return roles.includes(userRoles);
    }
  } catch (_) {}
  return false;
};

const getUserRoles = () =>
  getCurrentUser()?.roles?.map((role) => OpenUpRoles[role]);
const isExpert = () => hasRole(OpenUpRoles.Expert);
const isManagerOrAdmin = () => hasRole(OpenUpRoles.Manager, OpenUpRoles.Admin);
const isHost = () => hasRole(OpenUpRoles.Host);
const isManagerOrAdminOrHost = () =>
  hasRole(OpenUpRoles.Manager, OpenUpRoles.Admin, OpenUpRoles.Host);
const isManagerOrAdminOrSupport = () =>
  hasRole(OpenUpRoles.Manager, OpenUpRoles.Admin, OpenUpRoles.Support);
const isManagerOrAdminOrHostOrSupport = () =>
  hasRole(
    OpenUpRoles.Manager,
    OpenUpRoles.Admin,
    OpenUpRoles.Host,
    OpenUpRoles.Support,
  );
const isSupport = () => hasRole(OpenUpRoles.Support);

export const getRefreshToken = () => sessionStorage.getItem("refresh_token");

// checking if user has set up google 2FA authenticator
const twoFactorEnabled = () =>
  !!getCurrentUser()?.userTokenProviders?.find(
    (tokenProvider) => tokenProvider === "AuthenticatorKey",
  );

const AuthenticationContext = createContext({
  isLoggedIn: false,
  hasCheckedLogin: false,
  user: null,
  logout: (): void => {
    throw new Error("1 Authorization context not initialized");
  },
  setLoggedIn: (): void => {
    throw new Error("2 Authorization context not initialized");
  },
  hasRole: (): boolean => {
    throw new Error("3 Authorization context not initialized");
  },
  isManagerOrAdmin: (): boolean => {
    throw new Error("4 Authorization context not initialized");
  },
  isManagerOrAdminOrHost: (): boolean => {
    throw new Error("5 Authorization context not initialized");
  },
});

const authorizationErrorStatusCodes = [403, 401];

export const AuthenticationProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [hasCheckedLogin, setHasCheckedLogin] = useState(false);
  const [user, setUser] = useState(null);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const logout = () => {
    // eslint-disable-next-line no-console
    console.debug("[Authorization] Logging out");
    googleLogout();
    axios.post(apiPaths.logout, {});
    setUser(null);
    setIsLoggedIn(false);
    clearUserFromStorage();
    queryClient.removeQueries();
  };

  const setLoggedIn = () => {
    // eslint-disable-next-line no-console
    console.debug("[Authorization] You are now logged in");
    setIsLoggedIn(true);
  };

  useEffect(() => {
    // Setup axios
    axios.defaults.baseURL = getApiBaseUrl();
    axios.defaults.withCredentials = true;

    // Interceptor that attaches the access token to every request
    axios.interceptors.request.use((config: any) => {
      if (!config.headers) config.headers = {};
      config.headers["accept-language"] = i18n.language;
      return config;
    });

    // Interceptor that tries to refresh the access token using the refresh token
    axios.interceptors.response.use(
      (response) => response,
      async function (error) {
        const originalRequest = error.config;
        const isRefreshRequest = error.config?.url?.startsWith(
          apiPaths.refreshToken,
        );
        const isLoginRequest = error.config?.url?.startsWith(
          apiPaths.loginExternalUser,
        );
        const isLogoutRequest = error.config?.url === apiPaths.logout;
        const isTwoFactor = error.config?.url === apiPaths.verifyTwoFactorCode;
        const isAuthorizationError =
          !!error?.response?.status &&
          authorizationErrorStatusCodes.includes(error?.response?.status);
        const isRetry = !!originalRequest?._retry;

        if (isAuthorizationError) {
          if (isLoginRequest) {
            return Promise.reject(error);
          }

          if (!isLogoutRequest && isRetry) {
            navigate("/login");
            logout();
          }

          if (
            !isLogoutRequest &&
            !isRefreshRequest &&
            isAuthorizationError &&
            !isRetry &&
            !isTwoFactor
          ) {
            // Next request is a retry, set the variable so we now
            originalRequest._retry = true;

            // This user should be gotten from sessionStorage. We need the email to refresh the access token,
            // and redux won't be loaded on first page load.
            const currentUser = getCurrentUser();
            if (currentUser?.email) {
              try {
                await refreshAccessToken(currentUser.email);
                return await axios(originalRequest);
              } catch {
                // eslint-disable-next-line no-console
                console.debug("[Authorization] The refresh request failed");
                navigate("/login");
                logout();
              }
            } else {
              setIsLoggedIn(false);
              setUser(null);
            }
          }
        }
        return Promise.reject(error);
      },
    );

    // Try to get the user profile once, using the access token or the
    // refresh token, to make sure that either is still valid.
    const checkRefreshTokenValidity = async () => {
      try {
        const userProfile = await getUserProfile();
        setUser(userProfile);
        setIsLoggedIn(true);
      } catch (e) {
        console.error(e);
      } finally {
        setHasCheckedLogin(true);
      }
    };
    checkRefreshTokenValidity();
  }, []);

  return (
    <AuthenticationContext.Provider
      value={{
        isLoggedIn,
        hasCheckedLogin,
        user,
        logout,
        setLoggedIn,
        hasRole,
        isManagerOrAdmin,
        isManagerOrAdminOrHost,
      }}
    >
      {hasCheckedLogin ? children : <Loader />}
    </AuthenticationContext.Provider>
  );
};

export const useAuthentication = () => useContext(AuthenticationContext);

const service = {
  twoFactorEnabled,
  getExternalUser,
  signUpExternalUser,
  getTwoFactorRegistration,
  authenticateTwoFactorCode,
  setTwoFactorLogin,
  hasRole,
  getUserRoles,
  grantCalendarAccess,
  registerExternalUser,
  loginExternalUser,
  isHost,
  isExpert,
  isManagerOrAdmin,
  isManagerOrAdminOrHost,
  isManagerOrAdminOrSupport,
  isManagerOrAdminOrHostOrSupport,
  isSupport,
};

export default service;
