/* eslint-disable react/prop-types */
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { alpha } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

import clsx from "clsx";
import { useClientThemeAnswersQuery } from "@src/queries/clients";
import { isEmptyQueryResult } from "@src/queries/utils";
import { getScoreColor, colors } from "@src/theme/colors";
import { TypographyWithIcon } from "./utils/TypographyWithIcon";

import NothingFound from "./NothingFound";
import { withSuspense } from "./wrappers/Suspendable";
import FormattedDate from "./FormattedDate";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  headerText: {
    fontWeight: 500,
    fontSize: 16,
    lineHeight: "24px",
  },
  questionText: {
    opacity: 0.7,
  },
  darkBorder: {
    borderColor: alpha(colors.primaryDarkBlue, 0.5),
  },
});

const CheckinAnswersTable = ({ clientId, formName }) => {
  const { data } = useClientThemeAnswersQuery(clientId, formName);
  const { t } = useTranslation();
  const classes = useStyles();

  if (isEmptyQueryResult(data)) {
    return <NothingFound />;
  }

  return (
    <Paper sx={{ padding: "24px" }}>
      <TypographyWithIcon iconLigature="group" outlined>
        {t(`Themes.${formName}.Name`)}
      </TypographyWithIcon>
      <TableContainer>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.darkBorder} />
              {data.questionLists.map(({ createdAt, key }, index) => (
                <TableCell
                  className={clsx(classes.darkBorder, classes.headerText)}
                  align="right"
                  key={`${key}_${createdAt}_${index}`}
                >
                  <FormattedDate date={createdAt} />
                </TableCell>
              ))}
            </TableRow>
            <TableRow>
              <TableCell className={classes.darkBorder}>
                <Typography variant="subtitle2">{t("Common.Score")}</Typography>
              </TableCell>
              {data.questionLists.map((questionList) => (
                <TableCell className={classes.darkBorder}>
                  <Typography
                    variant="subtitle2"
                    align="right"
                    style={{ color: getScoreColor(questionList.score) }}
                  >
                    <b>{questionList.score.toFixed(1)}</b>
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.questionLists.length > 0 &&
              data.questionLists[0].questions.map(
                ({ key, createdAt, text }, index) => {
                  return (
                    <TableRow key={`${key}_${createdAt}_${index}`} hover>
                      <TableCell component="th" scope="row">
                        <Typography
                          variant="body2"
                          className={classes.questionText}
                        >
                          {text}
                        </Typography>
                      </TableCell>
                      {data.questionLists.map(({ questions }, innerIndex) => {
                        const question = questions.find((q) => q.key === key);
                        if (!question) {
                          return null;
                        }

                        return (
                          <TableCell
                            align="right"
                            key={`${key}_${createdAt}_${innerIndex}_${question.key}`}
                          >
                            {!question.answers[0] ? (
                              <Typography
                                variant="body2"
                                style={{ color: getScoreColor(0) }}
                              >
                                '-'
                              </Typography>
                            ) : (
                              question.answers.map((answer) => (
                                <Typography
                                  variant="body2"
                                  style={{
                                    color: getScoreColor(answer.intValue),
                                  }}
                                >
                                  {answer.textValue}
                                </Typography>
                              ))
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                },
              )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default withSuspense(CheckinAnswersTable);
