import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import ClientCheckinAnswersTable from "@src/components/ClientCheckinAnswersTable";
import { useClientQuery } from "@src/queries/clients";
import { Breadcrumbs } from "@src/components/Breadcrumbs";
import { formatUserName } from "@src/utils/formatting";
import { Grid } from "@mui/material";
import { THEME_KEY } from "@src/constants";
import ClientMedicalCheckinAnswersTable from "@src/components/ClientMedicalCheckinAnswersTable";

const ClientThemeView = () => {
  const { clientId, themeName } = useParams();
  const { t } = useTranslation();
  const { data: client } = useClientQuery(clientId);
  const themeKey = Object.values(THEME_KEY).find(
    (themeKey) =>
      t(`route.ThemeKey.${themeKey}`).toLowerCase() === themeName.toLowerCase(),
  );
  return (
    <Grid container>
      <Grid item xs={12}>
        <Breadcrumbs
          crumbs={[
            {
              link: "/clients",
              text: t("Clients.Pagetitle"),
            },
            {
              link: `/clients/${clientId}`,
              text: formatUserName(client),
            },
          ]}
        />
      </Grid>
      <Grid item xs={12}>
        {themeKey === THEME_KEY.MEDICAL ? (
          <ClientMedicalCheckinAnswersTable clientId={clientId} />
        ) : (
          <ClientCheckinAnswersTable clientId={clientId} formName={themeKey} />
        )}
      </Grid>
    </Grid>
  );
};

export default ClientThemeView;
