import type { FC } from "react";

import { ReactComponent as AR } from "@src/resources/flags/ar-AR.svg";
import { ReactComponent as BA } from "@src/resources/flags/bs-BA.svg";
import { ReactComponent as BG } from "@src/resources/flags/bg-BG.svg";
import { ReactComponent as CS } from "@src/resources/flags/cs-CZ.svg";
import { ReactComponent as DA } from "@src/resources/flags/da-DK.svg";
import { ReactComponent as DE } from "@src/resources/flags/de-DE.svg";
import { ReactComponent as CH } from "@src/resources/flags/zh-CN.svg";
import { ReactComponent as EG } from "@src/resources/flags/ar-EG.svg";
import { ReactComponent as EN } from "@src/resources/flags/en-GB.svg";
import { ReactComponent as ES } from "@src/resources/flags/es-ES.svg";
import { ReactComponent as FI } from "@src/resources/flags/fi-FI.svg";
import { ReactComponent as FL } from "@src/resources/flags/nl-BE.svg";
import { ReactComponent as FR } from "@src/resources/flags/fr-FR.svg";
import { ReactComponent as GR } from "@src/resources/flags/el-GR.svg";
import { ReactComponent as HR } from "@src/resources/flags/hr-HR.svg";
import { ReactComponent as HU } from "@src/resources/flags/hu-HU.svg";
import { ReactComponent as IT } from "@src/resources/flags/it-IT.svg";
import { ReactComponent as MA } from "@src/resources/flags/ar-MA.svg";
import { ReactComponent as NL } from "@src/resources/flags/nl-NL.svg";
import { ReactComponent as NO } from "@src/resources/flags/nb-NO.svg";
import { ReactComponent as PL } from "@src/resources/flags/pl-PL.svg";
import { ReactComponent as PT } from "@src/resources/flags/pt-PT.svg";
import { ReactComponent as RU } from "@src/resources/flags/ru-RU.svg";
import { ReactComponent as SE } from "@src/resources/flags/sv-SE.svg";
import { ReactComponent as SK } from "@src/resources/flags/sk-SK.svg";
import { ReactComponent as SL } from "@src/resources/flags/sl-SL.svg";
import { ReactComponent as SO } from "@src/resources/flags/so-SO.svg";
import { ReactComponent as SR } from "@src/resources/flags/sr-RS.svg";
import { ReactComponent as TN } from "@src/resources/flags/ar-TN.svg";
import { ReactComponent as TR } from "@src/resources/flags/tr-TR.svg";
import { ReactComponent as UK } from "@src/resources/flags/uk-UA.svg";
import { ReactComponent as EN_US } from "@src/resources/flags/en-US.svg";
import { ReactComponent as AF } from "@src/resources/flags/af-ZA.svg";
import { ReactComponent as DE_CH } from "@src/resources/flags/de-CH.svg";
import { ReactComponent as MFE } from "@src/resources/flags/mfe-MU.svg";
import { ReactComponent as JA } from "@src/resources/flags/ja-JP.svg";
import { ReactComponent as ID } from "@src/resources/flags/id-ID.svg";
import { ReactComponent as KO } from "@src/resources/flags/ko-KR.svg";
import { LANGUAGE_TYPES } from "@src/constants";

export const IconSessionLanguage: FC<IconSessionLanguageProps> = ({
  language,
  className,
}) => {
  switch (language) {
    case LANGUAGE_TYPES.ITALIAN:
      return <IT className={className} key={language} />;
    case LANGUAGE_TYPES.CHINESE:
      return <CH className={className} key={language} />;
    case LANGUAGE_TYPES.DUTCH:
      return <NL className={className} key={language} />;
    case LANGUAGE_TYPES.SPANISH:
      return <ES className={className} key={language} />;
    case LANGUAGE_TYPES.FRENCH:
      return <FR className={className} key={language} />;
    case LANGUAGE_TYPES.GERMAN:
      return <DE className={className} key={language} />;
    case LANGUAGE_TYPES.GERMAN_CH:
      return <DE_CH className={className} key={language} />;
    case LANGUAGE_TYPES.ENGLISH_GB:
      return <EN className={className} key={language} />;
    case LANGUAGE_TYPES.ENGLISH_US:
      return <EN_US className={className} key={language} />;
    case LANGUAGE_TYPES.BOSNIAN:
      return <BA className={className} key={language} />;
    case LANGUAGE_TYPES.ARABIC:
      return <AR className={className} key={language} />;
    case LANGUAGE_TYPES.CROATIAN:
      return <HR className={className} key={language} />;
    case LANGUAGE_TYPES.PORTUGUESE:
      return <PT className={className} key={language} />;
    case LANGUAGE_TYPES.RUSSIAN:
      return <RU className={className} key={language} />;
    case LANGUAGE_TYPES.SERBIAN:
      return <SR className={className} key={language} />;
    case LANGUAGE_TYPES.TURKISH:
      return <TR className={className} key={language} />;
    case LANGUAGE_TYPES.UKRAINIAN:
      return <UK className={className} key={language} />;
    case LANGUAGE_TYPES.FLEMISH:
      return <FL className={className} key={language} />;
    case LANGUAGE_TYPES.POLISH:
      return <PL className={className} key={language} />;
    case LANGUAGE_TYPES.MOROCCAN:
      return <MA className={className} key={language} />;
    case LANGUAGE_TYPES.BULGARIAN:
      return <BG className={className} key={language} />;
    case LANGUAGE_TYPES.DANISH:
      return <DA className={className} key={language} />;
    case LANGUAGE_TYPES.SWEDISH:
      return <SE className={className} key={language} />;
    case LANGUAGE_TYPES.FINNISH:
      return <FI className={className} key={language} />;
    case LANGUAGE_TYPES.SOMALI:
      return <SO className={className} key={language} />;
    case LANGUAGE_TYPES.NORWEGIAN:
      return <NO className={className} key={language} />;
    case LANGUAGE_TYPES.HUNGARIAN:
      return <HU className={className} key={language} />;
    case LANGUAGE_TYPES.SLOVAK:
      return <SK className={className} key={language} />;
    case LANGUAGE_TYPES.GREEK:
      return <GR className={className} key={language} />;
    case LANGUAGE_TYPES.EGYPTIAN:
      return <EG className={className} key={language} />;
    case LANGUAGE_TYPES.TUNISIAN:
      return <TN className={className} key={language} />;
    case LANGUAGE_TYPES.CZECH:
      return <CS className={className} key={language} />;
    case LANGUAGE_TYPES.SLOVENIAN:
      return <SL className={className} key={language} />;
    case LANGUAGE_TYPES.AFRIKAANS:
      return <AF className={className} key={language} />;
    case LANGUAGE_TYPES.MAURITIAN:
      return <MFE className={className} key={language} />;
    case LANGUAGE_TYPES.JAPANESE:
      return <JA className={className} key={language} />;
    case LANGUAGE_TYPES.INDONESIAN:
      return <ID className={className} key={language} />;
    case LANGUAGE_TYPES.KOREAN:
      return <KO className={className} key={language} />;

    default:
      return null;
  }
};

interface IconSessionLanguageProps {
  language: string;
  className: string;
}
