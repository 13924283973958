import * as Yup from "yup";
import libphonenumber from "google-libphonenumber";
import { TFunction } from "i18next";

type TFunctionType = TFunction<"translation", undefined>;

const getFirstNameValidation = (t: TFunctionType) =>
  Yup.string()
    .max(20, t("Validations.FirstnameLength"))
    .required(t("Validations.RequiredField"));

const getLastNameValidation = (t: TFunctionType) =>
  Yup.string().max(20, t("Validations.LastnameLength"));

const getEmailValidation = (t: TFunctionType) =>
  Yup.string()
    .email(t("Validations.InvalidEmail"))
    .max(255)
    .required(t("Validations.RequiredField"));

const getIsValidPhoneNumberValidation = (
  phoneNumberValue: string | undefined,
) => {
  if (!phoneNumberValue) return true;
  const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
  let isValid = false;

  try {
    isValid = phoneUtil.isValidNumber(phoneUtil.parse(phoneNumberValue));
  } catch {}
  return isValid;
};

const getPhoneNumberValidation = (t: TFunctionType) =>
  Yup.string()
    .nullable()
    .test(
      "is-valid-phone-number",
      t("Validations.InvalidPhoneNumber"),
      getIsValidPhoneNumberValidation,
    );

const getMandatoryPhoneNumberValidation = (t: TFunctionType) =>
  Yup.string()
    .test(
      "is-valid-phone-number",
      t("Validations.InvalidPhoneNumber"),
      getIsValidPhoneNumberValidation,
    )
    .required(t("Validations.RequiredField"));

const getEmployerValidation = (t: TFunctionType) =>
  Yup.string().when("isPartner", {
    is: "true",
    then: Yup.string().required(t("Validations.RequiredField")),
  });

const getYearOfBirthValidation = (t: TFunctionType) =>
  Yup.number()
    .integer()
    .typeError(t("Validations.YearOfBirthNumeric"))
    .max(
      parseInt(new Date().getFullYear().toString(), 10) - 16,
      t("Validations.YearOfBirthAbove16"),
    )
    .min(1901, t("Validations.YearOfBirthRange"));

export const getNewClientValidation = (
  t: TFunctionType,
  alreadyHasPhoneNumber: boolean,
) =>
  Yup.object().shape({
    firstName: getFirstNameValidation(t),
    lastName: getLastNameValidation(t),
    email: getEmailValidation(t),
    yearOfBirth: getYearOfBirthValidation(t),
    employerId: getEmployerValidation(t),
    phoneNumber: alreadyHasPhoneNumber
      ? getMandatoryPhoneNumberValidation(t)
      : getPhoneNumberValidation(t),
  });

export const validationSchemaSessionNotes = Yup.object({
  themeKey: Yup.string().required(),
  reasonForBooking: Yup.string().required(),
  discussedInSession: Yup.string().required(),
  canWeHelp: Yup.string().required(),
  nextSteps: Yup.string().required(),
});

export const validationSchemaSessionDetails = Yup.object({
  userId: Yup.string().uuid().required(),
  employeeId: Yup.string().uuid().required(),
  startedAt: Yup.date().required(),
  consultType: Yup.string().required(),
  consultLanguage: Yup.string().required(),
});
