import {
  Box,
  Typography,
  Link,
  FormControlLabel,
  Checkbox,
  Button,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  isDesktop,
  isMobile,
  isWindows,
  isMacOs,
  isAndroid,
  isIOS,
  isTablet,
} from "react-device-detect";
import { ReactComponent as GoogleAuthenticator } from "@src/components/images/logoGoogleAuthentificator.svg";
import { withSnackbar } from "@src/components/SnackBarComponent";
import authentificationService from "@src/services/authentication.service";
import { colors } from "@src/theme";
import LoginContainer from "./LoginContainer";

const useStyles = makeStyles((theme) => ({
  textList: {
    marginLeft: theme.spacing(3),
  },
  buttonConfirm: {
    padding: theme.spacing(1.5, 6),
    backgroundColor: colors.primaryBlue,
    width: 360,
    borderRadius: 28,
    marginTop: theme.spacing(3),
    "&:hover": {
      backgroundColor: colors.primaryBlue,
    },
  },
  textButton: {
    color: "white",
    letterSpacing: "1.25px",
  },
  googleImage: {
    marginRight: theme.spacing(1),
  },
  textUnderline: {
    cursor: "pointer",
    textDecoration: "underline",
    fontWeight: "bold",
    display: "inline-flex",
    verticalAlign: "top",
    alignItems: "center",
  },
  boxBoldText: {
    fontSize: "bold",
  },
}));

const FactorAuthentification = ({
  moveToNextStep,
  twoFactorEnabled,
  setTwoFactorEnabled,
  ...props
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [twoFactorRegistration, setTwoFactorRegistration] = useState();
  const [link, setLink] = useState();

  async function onSubmit() {
    await authentificationService.setTwoFactorLogin(true).then(
      () => {
        setTwoFactorEnabled(true);
        moveToNextStep();
      },
      (error) => {
        props.snackbarShowMessage(error);
      },
    );
  }

  useEffect(() => {
    authentificationService.getTwoFactorRegistration().then((registration) => {
      setTwoFactorRegistration(registration);
    });
    if (isDesktop) {
      isWindows &&
        setLink(t("LoginView.FactorAuthentificationPage.WindowsLink"));
      isMacOs && setLink(t("LoginView.FactorAuthentificationPage.MacLink"));
    } else if (isMobile) {
      isAndroid &&
        setLink(t("LoginView.FactorAuthentificationPage.WindowsLink"));
      isIOS && setLink(t("LoginView.FactorAuthentificationPage.MacLink"));
    } else if (isTablet) {
      isAndroid &&
        setLink(t("LoginView.FactorAuthentificationPage.WindowsLink"));
      isIOS && setLink(t("LoginView.FactorAuthentificationPage.MacLink"));
    }
  }, [t]);

  return (
    <LoginContainer
      title={t("LoginView.FactorAuthentificationPage.Title")}
      variant="h2"
    >
      <Formik
        initialValues={{ acceptTerms: false }}
        validationSchema={Yup.boolean().oneOf([true], "error")}
        onSubmit={onSubmit}
      >
        {({ handleChange, handleSubmit, isSubmitting, values }) => (
          <form onSubmit={handleSubmit}>
            <Box className={classes.container}>
              <Box display="flex" mt={3} alignItems="baseline">
                <Typography variant="body1">
                  {t("LoginView.FactorAuthentificationPage.FirstElementList")}
                </Typography>
                <Typography variant="body1" className={classes.textList}>
                  {t("LoginView.FactorAuthentificationPage.FirstElementText1")}
                  <Link
                    target="_blank"
                    href={link}
                    variant="body1"
                    className={classes.textUnderline}
                    underline="hover"
                  >
                    <GoogleAuthenticator className={classes.googleImage} />
                    {t(
                      "LoginView.FactorAuthentificationPage.FirstElementLinkText",
                    )}
                  </Link>
                  {t("LoginView.FactorAuthentificationPage.FirstElementText2")}
                </Typography>
              </Box>
              <Box display="flex" mt={2}>
                <Typography variant="body1">
                  {t("LoginView.FactorAuthentificationPage.SecondElementList")}
                </Typography>
                <Typography variant="body1" className={classes.textList}>
                  {t("LoginView.FactorAuthentificationPage.SecondElementText")}
                  <Box display="inline" ml={0.5} fontWeight="bold">
                    {t(
                      "LoginView.FactorAuthentificationPage.SecondElementTextBold",
                    )}
                  </Box>
                </Typography>
              </Box>
              <Box display="flex" mt={2}>
                <Typography variant="body1">
                  {t("LoginView.FactorAuthentificationPage.ThirdElementList")}
                </Typography>
                <Typography variant="body1" className={classes.textList}>
                  {t(
                    "LoginView.FactorAuthentificationPage.ThirdElementTextFirstPart",
                  )}
                  <Box display="inline" ml={0.5} mr={0.5} fontWeight="bold">
                    {t(
                      "LoginView.FactorAuthentificationPage.ThirdElementTextBold",
                    )}
                  </Box>
                  {t(
                    "LoginView.FactorAuthentificationPage.ThirdElementTextLastPart",
                  )}
                </Typography>
              </Box>
              {twoFactorRegistration && (
                <Box ml={3} mt={3} mb={3}>
                  <img src={twoFactorRegistration.qrCodeSetupImageUrl} alt="" />
                </Box>
              )}
              <FormControlLabel
                name="acceptTerms"
                className={classes.checkboxControl}
                onChange={handleChange}
                control={
                  <Checkbox
                    className={classes.checkbox}
                    disabled={twoFactorEnabled}
                  />
                }
                label={
                  <Typography
                    variant="body2"
                    className={classes.termsAndConditions}
                  >
                    {t("LoginView.FactorAuthentificationPage.CheckboxText")}
                  </Typography>
                }
              />
              <Button
                disabled={isSubmitting || !values.acceptTerms}
                className={classes.buttonConfirm}
                type="submit"
              >
                <Typography variant="subtitle2" className={classes.textButton}>
                  {t("LoginView.FactorAuthentificationPage.ConfirmButton")}
                </Typography>
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </LoginContainer>
  );
};

export default withSnackbar(FactorAuthentification);
