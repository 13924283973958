import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { STATUS_TYPE, TAG_TYPES } from "@src/constants";
import { sortPsychologistsByLastNameThenFirstName } from "@src/utils/psychologistHelpers";
import { colors } from "@src/theme";
import { TypographyWithIcon } from "./utils/TypographyWithIcon";
import { withSnackbar } from "./SnackBarComponent";

const useStyles = makeStyles((theme) => ({
  row: {
    opacity: 0.7,
    cursor: "pointer",
  },
  hover: {
    "&:hover": {
      borderRadius: 4,
    },
  },
  cell: {
    padding: theme.spacing(2, 0),
  },
  cellContain: {
    "$hover:hover &": {
      color: colors.secondaryBlue,
    },
  },
  selectedRow: {
    marginBottom: theme.spacing(-3),
  },
}));

const columns = [
  {
    id: "",
    minWidth: 30,
  },
  {
    id: "Common.LastName",
    minWidth: 200,
  },
  {
    id: "Common.FirstName",
    minWidth: 230,
  },
  {
    id: "Common.Status",
    minWidth: 60,
  },
];

const PsychologistSelectorTable = ({
  psychologists,
  selectedPsychologist,
  setSelectedPsychologist,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [sortedPsychologists, setSortedPsychologists] = useState([]);

  useEffect(() => {
    setSortedPsychologists(
      sortPsychologistsByLastNameThenFirstName(psychologists),
    );
  }, [psychologists]);

  const initials = [];
  const getLastNameInitial = (lastname) => {
    if (!lastname) return "";

    const currentInitial = lastname[0].toUpperCase();
    if (initials.some((initial) => initial === currentInitial)) return "";

    initials.push(currentInitial);
    return currentInitial;
  };

  const getStatus = (userTags) => {
    const statusTags = userTags.filter(
      (tag) => tag.tagType === TAG_TYPES.PSYCHOLOGIST_STATUS,
    );
    return (
      <Box>
        {statusTags.length > 0 &&
          statusTags.map((tag) => getStatusIcon(tag.tagName, tag.description))}
      </Box>
    );
  };

  const getStatusIcon = (status, key) => {
    switch (status) {
      case STATUS_TYPE.INACTIVE:
        return (
          <span key={key} className="material-icons-outlined">
            person_off
          </span>
        );
      case STATUS_TYPE.ACTIVE_NOT_ACCEPTING:
        return (
          <span key={key} className="material-icons-outlined">
            person_remove
          </span>
        );
      case STATUS_TYPE.ACTIVE_ACCEPTING:
        return (
          <span key={key} className="material-icons-outlined">
            person_add
          </span>
        );
      default:
        return null;
    }
  };

  return (
    <TableContainer className={classes.tableContainer}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.id}
                style={{ minWidth: column.minWidth }}
                className={classes.cell}
              >
                <Typography variant="subtitle1">{t(column.id)}</Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {sortedPsychologists.map((psychologist) => (
            <TableRow
              hover
              role="checkbox"
              tabIndex={-1}
              key={psychologist.id}
              id={psychologist.id}
              classes={{ hover: classes.hover, root: classes.row }}
              onClick={() => setSelectedPsychologist(psychologist)}
            >
              <TableCell className={classes.cell}>
                <Typography variant="subtitle1" className={classes.cellContain}>
                  {getLastNameInitial(psychologist.lastName)}
                </Typography>
              </TableCell>
              <TableCell className={classes.cell}>
                {psychologist === selectedPsychologist ? (
                  <Box className={classes.selectedRow}>
                    <TypographyWithIcon
                      variant="subtitle1"
                      iconLigature={<TaskAltIcon />}
                    >
                      {psychologist.lastName}
                    </TypographyWithIcon>
                  </Box>
                ) : (
                  <Typography
                    variant="subtitle1"
                    className={classes.cellContain}
                  >
                    {psychologist.lastName}
                  </Typography>
                )}
              </TableCell>
              <TableCell className={classes.cell}>
                <Typography variant="subtitle1" className={classes.cellContain}>
                  {psychologist.firstName}
                </Typography>
              </TableCell>
              <TableCell className={classes.cell}>
                <div className={classes.cellContain}>
                  {getStatus(psychologist.tags)}
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default withSnackbar(PsychologistSelectorTable);
