import PropTypes from "prop-types";
import {
  Dialog,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Radio,
  RadioGroup,
  FormControlLabel,
  TextField as MuiTextField,
} from "@mui/material";
import { Form, Formik } from "formik";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getNewClientValidation } from "@src/utils/validationHelpers";
import {
  GENDER,
  GenderIdentifier,
  USER_AVAILABLE_LANGUAGES,
} from "@src/constants";
import { useClientMutation } from "@src/queries/clients";
import { getGenderTranslationKey, stringToBoolean } from "@src/utils/helpers";
import { DialogActionButtons, DialogHeader } from "./DialogToolkit";
import EmployersAutocomplete from "./form/EmployersAutocomplete";
import { withSnackbar } from "./SnackBarComponent";

const useStyles = makeStyles({
  form: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  formItem: {
    flexBasis: "calc(50% - 12px)",
    height: 72,
  },
  marginTop: {
    marginTop: 13,
  },
});

const AddOrUpdateClientDialog = ({
  client = {},
  clientTags,
  isEdit = false,
  ...props
}) => {
  const { i18n, t } = useTranslation();
  const { mutateAsync: updateClient } = useClientMutation();
  const classes = useStyles();
  const navigate = useNavigate();

  const submitClientDetails = async (values) => {
    try {
      const response = await updateClient({
        ...values,
        id: client?.id,
        isPartner: stringToBoolean(values.isPartner),
      });

      if (isEdit) {
        props.onClose();
      } else {
        navigate(`/clients/${response.data.id}`);
      }
    } catch (e) {
      if (e.response.data.errors === undefined) {
        throw e;
      }

      for (const [key, value] of Object.entries(e.response.data.errors)) {
        props.snackbarShowMessage(`${key}: ${value}`);
      }
    }
  };

  return (
    <Formik
      initialValues={{
        languageCode: client.languageCode ?? i18n.language,
        employerId: client.employerId ?? "",
        firstName: client.firstName ?? "",
        lastName: client.lastName ?? "",
        email: client.email ?? "",
        yearOfBirth: client.yearOfBirth ?? "",
        phoneNumber: client.phoneNumber ?? "",
        gender:
          clientTags?.filter((tag) => tag.tagType.name === GenderIdentifier)[0]
            ?.name ?? GENDER.UNKNOWN,
        isPartner: client.isPartner?.toString() ?? "false",
      }}
      onSubmit={submitClientDetails}
      validationSchema={getNewClientValidation(t, !!client.phoneNumber)}
      enableReinitialize
      validateOnMount
    >
      {(formik) => (
        <Dialog
          {...props}
          onClose={() => {
            formik.handleReset();
            props.onClose();
          }}
        >
          <DialogHeader
            iconLigature="person_add"
            title={isEdit ? t("Clients.Edit") : t("Clients.Add")}
            outlined
          />

          <Form
            onSubmit={formik.handleSubmit}
            onChange={formik.handleChange}
            className={classes.form}
            noValidate
          >
            <TextField
              name="firstName"
              label={t("Common.FirstName")}
              formik={formik}
              required
              autoFocus
            />
            <TextField
              name="lastName"
              type="text"
              label={t("Common.LastName")}
              formik={formik}
            />
            <TextField
              disabled={isEdit}
              name="email"
              type="email"
              label={t("Common.Email")}
              formik={formik}
              required
            />
            <TextField
              name="yearOfBirth"
              type="number"
              label={t("Common.YearOfBirth")}
              formik={formik}
            />
            <TextField
              extraStyling={classes.marginTop}
              name="phoneNumber"
              type="text"
              label={t("Common.PhoneNumber")}
              formik={formik}
            />
            <FormControl className={classes.formItem} required>
              <InputLabel id="languageCode-label" sx={{ left: -14 }}>
                {t("Common.Language")}
              </InputLabel>
              <Select
                name="languageCode"
                labelId="languageCode-label"
                id="languageCode"
                onChange={formik.handleChange}
                value={formik.values.languageCode}
                variant="standard"
              >
                {USER_AVAILABLE_LANGUAGES.map(({ label, value }) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className={classes.formItem} required>
              <EmployersAutocomplete
                className={classes.formItem}
                error={
                  stringToBoolean(formik.values.isPartner) &&
                  !formik.values.employerId
                }
                onChange={(employer) => {
                  formik.setFieldValue("employerId", employer?.id ?? "");
                }}
                value={formik.values.employerId}
                formik={formik}
                name="employerId"
                label={t("Common.Employer")}
              />
            </FormControl>
            <FormControl className={classes.formItem} required>
              <InputLabel id="gender-label" sx={{ left: -14 }}>
                {t("Common.Gender")}
              </InputLabel>
              <Select
                name="gender"
                labelId="gender-label"
                id="gender"
                onChange={formik.handleChange}
                value={formik.values.gender}
                variant="standard"
              >
                {Object.values(GENDER).map((value) => (
                  <MenuItem key={value} value={value}>
                    {t(getGenderTranslationKey(value))}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <div>
              <InputLabel id="family-label">
                {t("Common.FamilyMember")}
              </InputLabel>
              <FormControl className={classes.formItem} required>
                <RadioGroup
                  row
                  value={formik.values.isPartner}
                  name="isPartner"
                  onChange={formik.handleChange}
                >
                  <FormControlLabel
                    value={false}
                    control={<Radio data-cy="no-radio-button" />}
                    label={t("Common.No")}
                  />
                  <FormControlLabel
                    value
                    control={<Radio data-cy="yes-radio-button" />}
                    label={t("Common.Yes")}
                  />
                </RadioGroup>
              </FormControl>
            </div>
            <DialogActionButtons
              onCancel={() => {
                formik.handleReset();
                props.onClose();
              }}
            />
          </Form>
        </Dialog>
      )}
    </Formik>
  );
};

const TextField = ({ formik, name, extraStyling = "", ...props }) => {
  const classes = useStyles();
  return (
    <MuiTextField
      {...props}
      id={name}
      name={name}
      className={`${classes.formItem} ${extraStyling}`}
      value={formik.values[name]}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      error={formik.touched[name] && Boolean(formik.errors[name])}
      helperText={formik.touched[name] && formik.errors[name]}
      variant="standard"
    />
  );
};

TextField.propTypes = {
  formik: PropTypes.object,
  name: PropTypes.string,
  extraStyling: PropTypes.string,
};

export default withSnackbar(AddOrUpdateClientDialog);
