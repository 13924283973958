import { useState } from "react";
import { Box, Dialog, FormGroup, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import {
  psychologistLanguageTagName,
  psychologistStatusTagName,
  psychologistGenderTagName,
  useMultipleTagsQueries,
  sessionTypeTagName,
} from "@src/queries/tags";
import { useThemesQuery } from "@src/queries/themes";
import { TAG_TYPES } from "@src/constants";
import { colors } from "@src/theme";
import CustomCheckbox from "./form/CustomCheckbox";
import { DialogActionButtons, DialogHeader } from "./DialogToolkit";
import TagSelector from "./TagSelector";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    minHeight: 500,
    width: 500,
    overflowX: "hidden",
    overflowY: "auto",
  },
  tagTypeClass: {
    color: colors.primaryBlue,
  },
  filterInformationHeader: {
    padding: theme.spacing(1, 0, 0, 0),
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(0),
  },
}));

const FilterPsychologistsDialog = ({
  onClose = () => {},
  setFilters = () => {},
  ...props
}) => {
  const { t } = useTranslation();

  const { taggedData } = useMultipleTagsQueries([
    psychologistStatusTagName,
    sessionTypeTagName,
    psychologistLanguageTagName,
    psychologistGenderTagName,
  ]);
  const psychologistStatusTags = taggedData[psychologistStatusTagName];
  const sessionTypeTags = taggedData[sessionTypeTagName];
  const languageTags = taggedData[psychologistLanguageTagName];
  const { data: allThemes } = useThemesQuery();

  const classes = useStyles();
  const [psychologistStatus, setPsychologistStatus] = useState([]);
  const [psychologistSessionTypes, setPsychologistSessionTypes] = useState([]);
  const [psychologistLanguage, setPsychologistLanguage] = useState([]);
  const [selectedPsychologistThemes, setSelectedPsychologistThemes] = useState(
    [],
  );

  const formik = useFormik({
    initialValues: {
      psychologistStateStatus: psychologistStatus,
    },
    onSubmit: async () => {
      const tags = [];
      const themes = [];

      psychologistLanguage.forEach((tag) =>
        tags.push({ tagType: TAG_TYPES.PSYCHOLOGIST_LANGUAGE, tagName: tag }),
      );
      psychologistStatus.forEach((tag) =>
        tags.push({ tagType: TAG_TYPES.PSYCHOLOGIST_STATUS, tagName: tag }),
      );
      psychologistSessionTypes.forEach((tag) =>
        tags.push({ tagType: TAG_TYPES.SESSION_TYPE, tagName: tag }),
      );
      selectedPsychologistThemes.forEach((theme) =>
        themes.push({ key: theme }),
      );

      // will show a  warning/error if undefined - intentional behavior
      setFilters(tags, themes);
      onClose();
    },
    enableReinitialize: true,
  });

  const onChangeTheme = (theme) => {
    if (selectedPsychologistThemes.indexOf(theme) === -1) {
      // its not a selected theme - select it
      setSelectedPsychologistThemes(selectedPsychologistThemes.concat(theme));
    } else {
      // its already selected - uncheck it
      setSelectedPsychologistThemes(
        selectedPsychologistThemes.filter((themes) => themes !== theme),
      );
    }
  };

  return (
    <Dialog
      {...props}
      onClose={(event) => {
        formik.handleReset(event);
        onClose();
      }}
    >
      <DialogHeader
        iconLigature="filter_list"
        title={t("Search.Filter")}
        outlined
        onFilter={(event) => {
          setPsychologistStatus([]);
          setPsychologistSessionTypes([]);
          setPsychologistLanguage([]);
          setSelectedPsychologistThemes([]);
          formik.handleReset(event);
        }}
        textRightButton={t("Common.ClearAll")}
      />
      <Box className={classes.form}>
        <Grid container>
          <Grid item xs={6}>
            <TagSelector
              tagTypeName={t("Tags.PsychologistStatusTagsTitle")}
              tagState={psychologistStatus}
              setTagState={setPsychologistStatus}
              tagType={psychologistStatusTags}
            />
          </Grid>
          <Grid item xs={6}>
            <TagSelector
              tagTypeName={t("Tags.SessionTypeTagsTitle")}
              tagState={psychologistSessionTypes}
              setTagState={setPsychologistSessionTypes}
              tagType={sessionTypeTags}
            />
          </Grid>
          <Grid item xs={12}>
            <TagSelector
              tagTypeName={t("Tags.LanguageTagsTitle")}
              tagState={psychologistLanguage}
              setTagState={setPsychologistLanguage}
              tagType={languageTags}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption" className={classes.tagTypeClass}>
              {t("SharedStrings.Themes")}
            </Typography>
            <Grid container>
              <Grid item xs={6}>
                <FormGroup>
                  {allThemes
                    .slice(0, Math.ceil(allThemes.length / 2))
                    .map((theme) => (
                      <CustomCheckbox
                        key={theme.key}
                        value={theme.key}
                        onCheck={onChangeTheme}
                        isChecked={
                          selectedPsychologistThemes.indexOf(theme.key) > -1
                        }
                      />
                    ))}
                </FormGroup>
              </Grid>
              <Grid item xs={6}>
                <FormGroup>
                  {allThemes
                    .slice(Math.ceil(allThemes.length / 2))
                    .map((theme) => (
                      <CustomCheckbox
                        key={theme.key}
                        value={theme.key}
                        onCheck={onChangeTheme}
                        isChecked={
                          selectedPsychologistThemes.indexOf(theme.key) > -1
                        }
                      />
                    ))}
                </FormGroup>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <DialogActionButtons
        onCancel={(event) => {
          formik.handleReset(event);
          onClose();
        }}
        onSubmit={formik.handleSubmit}
      />
    </Dialog>
  );
};

export default FilterPsychologistsDialog;
