import { useRef } from "react";
import {
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  Typography,
  TablePagination,
  Grid,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import { useTranslation } from "react-i18next";
import { useClientEmotionsOverviewQuery } from "@src/queries/clients";
import { formatScore } from "@src/queries/utils";
import FormattedDate from "./FormattedDate";
import { TypographyWithIcon } from "./utils/TypographyWithIcon";

const useStyles = makeStyles(({ spacing, colors, breakpoints }) => ({
  tableContainer: {
    overflowX: "auto",
  },
  table: {
    minWidth: 650,
    borderCollapse: "unset",
  },
  scoreText: {
    fontSize: "16px",
    fontWeight: "400",
    letterSpacing: "0.15px",
    opacity: "0.7",
    position: "sticky",
  },
  scoreTextCell: {
    minWidth: 221,
  },
  scoreText2: {
    visibility: "hidden",
  },
  dateText: {
    fontWeight: "500",
    minWidth: 250,
    borderColor: colors.primaryDarkBlue,
  },
  spacerTablePagination: {
    flex: "0",
  },
  actionsTablePagination: {
    marginLeft: spacing(0),
  },
  toolbarTablePagination: {
    paddingLeft: spacing(0),
    paddingTop: spacing(1),
  },
  tableRef: {
    overflow: "auto",
    whiteSpace: "nowrap",
  },
  gridTable: {
    overflowX: "hidden",
    maxWidth: 750,
  },
  scoreGrid: {
    marginRight: spacing(0),
    marginTop: spacing(-1),
    [breakpoints.down("lg")]: {
      marginRight: spacing(2),
    },
  },
  borderColorText: {
    borderColor: colors.primaryDarkBlue,
  },
  empty: {
    paddingBottom: spacing(2),
  },
}));

const EmotionsOverview = ({ clientId }) => {
  const { data } = useClientEmotionsOverviewQuery(
    clientId,
    "general-wellbeing",
  );
  const dataSorted =
    data?.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt)) || [];
  const classes = useStyles();
  const { t } = useTranslation();
  const ref = useRef();

  const handleClick = (direction) => {
    if (direction === "right") {
      ref && (ref.current.scrollLeft += 250);
    } else {
      ref && (ref.current.scrollLeft -= 250);
    }
  };

  return (
    dataSorted.length > 0 && (
      <TableContainer
        component={Paper}
        className={classes.tableContainer}
        sx={{ padding: "24px" }}
      >
        <TypographyWithIcon
          variant="h5"
          iconLigature="sentiment_satisfied"
          outlined
        >
          {t("Common.Emotional.Title")}
        </TypographyWithIcon>

        <Grid container spacing={0}>
          <Grid
            item
            xs={2}
            sm={2}
            md={2}
            lg={2}
            xl={2}
            className={classes.scoreGrid}
          >
            <TableRow>
              <TableCell className={classes.borderColorText}>
                <Typography className={classes.scoreText2}>'</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.scoreTextCell}>
                <Typography className={classes.scoreText}>
                  {t("Common.Emotional.Score")}
                </Typography>
              </TableCell>
            </TableRow>
          </Grid>
          <Grid
            item
            xs={7}
            sm={7}
            md={7}
            lg={7}
            xl={7}
            ref={ref}
            className={classes.gridTable}
          >
            <Table className={classes.table} aria-label="client emotions table">
              <TableHead>
                <TableRow className={classes.rowDateText}>
                  {Object.values(dataSorted).map((dataObject, index) => (
                    <TableCell className={classes.dateText} key={index}>
                      <FormattedDate date={dataObject.createdAt} />
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow className={classes.tableRef}>
                  {Object.values(dataSorted).map((dataObject, index) => (
                    <TableCell key={index}>
                      <Typography className={classes.scoreText}>
                        {t(`Common.Emotional.ScoreLevel`, {
                          key: formatScore(dataObject.score),
                        })}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
          <Grid item>
            <TableRow>
              <TablePagination
                className={classes.borderColorText}
                nextIconButtonProps={{ onClick: () => handleClick("right") }}
                backIconButtonProps={{ onClick: () => handleClick("left") }}
                labelDisplayedRows={() => ""}
                rowsPerPageOptions={[]}
                classes={{
                  spacer: classes.spacerTablePagination,
                  actions: classes.actionsTablePagination,
                  toolbar: classes.toolbarTablePagination,
                }}
              />
            </TableRow>
            <TableRow>
              <TableCell className={classes.empty}>
                <Typography className={classes.scoreText2}>'</Typography>
              </TableCell>
            </TableRow>
          </Grid>
        </Grid>
      </TableContainer>
    )
  );
};

export default EmotionsOverview;
