import { useEffect, useState } from "react";
import { Tab, Tabs, Box, Typography } from "@mui/material";
import { makeStyles, withStyles } from "@mui/styles";
import { colors } from "@src/theme";
import moment from "moment";

const useStyles = makeStyles(() => ({
  dateText: {
    color: "white",
    opacity: 1,
    textTransform: "none",
  },
  unavailableTimeSlots: {
    opacity: 0.5,
  },
}));

const CustomTab = withStyles((theme) => ({
  root: {
    backgroundColor: colors.tertiaryGreen,
    borderTopRightRadius: 16,
    borderTopLeftRadius: 16,
    width: 84,
    height: 56,
    margin: "auto",
    minWidth: "auto",
    [theme.breakpoints.down("md")]: {
      height: 46,
      width: 44,
      borderTopRightRadius: 8,
      borderTopLeftRadius: 8,
    },
  },
  selected: {
    backgroundColor: "white",
  },
}))(Tab);

const DayTab = ({
  next,
  setCurrentDateTime,
  currentDateTime,
  weekDaysAvailabilityStatus,
}) => {
  const classes = useStyles();
  const sundayWeekDay = 6;
  const getCurrentWeekDay = () => {
    const weekDayOfCurrentDateTime = moment(currentDateTime).utc().weekday();
    return weekDayOfCurrentDateTime === 0
      ? sundayWeekDay
      : weekDayOfCurrentDateTime - 1;
  };
  const [currentWeekDay, setCurrentWeekDay] = useState(getCurrentWeekDay());

  useEffect(() => {
    setCurrentWeekDay(getCurrentWeekDay());
  });

  const handleChange = (event, newValue) => {
    setCurrentWeekDay(newValue);
  };

  const weekStart = moment().utc().startOf("isoWeek");
  const dates = [];

  for (let i = 0; i <= 6; i += 1) {
    const date = moment(weekStart).add(i, "days").add(next, "weeks");
    const time = date.toISOString();
    const day = date.format("ddd");
    const dayNumber = date.date();
    dates.push({
      time,
      day,
      dayNumber,
      disable: date < moment.utc().startOf("day"),
      weekDay: date.day() === 0 ? sundayWeekDay : date.day() - 1,
    });
  }

  const handleClick = (data) => {
    dates.map((dataElement) => {
      if (dataElement.dayNumber === data.dayNumber) {
        setCurrentDateTime(data.time);
      }
      return null;
    });
  };

  return (
    <Tabs
      TabIndicatorProps={{ style: { backgroundColor: "white" } }}
      value={currentWeekDay}
      onChange={handleChange}
      aria-label="basic tabs example"
    >
      {dates.map((data) => (
        <CustomTab
          data-cy="day-tab"
          key={data.day}
          onClick={() => handleClick(data)}
          disabled={data.disable}
          className={
            weekDaysAvailabilityStatus[data.weekDay] === false
              ? classes.unavailableTimeSlots
              : ""
          }
          label={
            <Box>
              <Typography
                variant="subtitle2"
                className={classes.dateText}
                style={{
                  color:
                    currentWeekDay === data.weekDay && colors.primaryDarkBlue,
                }}
              >
                {data.day}
              </Typography>
              <Typography
                variant="subtitle2"
                className={classes.dateText}
                style={{
                  color:
                    currentWeekDay === data.weekDay && colors.primaryDarkBlue,
                }}
              >
                {data.dayNumber}
              </Typography>
            </Box>
          }
        />
      ))}
    </Tabs>
  );
};

export default DayTab;
