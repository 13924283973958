import axios from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { queryIdentifiers } from "@src/utils/constants";
import moment from "moment";
import { getWeekStartQueryKey } from "@src/utils/formatting";
import { apiPaths } from "@src/constants";
import { consultStaleTime } from "./staletimes";

export const identifier = "scheduler";

export const useSchedulerGetAvailableSlotsForPsychologist = (
  timeZone,
  startDate,
  endDate,
  psychologistId,
) => {
  const startDateOfWeek = moment(startDate).utc().startOf("isoWeek");
  const queryClient = useQueryClient();
  return useQuery(
    [
      queryIdentifiers.availableTimeSlots,
      timeZone,
      getWeekStartQueryKey(startDateOfWeek),
      psychologistId,
    ],
    async () => {
      let endDateParameter = "";
      if (endDate !== undefined) {
        endDateParameter = `&endDate=${endDate}`;
      }
      const { data } = await axios.get(
        `${
          apiPaths.availableTimeSlots
        }/${psychologistId}?timeZone=${timeZone}&startDate=${startDateOfWeek.toISOString()}${endDateParameter}`,
      );
      if (!endDate && data?.length === 0) {
        // handle no available slots
        return null;
      }
      if (data.length === 0) {
        return createWeekWithNoAvailableTimeSlots(startDateOfWeek);
      }
      return groupByTimeSlotsByDate(data, timeZone);
    },
    {
      onSuccess: (data) => {
        data
          ? updateAvailableSlotsCache(
              startDateOfWeek,
              timeZone,
              data,
              queryClient,
            )
          : queryClient.setQueryData(
              [
                queryIdentifiers.availableTimeSlots,
                timeZone,
                getWeekStartQueryKey(startDateOfWeek),
              ],
              data,
            );
      },
      staleTime: consultStaleTime,
      enabled: !!startDate && !!psychologistId,
    },
  );
};

export const useGetAvailableSlotsForCurrentPsychologist = (
  timeZone,
  startDate,
  endDate,
) => {
  const startDateOfWeek = moment(startDate).utc().startOf("isoWeek");
  const queryClient = useQueryClient();
  return useQuery(
    [
      queryIdentifiers.availableTimeSlots,
      timeZone,
      getWeekStartQueryKey(startDateOfWeek),
    ],
    async () => {
      let endDateParameter = "";
      if (endDate !== undefined) {
        endDateParameter = `&endDate=${endDate}`;
      }
      const { data } = await axios.get(
        `${
          apiPaths.availableTimeSlots
        }?timeZone=${timeZone}&startDate=${startDateOfWeek.toISOString()}${endDateParameter}`,
      );
      if (!endDate && data?.length === 0) {
        // handle no available slots
        return null;
      }
      if (data.length === 0) {
        return createWeekWithNoAvailableTimeSlots(startDateOfWeek);
      }
      return groupByTimeSlotsByDate(data, timeZone);
    },
    {
      onSuccess: (data) => {
        data
          ? updateAvailableSlotsCache(
              startDateOfWeek,
              timeZone,
              data,
              queryClient,
            )
          : queryClient.setQueryData(
              [
                queryIdentifiers.availableTimeSlots,
                timeZone,
                getWeekStartQueryKey(startDateOfWeek),
              ],
              data,
            );
      },
      staleTime: consultStaleTime,
      enabled: !!startDate,
    },
  );
};

const groupByTimeSlotsByDate = (timeSlots, timeZone) => {
  const startDateInTimeZone = moment.tz(timeSlots[0].startTime, timeZone);

  const startDateOfWeek = moment.utc(startDateInTimeZone).startOf("isoWeek");
  const endDateOfWeek = moment.utc(startDateInTimeZone).endOf("isoWeek");
  let date = startDateOfWeek;

  const result = new Array(7);
  let counter = 0;
  while (endDateOfWeek.isSameOrAfter(date)) {
    const timeSlotsInDay = timeSlots.filter((timeSlot) => {
      return (
        moment.tz(timeSlot.startTime, timeZone).format("LL") ===
        date.format("LL")
      );
    });
    result[counter] = { date: date.toISOString(), timeSlots: timeSlotsInDay };
    counter++;
    date = moment(date).add(1, "days");
  }
  return result;
};

const updateAvailableSlotsCache = (startDate, timeZone, data, queryClient) => {
  if (!Array.isArray(data) || data.length === 0) return;

  const startDateOfWeekWithAvailableSlots = moment(data[0].date);
  if (startDateOfWeekWithAvailableSlots.isAfter(startDate)) {
    let startDateOfWeek = moment(startDate).startOf("isoWeek");

    while (startDateOfWeekWithAvailableSlots.isAfter(startDateOfWeek)) {
      queryClient.setQueryData(
        [
          queryIdentifiers.availableTimeSlots,
          timeZone,
          getWeekStartQueryKey(startDateOfWeek),
        ],
        createWeekWithNoAvailableTimeSlots(startDateOfWeek),
      );

      startDateOfWeek = moment(startDateOfWeek).add(1, "isoWeek");
    }

    queryClient.setQueryData(
      [
        queryIdentifiers.availableTimeSlots,
        timeZone,
        getWeekStartQueryKey(startDateOfWeekWithAvailableSlots),
      ],
      data,
    );
  }
};

const createWeekWithNoAvailableTimeSlots = (startDateOfWeek) => {
  const endDateOfWeek = moment(startDateOfWeek).utc().endOf("isoWeek");
  const result = [];
  let date = startDateOfWeek;
  while (date <= endDateOfWeek) {
    result.push({ date: date.toISOString(), timeSlots: [] });
    date = moment(date).add(1, "day");
  }
  return result;
};

export const useSchedulerBookConsultWithClient = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ time, timezone, tags, clientId }) => {
      return axios.post(
        `${apiPaths.scheduler}/book-consult-with-client/${clientId}`,
        {
          time,
          timezone,
          tags,
        },
      );
    },
    {
      onSuccess: ({ data }) => {
        queryClient.invalidateQueries([queryIdentifiers.availableTimeSlots]);
        queryClient.invalidateQueries(["clients", data.clientId, "consults"]);
        queryClient.invalidateQueries(["client-consults", data.clientId]);
      },
    },
  );
};

export const useSchedulerBookConsultWithClientAndPsychologist = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ time, timezone, tags, clientId, psychologistId }) => {
      return axios.post(
        `${apiPaths.scheduler}/book-consult-with-client/${clientId}/${psychologistId}`,
        {
          time,
          timezone,
          tags,
        },
      );
    },
    {
      onSuccess: ({ data }) => {
        queryClient.invalidateQueries([queryIdentifiers.availableTimeSlots]);
        queryClient.invalidateQueries(["clients", data.clientId, "consults"]);
        queryClient.invalidateQueries(["client-consults", data.clientId]);
      },
    },
  );
};

export const useCancelSession = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ consultId, reasonCancel }) => {
      const { data } = await axios.put(
        `${apiPaths.scheduler}/consult/${consultId}/cancel`,
        {
          reason: reasonCancel,
        },
      );

      return data;
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(queryIdentifiers.availableTimeSlots);
        queryClient.invalidateQueries(["clients", data.clientId, "consults"]);
        queryClient.setQueryData(
          [identifier, data.id, "consult", "cancel"],
          data,
        );
        queryClient.invalidateQueries([queryIdentifiers.psychologists]);
      },
    },
  );
};

export const useRescheduleSession = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ consultId, startTime, endTime, reasonReschedule, timezone }) => {
      return axios.put(
        `${apiPaths.scheduler}/consult/${consultId}/reschedule`,
        {
          newStart: startTime,
          endStart: endTime,
          reason: reasonReschedule,
          timezone,
        },
      );
    },
    {
      onSuccess: ({ data }) => {
        queryClient.invalidateQueries([queryIdentifiers.availableTimeSlots]);
        queryClient.invalidateQueries(["clients", data.clientId, "consults"]);
        queryClient.setQueryData(
          [identifier, data.id, "consult", "reschedule"],
          data,
        );
        queryClient.invalidateQueries([
          identifier,
          data.id,
          "consult",
          "reschedule",
        ]);
        queryClient.invalidateQueries([queryIdentifiers.psychologists]);
      },
    },
  );
};

export const useGetTimezones = () => {
  return useQuery([queryIdentifiers.timezones], async () => {
    const { data } = await axios.get(apiPaths.timezones);
    return data;
  });
};
