import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { getCurrentUser } from "@src/services/authentication.service";
import en from "./locales/en.json";
import nl from "./locales/nl.json";
import de from "./locales/de.json";

const defaultLanguage = "nl";
const defaultCulture = "nl-NL";

const resources = {
  en: {
    translation: en,
  },
  nl: {
    translation: nl,
  },
  de: {
    translation: de,
  },
};

i18n.use(initReactI18next).init({
  resources: sessionStorage.getItem("skip_translations") ? null : resources,
  lng: defaultLanguage,
  fallbackLng: defaultLanguage,
  keySeparator: true, // we do not use keys in form messages.welcome
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
});

export const getUserLanguage = () => {
  const user = getCurrentUser();

  switch (user?.languageCode) {
    case "en-GB":
    case "nl-NL":
      return user.languageCode;
    default:
      return sessionStorage.getItem("language") || defaultCulture;
  }
};

export default i18n;
