import { useState } from "react";
import { useTranslation } from "react-i18next";
import authenticationService from "@src/services/authentication.service";
import PageTitle from "@src/components/PageTitle";
import { withSnackbar } from "@src/components/SnackBarComponent";
import { useClientsQuery } from "@src/queries/clients";
import ClientsList from "./ClientPage/ClientsList";

const AllClientsView = ({ snackbarShowMessage }) => {
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState();
  const { t } = useTranslation();
  const { data, isFetching, refetch } = useClientsQuery({
    page,
    search,
  });
  const { isManagerOrAdminOrHost, isSupport } = authenticationService;
  return (
    <>
      <PageTitle title={t("Clients.Pagetitle")} />
      <ClientsList
        data={data}
        isFetching={isFetching}
        refetchData={refetch}
        page={page}
        setPage={setPage}
        setSearch={setSearch}
        snackbarShowMessage={snackbarShowMessage}
        showClientDetails={isManagerOrAdminOrHost()}
        ableToAddClient={isManagerOrAdminOrHost()}
        ableToInitiateChat={isManagerOrAdminOrHost()}
        ableToAnonymizeClient={isSupport()}
      />
    </>
  );
};

export default withSnackbar(AllClientsView);
