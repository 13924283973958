import { Grid } from "@mui/material";

interface FooterProps {
  footerStyle: string;
  leftChildren: React.ReactNode;
  centerChildren: React.ReactNode;
  rightChildren: React.ReactNode;
}

/**
 * Generic footer component
 */
const Footer: React.FC<FooterProps> = ({
  footerStyle,
  leftChildren,
  centerChildren,
  rightChildren,
}) => {
  return (
    <Grid
      container
      spacing={1}
      justifyContent="space-between"
      alignItems="center"
      columns={12}
      className={footerStyle}
    >
      <Grid item xs="auto">
        {leftChildren}
      </Grid>
      <Grid item xs="auto">
        {centerChildren}
      </Grid>
      <Grid item xs="auto" display="flex">
        {rightChildren}
      </Grid>
    </Grid>
  );
};

export default Footer;
