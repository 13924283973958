export const colors = {
  primaryBlue: "#48B4FF",
  disabledBlue: "#A1D7Fd",
  primaryDarkBlue: "#152361",
  primaryGreen: "#00A885",
  secondaryGreen: "#0C8E77",
  primarySkin: "#FFF8ED",
  secondarySkin: "#FFFDFB",
  secondaryBlue: "#3BA5EE",
  tertiaryGreen: "#187363",
  lightGray: "#E6E6E6",
  almostWhite: "#f9f9f9",
  blueGray: "#59628d",
  red: "#FF0000",
  primaryBlack: "#333333",
};

export const LOW_SCORE_THRESHOLD = 1.0;
export const MEDIUM_SCORE_THRESHOLD = 2.5;
export const HIGH_SCORE_THRESHOLD = 4.0;

export const getScoreColor = (score) => {
  if (score > HIGH_SCORE_THRESHOLD) {
    return colors.primaryGreen;
  }

  if (score > MEDIUM_SCORE_THRESHOLD) {
    return colors.primaryBlue;
  }

  return colors.primaryDarkBlue;
};
