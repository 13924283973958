// Dividing by 1 removes trailing 0's in case of integer values (eg. 1.0 -> 1)
export const formatScore = (score) =>
  score !== undefined ? score.toPrecision(2) / 1 : undefined;

export const getNextPageParam = (result) => {
  if (!result) {
    return 1;
  }

  const {
    pagination: { total, currentPage, limit },
  } = result;

  if (!currentPage) {
    return 1;
  }

  const maxPage = Math.floor(total / limit);

  // No more pages available
  if (maxPage === currentPage) {
    return undefined;
  }

  const nextPage = Math.min(maxPage, currentPage + 1);

  return nextPage;
};

export const getPreviousPageParam = (result) => {
  if (!result) {
    return undefined;
  }

  const {
    pagination: { currentPage },
  } = result;

  if (currentPage === 0) {
    return undefined;
  }

  return Math.max(0, currentPage - 1);
};

export const handlePagination = {
  getNextPageParam,
  getPreviousPageParam,
};

export const isEmptyQueryResult = (val) => {
  if (!val) {
    return false;
  }

  if (typeof val === "object") {
    if (val.pagination) {
      return val.pagination.total === 0;
    }
    return Object.keys(val).length === 0;
  }

  if (Array.isArray(val)) {
    return val.length === 0;
  }

  return !!val;
};
