/* eslint-disable react/prop-types */
import { Grid, Paper, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  container: {
    padding: "20px 0px",
    marginBottom: 12,
  },
});

const NothingFound = ({ translationKey: translationKeyProp }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const translationKey = translationKeyProp || "Common.NothingFound";
  return (
    <Grid item xs={12}>
      <Paper elevation={3} className={classes.container}>
        <Typography variant="h4" align="center">
          {t(translationKey)}
        </Typography>
      </Paper>
    </Grid>
  );
};

export default NothingFound;
