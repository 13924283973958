import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useExpertsConsultsQuery } from "@src/queries/experts";
import { STATUS } from "@src/constants";
import SessionsView from "./SessionsView";

const columns: { id: string; minWidth: number }[] = [
  {
    id: "MySessions.DateTime",
    minWidth: 130,
  },
  {
    id: "",
    minWidth: 30,
  },
  {
    id: "MySessions.Client",
    minWidth: 250,
  },
  {
    id: "MySessions.Session",
    minWidth: 116,
  },
  {
    id: "MySessions.Type",
    minWidth: 125,
  },
  {
    id: "MySessions.Status",
    minWidth: 250,
  },
];

const MySessionsView = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  const [statusFilter, setStatusFilter] = useState(STATUS.SCHEDULED);

  const { data, isFetching } = useExpertsConsultsQuery({
    page,
    search,
    status: statusFilter,
  });

  const consults = data?.data ?? [];
  const total = data?.pagination.total ?? 0;
  const hasNextPage = data?.pagination.hasNextPage ?? false;

  return (
    <SessionsView
      titleText={t("MySessions.PageTitle")}
      placeholderSearch={t("MySession.List.SearchPlaceholder")}
      page={page}
      statusFilter={statusFilter}
      setPage={setPage}
      setSearch={setSearch}
      setStatusFilter={setStatusFilter}
      showStatusFilter
      columns={columns}
      isFetching={isFetching}
      consults={consults}
      total={total}
      hasNextPage={hasNextPage}
      showCopyJoinLink
      showReferralToolkitIcon
    />
  );
};

export default MySessionsView;
