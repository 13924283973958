import { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { TAG_TYPES, LANGUAGE_TYPES } from "@src/constants";
import { useTranslation } from "react-i18next";
import LanguageCard from "./LanguageCard";
import BookingContainer from "./BookingContainer";

interface LanguageProps {
  moveToNextStep: () => void;
  setLanguage: (language: string) => void;
  psychologistTags: any[];
  clientFirstName?: string;
}
type LanguageType = { code: string; name: string };

const Language: React.FC<LanguageProps> = ({
  moveToNextStep,
  setLanguage,
  psychologistTags,
  clientFirstName,
}) => {
  const { i18n, t } = useTranslation();

  const [availableLanguages, setAvailableLanguages] = useState<LanguageType[]>(
    [],
  );

  const handlePressLanguageCard = (language: string) => {
    setLanguage(language);
    moveToNextStep();
  };
  useEffect(() => {
    const languageTags = psychologistTags.filter(
      (tag) =>
        tag.tagType.toLowerCase() ===
        TAG_TYPES.PSYCHOLOGIST_LANGUAGE.toLowerCase(),
    );

    const formattedTags = languageTags.map<LanguageType>((result) => ({
      code: result.tagName,
      name: result.description,
    }));

    const languageCode = i18n.language.slice(0, i18n.language.indexOf("-"));

    formattedTags.sort((prevLanguage, nextLanguage) =>
      prevLanguage.name.localeCompare(nextLanguage.name),
    );

    const priorityLanguages = [
      LANGUAGE_TYPES.ENGLISH_GB,
      LANGUAGE_TYPES.DUTCH,
      LANGUAGE_TYPES.GERMAN,
      LANGUAGE_TYPES.FRENCH,
      LANGUAGE_TYPES.SPANISH,
    ];

    const languageSpecificOrder = new Array<LanguageType>();
    let languageFromCode;

    priorityLanguages.forEach((langCode) => {
      languageFromCode = formattedTags.find((item) => item.code === langCode);

      if (languageFromCode) {
        languageSpecificOrder.push(languageFromCode);
      }
    });

    const currentLanguage = languageSpecificOrder.find(
      (item) => item.code === languageCode,
    );

    if (currentLanguage) {
      const currentLanguageIndex =
        languageSpecificOrder.indexOf(currentLanguage);
      languageSpecificOrder.splice(currentLanguageIndex, 1);
      languageSpecificOrder.splice(0, 0, currentLanguage);
    }

    formattedTags.forEach((lang) => {
      if (!languageSpecificOrder.includes(lang)) {
        languageSpecificOrder.push(lang);
      }
    });
    setAvailableLanguages(languageSpecificOrder);
  }, [i18n.language, psychologistTags]);

  const title = clientFirstName
    ? t("Booking.ForClient.Language.Title", { firstname: clientFirstName })
    : t("Booking.Language.Title");

  return (
    <BookingContainer title={title} isMediumContainerWanted>
      <Grid container spacing={2} justifyContent="center">
        {availableLanguages.map((language) => (
          <Grid
            item
            xs={12}
            md={4}
            className="cursor-pointer max-w-[264px] md:max-w-[328px]"
            onClick={() => handlePressLanguageCard(language.code)}
            key={language.code}
            data-cy="language"
          >
            <LanguageCard languageCode={language.code} />
          </Grid>
        ))}
      </Grid>
    </BookingContainer>
  );
};

export default Language;
