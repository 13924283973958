import { useState, useRef } from "react";
import { Dialog } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import SearchBox from "@src/components/SearchBox";
import { useDebouncedCallback } from "use-debounce";
import PsychologistSelectorTable from "@src/components/PsychologistSelectorTable";
import { usePsychologistsQuery } from "@src/queries/experts";
import { withSnackbar } from "./SnackBarComponent";
import { DialogActionButtons, DialogHeader } from "./DialogToolkit";

const useStyles = makeStyles(() => ({
  dialog: {
    maxHeight: 655,
    width: 600,
  },
  searchBox: {
    marginBottom: 24,
  },
}));

const PsychologistSelectorDialog = ({ setIsOpen, clientId }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();

  const [selectedPsychologist, setSelectedPsychologist] = useState();
  const [search, setSearch] = useState();

  const {
    data: { data: allPsychologists },
  } = usePsychologistsQuery({ limit: 10000, search });

  const refCancel = useRef();

  const onSearchChange = useDebouncedCallback((searchValue) => {
    setSearch(searchValue);
  }, 500);

  const onSelection = async () => {
    navigate(`/book-session/${clientId}/${selectedPsychologist.id}`);

    setIsOpen(false);
  };

  return (
    <Dialog
      open
      classes={{ paper: classes.dialog }}
      data-cy="psychologist-selector"
    >
      <DialogHeader
        iconLigature="psychology"
        title={t("PsychologistSelection.Title")}
        outlined
      />
      <SearchBox
        onChange={onSearchChange}
        placeholder={t("PsychologistSelection.SearchPlaceholder")}
        refCancel={refCancel}
        className={classes.searchBox}
      />

      <PsychologistSelectorTable
        psychologists={allPsychologists}
        setSelectedPsychologist={setSelectedPsychologist}
        selectedPsychologist={selectedPsychologist}
      />

      <DialogActionButtons
        onSubmitText={t("PsychologistSelection.Select").toUpperCase()}
        onCancelText={t("PsychologistSelection.Cancel").toUpperCase()}
        onCancel={() => setIsOpen(false)}
        onSubmit={onSelection}
      />
    </Dialog>
  );
};

export default withSnackbar(PsychologistSelectorDialog);
