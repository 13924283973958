import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
} from "@mui/material";

import { useTranslation } from "react-i18next";
import TypographyWithIcon from "@openup/shared/components/TypographyWithIcon/TypographyWithIcon";
import { pageSize as defaultPageSize } from "@src/constants";
import {
  formatFirstUpperCase,
  formatStandardDate,
} from "@src/utils/formatting";
import { ReactComponent as CalendarAlt } from "@src/resources/icons/calendar_alt.svg";
import { ReactComponent as EmptyState } from "@src/resources/images/man-reading-newspaper.svg";
import Button from "@openup/shared/components/Button/Button";
import { PaginatedResult } from "@src/models/PaginatedResult";
import { ClientSpace } from "@src/models/ClientSpace";

interface SpacesTableProps {
  spaces: PaginatedResult<ClientSpace> | undefined;
  clientName: string;
  page: number;
  pageSize: number;
  onPaginate: (page: number) => void;
}

const SpacesTable: React.FC<SpacesTableProps> = ({
  spaces,
  clientName,
  page,
  onPaginate,
  pageSize = defaultPageSize,
}) => {
  const { t } = useTranslation();

  const columns = [
    {
      id: t("Common.Date"),
      minWidth: 130,
    },

    {
      id: t("Common.Type"),
      minWidth: 125,
    },
    {
      id: t("Common.Title"),
      minWidth: 190,
    },
    {
      id: t("Common.Status"),
      minWidth: 110,
    },
  ];

  const dateCell = (space: ClientSpace) => {
    return (
      <TableCell>
        <p className="body1">
          {formatStandardDate(space.date, space.timezone)}
        </p>
      </TableCell>
    );
  };

  const spaceTitleCell = (space: ClientSpace) => {
    return (
      <TableCell>
        <span className="body1">{space.title}</span>
      </TableCell>
    );
  };

  const spaceTypeCell = (space: ClientSpace) => {
    return (
      <TableCell>
        <span className="body1">{formatFirstUpperCase(space.type)}</span>
      </TableCell>
    );
  };

  const spaceStatusCell = (space: ClientSpace) => {
    return (
      <TableCell>
        <span className="body1">
          {t(`Spaces.Status.${formatFirstUpperCase(space.status)}`)}
        </span>
      </TableCell>
    );
  };

  return (
    <Paper className="p-6">
      <Box className="flex flex-row  w-full">
        <TypographyWithIcon
          classNameText="h5 leading-6"
          icon={<CalendarAlt className="mr-2" />}
        >
          {t("Client.Spaces.Title")}
        </TypographyWithIcon>
        {!!spaces?.pagination.total && (
          <TablePagination
            component="div"
            className="ml-auto"
            count={spaces.pagination.total}
            rowsPerPage={pageSize}
            rowsPerPageOptions={[pageSize]}
            page={page}
            onPageChange={(_, newPage) => onPaginate(newPage)}
          />
        )}
      </Box>
      {!!spaces?.pagination.total && (
        <TableContainer>
          <Table data-testId="spaces">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    style={{ minWidth: column.minWidth }}
                  >
                    <p className="subtitle1">{column.id}</p>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {spaces.data.map((space: ClientSpace) => (
                <TableRow
                  data-testId="spaces-row"
                  tabIndex={-1}
                  key={space.spaceId}
                  id={space.spaceId}
                >
                  {dateCell(space)}
                  {spaceTypeCell(space)}
                  {spaceTitleCell(space)}
                  {spaceStatusCell(space)}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {!spaces?.pagination.total && (
        <div className="flex flex-col items-center bg-white py-6 md:py-8 w-full">
          <EmptyState className="my-4" />
          <p className="text-center my-1 font-semibold text-lg md:text-xl text-indigo-800">
            {t("Spaces.NotFoundTitle")}
          </p>
          <p className="text-center my-4 text-sm font-medium md:text-base text-gray-500 mx-6 md:mx-12">
            {t("Spaces.NotFoundDescription", { clientName })}
          </p>
          <Button
            onClick={() =>
              window.open("https://openup.com/spaces", "_blank", "noopener")
            }
            className="my-2"
          >
            {t("Spaces.ViewUpcomingSpaces")}
          </Button>
        </div>
      )}
    </Paper>
  );
};

export default SpacesTable;
