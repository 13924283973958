import { makeStyles } from "@mui/styles";
import { FormControl, MenuItem, Select, alpha } from "@mui/material";
import clsx from "clsx";

import { useLanguage } from "@src/contexts/LanguageContext";
import { updateUserLanguage } from "@src/queries/common";
import { AVAILABLE_LANGUAGES } from "@src/constants";
import { colors } from "@src/theme";
import { formatLanguage } from "@src/utils/formatting";
import { TypographyWithIcon } from "./utils/TypographyWithIcon";

const useStyles = makeStyles((theme) => ({
  formControl: {
    display: "flex",
    alignItems: "center",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  typographyClass: {
    "&:hover": {
      color: colors.secondaryBlue,
    },
  },
  select: {
    paddingLeft: theme.spacing(2),
    "&:hover": {
      backgroundColor: alpha(colors.primaryBlue, 0.1),
    },
    "&:focus": {
      backgroundColor: "unset",
    },
  },
  root: {
    backgroundColor: "unset",
  },
  icon: {
    color: "black",
    left: 0,
  },
}));

// eslint-disable-next-line react/prop-types
const LanguageSelector = ({ className }) => {
  const classes = useStyles();
  const { currentCulture, setCurrentCulture } = useLanguage();

  const handleChange = async (e) => {
    const newCulture = e.target.value;
    setCurrentCulture(newCulture);
    await updateUserLanguage(newCulture);
  };

  return (
    <FormControl
      variant="standard"
      className={clsx(classes.formControl, className)}
    >
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={currentCulture}
        onChange={handleChange}
        IconComponent="span"
        classes={{
          select: classes.select,
          icon: classes.icon,
        }}
        sx={{
          "&:before, &:after": {
            display: "none",
          },
        }}
      >
        {AVAILABLE_LANGUAGES.map(({ label, value }) => (
          <MenuItem key={value} value={value}>
            <TypographyWithIcon
              iconLigature="language"
              outlined
              mb={0}
              typographyClass={classes.typographyClass}
              variant="subtitle1"
            >
              {formatLanguage(label)}
            </TypographyWithIcon>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default LanguageSelector;
