import axios from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useAuthentication } from "@src/services/authentication.service";
import { apiPaths } from "@src/constants";

export const useCalendarQuery = () => {
  const queryClient = useQueryClient();
  const { isLoggedIn } = useAuthentication();

  const { data: calendarData, refetch } = useQuery(
    [apiPaths.getGoogleCalendar],
    async () => {
      const data = await axios.get(`${apiPaths.getGoogleCalendar}`).then(
        (response) => {
          const { data } = response;
          return data;
        },
        (error) => {
          if (error?.response?.status === 400) {
            return {
              notRegistered: true,
            };
          }
        },
      );
      return data;
    },
    {
      enabled: !!isLoggedIn,
    },
  );

  const { mutateAsync: registerCalendar } = useMutation(
    async (calendarId) =>
      axios.post(`${apiPaths.registerGoogleCalendar}`, {
        calendarId,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["psychologists"]);
      },
    },
  );

  return {
    refetch,
    calendarData,
    registerCalendar,
  };
};
