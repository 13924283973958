import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { alpha } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

import clsx from "clsx";
import { useClientMedicalCheckinOverviewQuery } from "@src/queries/clients";
import { isEmptyQueryResult } from "@src/queries/utils";
import { getScoreColor, colors } from "@src/theme/colors";
import { TypographyWithIcon } from "./utils/TypographyWithIcon";

import NothingFound from "./NothingFound";
import { withSuspense } from "./wrappers/Suspendable";
import FormattedDate from "./FormattedDate";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  headerText: {
    fontWeight: 500,
    fontSize: 16,
    lineHeight: "24px",
  },
  questionText: {
    opacity: 0.7,
  },
  darkBorder: {
    borderColor: alpha(colors.primaryDarkBlue, 0.5),
  },
  themeHeaderRow: {
    height: 60,
  },
  themeNameCell: {
    marginBottom: "0 !important",
  },
});

const groupBy = function (xs, key) {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

const CheckinAnswersTable = ({ clientId }) => {
  const { data } = useClientMedicalCheckinOverviewQuery(clientId);
  const { t } = useTranslation();
  const classes = useStyles();

  if (isEmptyQueryResult(data)) {
    return <NothingFound />;
  }

  const flattenedQuestionLists = data
    .map((theme) => theme.questionLists)
    .flat(1);

  const groupedQuestionLists = Object.values(
    groupBy(flattenedQuestionLists, "createdAt"),
  );

  const scoredQuestionLists = groupedQuestionLists.map((questionLists) => ({
    createdAt: questionLists[0].createdAt,
    key: questionLists[0].key,
    score: questionLists.reduce((sum, element) => sum + element.score, 0),
  }));

  return (
    <Paper>
      <TableContainer>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.darkBorder} />
              {scoredQuestionLists.map(({ createdAt, key }, index) => (
                <TableCell
                  className={clsx(classes.darkBorder, classes.headerText)}
                  align="right"
                  key={`${key}_${createdAt}_${index}`}
                >
                  <FormattedDate date={createdAt} />
                </TableCell>
              ))}
            </TableRow>
            <TableRow>
              <TableCell className={classes.darkBorder}>
                <Typography variant="subtitle2">{t("Common.Score")}</Typography>
              </TableCell>
              {scoredQuestionLists.map((questionList) => (
                <TableCell className={classes.darkBorder}>
                  <Typography
                    variant="subtitle2"
                    align="right"
                    style={{ color: getScoreColor(questionList.score) }}
                  >
                    <b>{questionList.score.toFixed(1)}</b>
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map(
              (theme) =>
                theme.questionLists.length > 0 && (
                  <>
                    <TableRow
                      key={t(`Themes.${theme.key}.Name`)}
                      hover
                      className={classes.themeHeaderRow}
                    >
                      <TableCell className={classes.darkBorder}>
                        <TypographyWithIcon
                          iconLigature="group"
                          outlined
                          variant="subtitle2"
                          boxClass={classes.themeNameCell}
                        >
                          {t(`Themes.${theme.key}.Name`)}
                        </TypographyWithIcon>
                      </TableCell>
                      {theme.questionLists.map((questionList) => (
                        <TableCell className={classes.darkBorder}>
                          <Typography
                            variant="subtitle2"
                            align="right"
                            style={{ color: getScoreColor(questionList.score) }}
                          >
                            <b>{questionList.score.toFixed(1)}</b>
                          </Typography>
                        </TableCell>
                      ))}
                    </TableRow>
                    {theme.questionLists[0].questions.map(
                      ({ key, createdAt, text, ...rest }, index) => {
                        return (
                          <TableRow key={`${key}_${createdAt}_${index}`} hover>
                            <TableCell component="th" scope="row">
                              <Typography
                                variant="body2"
                                className={classes.questionText}
                              >
                                {text}
                              </Typography>
                            </TableCell>
                            {theme.questionLists.map(
                              ({ questions }, innerIndex) => {
                                const question = questions.find(
                                  (q) => q.key === key,
                                );
                                if (!question) {
                                  return null;
                                }

                                return (
                                  <TableCell
                                    align="right"
                                    key={`${key}_${createdAt}_${innerIndex}_${question.key}`}
                                  >
                                    {!question.answers[0] ? (
                                      <Typography
                                        variant="body2"
                                        style={{ color: getScoreColor(0) }}
                                      >
                                        '-'
                                      </Typography>
                                    ) : (
                                      question.answers.map((answer) => (
                                        <Typography
                                          variant="body2"
                                          style={{
                                            color: getScoreColor(
                                              answer.intValue,
                                            ),
                                          }}
                                        >
                                          {answer.textValue}
                                        </Typography>
                                      ))
                                    )}
                                  </TableCell>
                                );
                              },
                            )}
                          </TableRow>
                        );
                      },
                    )}
                  </>
                ),
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default withSuspense(CheckinAnswersTable);
