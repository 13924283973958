export const OpenUpRoles = {
  Automation: 0,
  Guest: 1,
  Client: 2,
  Expert: 3,
  Admin: 4,
  Manager: 5,
  Consult: 6,
  Checkin: 7,
  Host: 8,
  Support: 11,
};
