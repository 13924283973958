import { useTranslation } from "react-i18next";
import { ReactComponent as LogoSVGEnglish } from "./logos/openUpLogoEnglish.svg";
import { ReactComponent as LogoSVGDutch } from "./logos/openUpLogoDutch.svg";
import { ReactComponent as LogoSVGGerman } from "./logos/openUpLogoGerman.svg";
import { ReactComponent as LogoSVGDefault } from "./logos/logotype.svg";
import {
  LanguageCodeDutchNl,
  LanguageCodeEnglishUs,
  LanguageCodeGermanDe,
  LanguageCodeFrenchFr,
} from "../utils/languageHelpers";

interface LogoProps {
  showLanguageSpecificIcon?: boolean;
}

const Logo: React.FC<LogoProps> = ({
  showLanguageSpecificIcon = false,
  ...props
}) => {
  const { i18n } = useTranslation();

  if (showLanguageSpecificIcon) {
    switch (i18n.language) {
      case LanguageCodeEnglishUs:
      case LanguageCodeFrenchFr:
        return <LogoSVGEnglish {...props} />;
      case LanguageCodeDutchNl:
        return <LogoSVGDutch {...props} />;
      case LanguageCodeGermanDe:
        return <LogoSVGGerman {...props} />;

      default:
        return <LogoSVGDefault {...props} />;
    }
  }

  return <LogoSVGDefault {...props} />;
};

export default Logo;
