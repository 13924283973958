import { useTranslation } from "react-i18next";
import { Box, Paper, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  formatClientName,
  formatSessionDateForSessionDetails,
  formatSessionType,
} from "@src/utils/formatting";
import { getConsultType } from "@src/utils/helpers";
import { IconSessionTheme } from "@src/components/Icons/IconSessionTheme";
import { IconSessionType } from "@src/components/Icons/IconSessionType";
import { IconSessionLanguage } from "@src/components/Icons/IconSessionLanguage";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import PsychologyOutlinedIcon from "@mui/icons-material/PsychologyOutlined";
import EventIcon from "@mui/icons-material/Event";
import SmsIcon from "@mui/icons-material/SmsOutlined";
import { getTranslatedTopicName } from "../../../utils/languageHelpers";

const useStyle = makeStyles((theme) => ({
  detailsPaper: {
    width: 264,
  },
  paper: {
    padding: theme.spacing(3, 2.5),
  },

  box: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(2),
    gap: theme.spacing(1.75),
  },
  flag: {
    width: 20,
    height: 20,
  },
  fixedClass: {
    position: "fixed",
    marginLeft: "80vw",
    left: theme.spacing(2),
  },
}));

const SessionDetailsPaper = ({ consult, topics, isFixed = false }) => {
  const classes = useStyle(isFixed);
  const { t } = useTranslation();

  const extraStyling = isFixed ? ` ${classes.fixedClass}` : "";

  const concatenateTopics = (sessionTopics): string => {
    let translatedTopics = "";
    sessionTopics.forEach((topic) => {
      translatedTopics += `${getTranslatedTopicName(t, topic.name)}, `;
    });
    return translatedTopics.slice(0, -2);
  };

  return (
    <Paper
      className={`${classes.detailsPaper} ${classes.paper}${extraStyling}`}
      data-cy="client-sessions-details"
    >
      <h5 className="h5 mb-2">{t("Client.Sessions.Details")}</h5>
      <Box className={classes.box}>
        <PersonOutlinedIcon />
        <Typography variant="body2">
          {formatClientName(consult.user)}
        </Typography>
      </Box>
      <Box className={classes.box}>
        <EventIcon />
        <Typography variant="body2">
          {formatSessionDateForSessionDetails(consult)}
        </Typography>
      </Box>
      <Box className={classes.box}>
        <IconSessionType consultType={getConsultType(consult)} />
        <Typography variant="body2">
          {formatSessionType(getConsultType(consult))}
        </Typography>
      </Box>
      {consult.themeKey && (
        <Box className={classes.box}>
          <IconSessionTheme theme={consult.themeKey} />
          <Typography variant="body2">
            {t(`Themes.${consult.themeKey}.Name`)}
          </Typography>
        </Box>
      )}
      {!!topics?.length && (
        <Box className={classes.box} data-cy="session-topics">
          <SmsIcon />
          <Typography variant="body2">{concatenateTopics(topics)}</Typography>
        </Box>
      )}
      <Box className={classes.box}>
        <PsychologyOutlinedIcon />
        <Typography variant="body2">{consult.employeeFullName}</Typography>
      </Box>
      <Box className={classes.box}>
        <IconSessionLanguage
          language={consult.consultLanguage}
          className={classes.flag}
        />
        <Typography variant="body2">
          {t(`SessionLanguage.${consult.consultLanguage}`)}
        </Typography>
      </Box>
    </Paper>
  );
};

export default SessionDetailsPaper;
