import {
  alpha,
  Box,
  Icon,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Badge,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import RegisterCalendarDialog from "@src/components/RegisterCalendarDialog";
import { useLoggedPsychologist } from "@src/queries/experts";
import { colors } from "@src/theme";
import {
  WithSnackbarProps,
  withSnackbar,
} from "@src/components/SnackBarComponent";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2, 3),
    borderRadius: 16,
    marginTop: theme.spacing(2),
  },
  cell: {
    padding: theme.spacing(2, 0),
  },
  cellData: {
    padding: theme.spacing(2, 0),
    opacity: 0.7,
  },
  row: {
    cursor: "pointer",
  },
  hover: {
    "&:hover": {
      backgroundColor: alpha(colors.primaryBlue, 0.1),
      borderRadius: 4,
    },
  },
  profileIcon: {
    marginRight: theme.spacing(1),
  },
  container: {
    marginBottom: theme.spacing(4),
  },
  iconCopy: {
    display: "flex",
    alignItems: "center",
    marginLeft: theme.spacing(1),
    fontSize: 32,
    borderRadius: "4px",
    backgroundColor: colors.primaryDarkBlue,
    color: "white",
    "&:hover": {
      backgroundColor: colors.primaryBlue,
    },
    "$hover:hover &": {
      opacity: 0.7,
    },
  },
}));

const ProfileView: React.FC<WithSnackbarProps> = ({ snackbarShowMessage }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const { t } = useTranslation();
  const { data: userProfile } = useLoggedPsychologist();

  const handleClickCopyLink = async (link: string) => {
    await navigator.clipboard.writeText(link);
    snackbarShowMessage(t("Profile.LinkCopiedClipboard"), "success");
  };

  return (
    <Paper className={classes.root}>
      <Box display="flex" alignItems="center" mb={4}>
        <Icon className={classes.profileIcon}>account_circle</Icon>
        <p className="h5">{t("ProfileView.Profile")}</p>
      </Box>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableBody>
            <TableRow
              hover
              tabIndex={-1}
              key="name"
              classes={{ hover: classes.hover, root: classes.row }}
            >
              <TableCell className={classes.cell}>
                <p className="sub1">{t("Common.Name")}</p>
              </TableCell>
              <TableCell className={classes.cellData}>
                <p className="sub1">
                  {`${userProfile.firstName} ${userProfile.lastName}`}
                </p>
              </TableCell>
            </TableRow>
            <TableRow
              hover
              tabIndex={-1}
              key="email"
              classes={{ hover: classes.hover, root: classes.row }}
            >
              <TableCell className={classes.cell}>
                <p className="sub1">{t("ProfileView.LoggedInWith")}</p>
              </TableCell>
              <TableCell className={classes.cellData}>
                <p className="sub1">{userProfile.email}</p>
              </TableCell>
            </TableRow>
            <TableRow
              hover
              tabIndex={-1}
              key="bookingLink"
              classes={{ hover: classes.hover, root: classes.row }}
            >
              <TableCell className={classes.cell}>
                <p className="sub1">{t("ProfileView.PersonalBookingLink")}</p>
              </TableCell>
              <TableCell className={classes.cellData}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <p className="sub1">
                    {t("ProfileView.BookingLink", {
                      key: userProfile.personalUrlHandle,
                    })}
                  </p>
                  <Tooltip
                    title={t("ProfileView.Tooltip.BookingLink")}
                    placement="top"
                  >
                    <Icon
                      className={classes.iconCopy}
                      onClick={() =>
                        handleClickCopyLink(
                          t("ProfileView.BookingLink", {
                            key: userProfile.personalUrlHandle,
                          }),
                        )
                      }
                    >
                      file_copy_outlined
                    </Icon>
                  </Tooltip>
                </Box>
              </TableCell>
            </TableRow>
            <TableRow
              hover
              tabIndex={-1}
              key="googleCalendar"
              classes={{ hover: classes.hover, root: classes.row }}
            >
              <TableCell className={classes.cell}>
                <Badge
                  badgeContent={" "}
                  invisible={userProfile.hasLinkedCalendar}
                  variant="dot"
                  color="error"
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  <p className="sub1 pr-2">{t("ProfileView.GoogleCalendar")}</p>
                </Badge>
              </TableCell>
              <TableCell className={classes.cellData}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <p className="sub1">
                    {t(
                      userProfile.hasLinkedCalendar
                        ? "ProfileView.CalendarSynced"
                        : "ProfileView.SyncCalendar",
                    )}
                  </p>
                  {!userProfile.hasLinkedCalendar && (
                    <Tooltip
                      title={t("ProfileView.Tooltip.SyncCalendar")}
                      placement="top"
                    >
                      <Icon
                        className={classes.iconCopy}
                        onClick={() => setOpen(true)}
                      >
                        sync_outlined
                      </Icon>
                    </Tooltip>
                  )}
                </Box>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <RegisterCalendarDialog open={open} onClose={() => setOpen(false)} />
    </Paper>
  );
};

export default withSnackbar(ProfileView);
