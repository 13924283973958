import CircleUnchecked from "@mui/icons-material/RadioButtonUnchecked";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { Checkbox, FormControlLabel } from "@mui/material";
import RadioButtonCheckedOutlinedIcon from "@mui/icons-material/RadioButtonCheckedOutlined";

const FormControlLabelComponent = ({
  tag,
  tagChanged,
  singleChoice,
  tagState,
  isChecked,
  setIsChecked,
}) => {
  return (
    <FormControlLabel
      multiple
      key={tag.name}
      value={tag.name}
      label={tag.description}
      onChange={(event) => {
        tagChanged(event);
        setIsChecked(!isChecked);
      }}
      control={
        <Checkbox
          icon={
            singleChoice ? <CircleUnchecked /> : <CheckBoxOutlineBlankIcon />
          }
          checkedIcon={
            singleChoice ? <RadioButtonCheckedOutlinedIcon /> : <CheckBoxIcon />
          }
          checked={tagState.indexOf(tag.name) > -1}
          color="primary"
        />
      }
    />
  );
};

export default FormControlLabelComponent;
