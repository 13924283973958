import { Icon, TableCell, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { colors } from "@src/theme";
import { formatFirstUpperCase } from "@src/utils/formatting";

const useStyles = makeStyles((theme) => ({
  cell: {
    padding: theme.spacing(2, 0),
  },
  darkCell: {
    padding: theme.spacing(2, 0),
    color: colors.primaryDarkBlue,
  },
  blueCell: {
    padding: theme.spacing(2, 0),
    color: colors.primaryBlue,
  },
  greenCell: {
    padding: theme.spacing(2, 0),
    color: colors.primaryGreen,
  },
}));

const TableCellData = ({ question, getCorrectDate, secondScore }) => {
  const classes = useStyles();

  return (
    <>
      <TableCell className={classes.cell}>
        <Typography variant="body1">
          {formatFirstUpperCase(question.key)}
        </Typography>
      </TableCell>
      <TableCell className={classes.cell}>
        <Typography
          variant="body1"
          className={
            question.score >= 0 && question.score < 3
              ? classes.darkCell
              : question.score >= 3 && question.score < 5
                ? classes.blueCell
                : classes.greenCell
          }
        >
          {Math.round(question.score * 10) / 10}
        </Typography>
      </TableCell>
      <TableCell className={classes.cell}>
        <Typography variant="body1">
          {getCorrectDate(question.createdAt)}
        </Typography>
      </TableCell>
      <TableCell className={classes.cell}>
        {!secondScore || secondScore === question.score ? (
          <Icon>
            <span className="material-icons-outlined">unfold_less</span>
          </Icon>
        ) : secondScore > question.score ? (
          <Icon>
            <span className="material-icons-outlined">keyboard_arrow_down</span>
          </Icon>
        ) : (
          <Icon>
            <span className="material-icons-outlined">keyboard_arrow_up</span>
          </Icon>
        )}
      </TableCell>
    </>
  );
};

export default TableCellData;
