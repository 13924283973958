import { useState } from "react";
import {
  alpha,
  Grid,
  Icon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { useDebouncedCallback } from "use-debounce";
import { useOpenUpEmployeeQuery } from "@src/queries/experts";
import { isEmptyQueryResult } from "@src/queries/utils";
import { colors } from "@src/theme";
import { withSuspense } from "./wrappers/Suspendable";
import NothingFound from "./NothingFound";
import SearchBox from "./SearchBox";

const columns = [
  { id: "Common.LastName", minWidth: 100 },
  { id: "Common.FirstName", minWidth: 100 },
];

const useStyles = makeStyles(({ spacing }) => ({
  container: {
    maxHeight: 400,
  },
  cell: {
    padding: spacing(2, 0),
  },
  hover: {
    "&:hover": {
      backgroundColor: `${alpha(colors.primaryBlue, 0.1)} !important`,
      borderRadius: 4,
    },
  },
  row: {
    opacity: 0.7,
    cursor: "pointer",
  },
  selectedRow: {
    opacity: 1,
    cursor: "pointer",
    color: colors.primaryDarkBlue,
  },
  cellContain: {
    "$hover:hover &": {
      color: colors.secondaryBlue,
    },
  },
  cellContainWithIcon: {
    display: "flex",
    "$hover:hover &": {
      color: colors.secondaryBlue,
    },
  },
  icon: {
    marginRight: spacing(2),
  },
}));

const PsychologistsAddEmployers = ({ onChange }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [search, setSearch] = useState("");
  const [selectedRow, setSelectedRow] = useState(null);
  const { data } = useOpenUpEmployeeQuery({ search });

  const { data: employees } = data;

  const onSearchChange = useDebouncedCallback((searchValue) => {
    setSearch(searchValue);
  }, 500);

  return (
    <Grid container>
      <Grid item xs={12}>
        <SearchBox
          onChange={onSearchChange}
          placeholder={t("MySession.List.SearchPlaceholder")}
          className={classes.searchBox}
        />
      </Grid>

      {isEmptyQueryResult(employees) ? (
        <NothingFound translationKey="Clients.List.NotFound" />
      ) : (
        <Grid item xs={12}>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      style={{ minWidth: column.minWidth }}
                      className={classes.cell}
                    >
                      <Typography variant="subtitle1">
                        {t(column.id)}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {employees.map((employee) => (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={employee.id}
                    classes={{
                      hover: classes.hover,
                      root:
                        selectedRow === employee.id
                          ? classes.selectedRow
                          : classes.row,
                    }}
                    onClick={() => {
                      onChange(employee);
                      setSelectedRow(employee.id);
                    }}
                  >
                    <TableCell className={classes.cell}>
                      <Typography
                        variant="subtitle1"
                        className={classes.cellContainWithIcon}
                      >
                        {selectedRow === employee.id && (
                          <Icon className={classes.icon}>task_alt</Icon>
                        )}
                        {employee.lastName}
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.cell}>
                      <Typography
                        variant="subtitle1"
                        className={classes.cellContain}
                      >
                        {employee.firstName}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      )}
    </Grid>
  );
};

export default withSuspense(PsychologistsAddEmployers);
