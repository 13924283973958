import PropTypes from "prop-types";
import { Box, Button, Divider, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { useMemo, useState } from "react";

import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import { useTranslation } from "react-i18next";
import { useClientQuery, useClientTagsQuery } from "@src/queries/clients";
import { useEmployersQuery } from "@src/queries/common";
import { GenderIdentifier } from "@src/constants";
import { getGenderTranslationKey } from "@src/utils/helpers";
import { withSuspense } from "./wrappers/Suspendable";

import AddOrUpdateClientDialog from "./AddOrUpdateClientDialog";
import { withSnackbar } from "./SnackBarComponent";
import { TypographyWithIcon } from "./utils/TypographyWithIcon";

const useStyles = makeStyles(({ spacing }) => ({
  wrapper: {
    padding: spacing(4, 3),
  },
  propertyValue: {
    marginBottom: spacing(2),
  },
  button: {
    width: "100%",
    marginTop: spacing(2),
  },
  emailBox: {
    display: "flex",
  },
  icon: {
    marginLeft: spacing(1),
  },
}));

const ClientDetails = ({ clientId, className, canEditClient, ...props }) => {
  const { data: client } = useClientQuery(clientId);
  const { data: tags } = useClientTagsQuery(clientId);
  const { data: employees } = useEmployersQuery();
  const classes = useStyles();
  const { t } = useTranslation();

  const employer = useMemo(() => {
    if (Array.isArray(employees) && client?.employerId) {
      return employees.find((e) => e.id === client.employerId);
    }
    return null;
  }, [employees, client]);

  const [showDialog, setShowDialog] = useState(false);

  const copyClientEmailToClipboard = () => {
    navigator.clipboard.writeText(client.email).then(() => {
      props.snackbarShowMessage(t("Common.EmailClipboard"), "success");
    });
  };

  const getClientGender = (tags) => {
    if (tags?.length === 0) {
      return t("Gender.Unknown");
    }
    const genderTag = tags.filter(
      (tag) => tag.tagType?.name === GenderIdentifier,
    );
    if (genderTag.length === 0) {
      return t("Gender.Unknown");
    }
    return t(getGenderTranslationKey(genderTag[0].name));
  };

  return (
    <Paper
      className={clsx(classes.wrapper, className)}
      elevation={0}
      data-cy="client-details-section"
    >
      <TypographyWithIcon iconLigature="person" outlined variant="h5">
        {t("Common.Client")} OU-{client.userNumber}
      </TypographyWithIcon>

      <PropertyName>{t("Common.FirstName")}</PropertyName>
      <PropertyValue>{client.firstName}</PropertyValue>

      <PropertyName>{t("Common.LastName")}</PropertyName>
      <PropertyValue>{client.lastName}</PropertyValue>

      <PropertyName>{t("Common.Email")}</PropertyName>
      <Box className={classes.emailBox}>
        <PropertyValue className={classes.value}>{client.email}</PropertyValue>
        <FileCopyOutlinedIcon
          className={classes.icon}
          onClick={copyClientEmailToClipboard}
        />
      </Box>

      <PropertyName>{t("Common.PhoneNumber")}</PropertyName>
      <PropertyValue>{client.phoneNumber}</PropertyValue>

      <PropertyName>{t("Common.YearOfBirth")}</PropertyName>
      <PropertyValue>{client.yearOfBirth}</PropertyValue>

      <PropertyName>{t("Common.Language")}</PropertyName>
      <PropertyValue>{client.languageCode}</PropertyValue>

      <PropertyName>{t("Common.Gender")}</PropertyName>
      <PropertyValue>{getClientGender(tags)}</PropertyValue>

      {employer && (
        <>
          <PropertyName>{t("Common.Employer")}</PropertyName>
          <PropertyValue>{employer.name}</PropertyValue>

          <PropertyName>{t("Common.FamilyMember")}</PropertyName>
          <PropertyValue>
            {client.isPartner ? t("Common.Yes") : t("Common.No")}
          </PropertyValue>
        </>
      )}

      {canEditClient && (
        <>
          {" "}
          <Divider />
          <Button
            variant="contained"
            onClick={() => setShowDialog(true)}
            className={classes.button}
            data-cy="edit-client-button"
          >
            {t("Common.Edit")}
          </Button>
        </>
      )}
      {client && (
        <AddOrUpdateClientDialog
          isEdit
          open={showDialog}
          onClose={() => setShowDialog(false)}
          client={client}
          clientTags={tags}
        />
      )}
    </Paper>
  );
};

const PropertyName = ({ children }) => (
  <Typography variant="subtitle2">{children}</Typography>
);
const PropertyValue = ({ children }) => {
  const classes = useStyles();
  return (
    <Typography variant="body2" className={classes.propertyValue}>
      {children}
    </Typography>
  );
};

ClientDetails.propTypes = {
  clientId: PropTypes.string.isRequired,
  className: PropTypes.string,
  canEditClient: PropTypes.bool.isRequired,
};

export default withSnackbar(
  withSuspense(ClientDetails, {
    nothingFoundTranslationKey: "Client.Details.NotFound",
  }),
);
