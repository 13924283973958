import axios from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Consult } from "@src/models/Consult";
import { queryIdentifiers } from "@src/utils/constants";
import { ConsultWithShortNotesResponse } from "@src/models/Consults/ConsultWithShortNotesResponse";
import { apiPaths } from "@src/constants";

const identifier = "consults";

export const getConsultById = async (clientId: string, consultId: number) => {
  const { data } = await axios.get(
    `${apiPaths.clients}/${clientId}/${identifier}/session-notes/${consultId}`,
  );
  return data;
};

export const useClientConsultsQuery = (clientId: string | undefined) =>
  useQuery(["client-consults", clientId], () =>
    axios
      .get<
        ConsultWithShortNotesResponse[]
      >(`${apiPaths.clients}/${clientId}/${identifier}`)
      .then(({ data }) => data),
  );

export const useConsultQuery = (clientId: string, consultId: number) =>
  useQuery(
    ["client-consults", clientId, consultId],
    async () => getConsultById(clientId, consultId),
    { enabled: !!(clientId && consultId) },
  );

export const useGetClosestClientConsult = (clientId: string) =>
  useQuery(
    ["client-consults", clientId, "closest-consult"],
    async () =>
      axios
        .get<Consult>(
          `${apiPaths.clients}/${clientId}/${identifier}/closest-consult`,
        )
        .then((result) => result.data)
        .catch((error) => (error.response.status === 404 ? undefined : null)),
    { enabled: !!clientId },
  );

export const useConsultNotesMutation = (
  clientId: string,
  consultId: number,
) => {
  const queryClient = useQueryClient();
  return useMutation(
    (value: any) => {
      return axios.post(
        `${apiPaths.clients}/${clientId}/${queryIdentifiers.dossierNote}/${consultId}`,
        value.data,
      );
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries(["client-consults", clientId]);
        queryClient.invalidateQueries(["client-consults", clientId, consultId]);
      },
    },
  );
};

export const useConsultMutation = (clientId: string) => {
  const queryClient = useQueryClient();
  const onSettled = () => {
    queryClient.invalidateQueries(["client-consults", clientId]);
    queryClient.invalidateQueries([queryIdentifiers.psychologistManagement]);
    queryClient.invalidateQueries([queryIdentifiers.consults]);
  };

  const createOrUpdate = useMutation(
    (consult: Consult) =>
      consult.id
        ? axios.put(
            `${apiPaths.clientConsults}/${clientId}/${identifier}/${consult.id}`,
            consult,
          )
        : axios.post(
            `${apiPaths.clientConsults}/${clientId}/${identifier}`,
            consult,
          ),
    {
      onSettled,
    },
  );

  const updateConsult = useMutation(
    (consult: any) =>
      axios.put(
        `${apiPaths.clients}/${clientId}/${identifier}/${consult.id}`,
        consult,
      ),
    {
      onSettled,
    },
  );
  const updateConsultStatus = useMutation(
    (consult: Consult) =>
      axios.patch<Consult>(
        `${apiPaths.consults}/${consult.id}/status/${consult.status}`,
      ),
    {
      onSettled,
    },
  );

  const createConsult = useMutation(
    (consult: any) =>
      axios.post(`${apiPaths.clients}/${clientId}/${identifier}`, consult),
    {
      onSettled,
    },
  );

  const deleteConsult = useMutation(
    (consultId: number) =>
      axios.delete(
        `${apiPaths.clients}/${clientId}/${identifier}/${consultId}`,
      ),
    {
      onSettled,
    },
  );

  return {
    createOrUpdate,
    updateConsult,
    createConsult,
    deleteConsult,
    updateConsultStatus,
  };
};
