import { GENDER, SESSION_TYPE, STATUS } from "@src/constants";
import moment from "moment";

export const getConsultType = (consult) => {
  if (!consult) return undefined;

  return consult?.consultType || SESSION_TYPE.GENERAL;
};

export const createUpdateNotesObject = (formikNotesValues) => [
  {
    Question: "reasonBook",
    Answer: formikNotesValues.reasonForBooking,
  },
  {
    Question: "sessionDiscussed",
    Answer: formikNotesValues.discussedInSession,
  },
  {
    Question: "helpNeeded",
    Answer: formikNotesValues.canWeHelp,
  },
  {
    Question: "provider",
    Answer: formikNotesValues.referredToDifferentHealthProvider,
  },
  {
    Question: "providerName",
    Answer: formikNotesValues.healthProviderName,
  },
  {
    Question: "nextSteps",
    Answer: formikNotesValues.nextSteps,
  },
];

export const createUpdateNotesRequestObject = (formikNotesValues) => ({
  data: {
    Notes: createUpdateNotesObject(formikNotesValues),
    themeKey: formikNotesValues.themeKey,
  },
});

/**
 * Checking if [currentConsult] is an introductory session or a follow up session
 * If there are 0 sessions before current session with status = completed, then we see this current session as as *introductory session*.
 * If there are N (N >= 1) before current session with status = completed, then we see this current session as a *follow-up session*.
 * @param {Array<Consult>} allConsults - All consults of the client
 * @param {Consult} currentConsult - Current consult (being checked)
 * @return {bool} true if this is the first completed session, false otherwise
 */
export const isIntroductorySession = (allConsults, currentConsult) => {
  const previousSessionsWithPsychologistOfThisSession = allConsults
    .filter(
      (consult) =>
        consult.status.toLowerCase() === STATUS.COMPLETED.toLowerCase(),
    )
    .filter((consult) => {
      return (
        moment(consult.startedAt) <
        (currentConsult.startedAt ? moment(currentConsult.startedAt) : moment())
      );
    });
  return previousSessionsWithPsychologistOfThisSession.length === 0;
};

export const sessionHasNotes = (consult) => {
  if (!consult) return false;
  if (!consult.notes && consult.dossierNotes.length === 0) return false;
  return true;
};

export const isInternallyScheduled = (consult) => !!consult.psychologistUrl;

export const stringToBoolean = (value) =>
  String(value).toLowerCase() === "true";

export const getGenderTranslationKey = (gender) => {
  switch (gender) {
    case GENDER.FEMALE:
      return "Gender.Female";
    case GENDER.MALE:
      return "Gender.Male";
    case GENDER.UNKNOWN:
    default:
      return "Gender.Unknown";
  }
};
