import Box from "@mui/material/Box";
import MaterialDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import { alpha, Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import { Divider } from "@mui/material";
import clsx from "clsx";
import OpenUp from "@src/components/logos/OpenUp";
import authenticationService from "@src/services/authentication.service";
import { OpenUpRoles } from "@src/services/OpenUpRoles";
import { colors } from "@src/theme";
import { useMemo } from "react";

const drawerWidth = 240;
type StyleProps = {
  backgroundColor: string;
  textColor: string;
  textActiveColor: string;
  backgroundColorActiveNavigation: string;
  iconColor: string;
};
const useStyles = makeStyles<Theme, StyleProps>(({ spacing }) =>
  createStyles({
    drawer: {
      minWidth: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: ({ backgroundColor = colors.secondarySkin }) => ({
      backgroundColor,
      minWidth: drawerWidth,
      padding: spacing(0, 2),
      borderRight: "none",
      position: "inherit",
    }),
    drawerContainer: {
      width: "100%",
      overflow: "auto",
    },
    medicalDataContainer: {
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
    },
    medicalDataContainerSpacer: {
      display: "flex",
      flexGrow: 1,
    },
    medicalDataMessage: {
      width: 200,
      textAlign: "center",
      flexGrow: 0,
      marginBottom: 20,
      padding: 5,
      fontSize: 14,
      lineHeight: 1.75,
    },
    navigationEntry: {
      color: "white",
      margin: spacing(1, 0),
      borderRadius: "4px",
    },
    navigationEntryActive: ({
      backgroundColorActiveNavigation = colors.primaryBlue,
    }) => ({
      backgroundColor: alpha(backgroundColorActiveNavigation, 0.1),
    }),
    navigationText: ({ textColor = colors.primaryDarkBlue }) => ({
      color: textColor,
      fontSize: 16,
      lineHeight: "24px",
      fontWeight: "bold",
      opacity: 1,
    }),
    navigationTextActive: ({ textActiveColor = colors.primaryBlue }) => ({
      color: textActiveColor,
    }),
    divider: {
      margin: spacing(0, 1),
      backgroundColor: alpha(colors.primaryDarkBlue, 0.2),
    },
    openUpLogo: {
      marginTop: spacing(3),
      marginLeft: spacing(1),
      height: 24,
    },
    menu: {
      marginTop: spacing(2),
    },
  }),
);

const drawerBackgroundColors = {
  "/": {
    backgroundColor: colors.secondaryGreen,
    textColor: "#A7D6CE",
    textActiveColor: "white",
    backgroundColorActiveNavigation: "#FFFFFF",
    iconColor: "#FFFFFF",
  },
  "/profile": {
    backgroundColor: colors.secondaryGreen,
    textColor: "#A7D6CE",
    textActiveColor: "white",
    backgroundColorActiveNavigation: "#FFFFFF",
    iconColor: "#FFFFFF",
  },
};

const possibleDrawerItems = [
  {
    rolesWithAccess: [
      OpenUpRoles.Expert,
      OpenUpRoles.Host,
      OpenUpRoles.Admin,
      OpenUpRoles.Manager,
      OpenUpRoles.Support,
    ],
    value: {
      i18nKey: "Navigation.Dashboard",
      href: "/",
    },
  },
  {
    rolesWithAccess: [OpenUpRoles.Expert],
    value: {
      i18nKey: "Navigation.Clients",
      href: "/clients",
    },
  },
  {
    rolesWithAccess: [OpenUpRoles.Expert, OpenUpRoles.Manager],
    value: {
      i18nKey: "Navigation.MySessions",
      href: "/my-sessions",
    },
  },
  {
    rolesWithAccess: [
      OpenUpRoles.Host,
      OpenUpRoles.Admin,
      OpenUpRoles.Manager,
      OpenUpRoles.Support,
      OpenUpRoles.Expert,
    ],
    value: {
      i18nKey: "Navigation.Psychologists",
      href: "/psychologists",
    },
  },
  {
    rolesWithAccess: [
      OpenUpRoles.Host,
      OpenUpRoles.Admin,
      OpenUpRoles.Manager,
      OpenUpRoles.Support,
    ],
    value: {
      i18nKey: "Navigation.AllSessions",
      href: "/all-sessions",
    },
  },
  {
    rolesWithAccess: [
      OpenUpRoles.Host,
      OpenUpRoles.Admin,
      OpenUpRoles.Manager,
      OpenUpRoles.Support,
    ],
    value: {
      i18nKey: "Navigation.AllClients",
      href: "/all-clients",
    },
  },
];

const Drawer = () => {
  const { pathname } = useLocation();
  const colors: StyleProps = drawerBackgroundColors[pathname] || {};
  const classes = useStyles({ ...colors });
  const { t } = useTranslation();
  const navigate = useNavigate();

  const drawerItems = useMemo(() => {
    return possibleDrawerItems
      .filter((newItem) =>
        authenticationService.hasRole(...newItem.rolesWithAccess),
      )
      .map((drawerItem) => drawerItem.value);
  }, []);

  return (
    <MaterialDrawer
      className={classes.drawer}
      variant="permanent"
      elevation={0}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.drawerContainer}>
        <OpenUp className={classes.openUpLogo} />
        <List className={classes.menu}>
          {drawerItems.map(({ i18nKey, href }) => {
            const isActiveLocation = pathname === href;
            return (
              <div key={i18nKey}>
                <ListItem
                  button
                  onClick={() => navigate(href)}
                  className={clsx(
                    classes.navigationEntry,
                    isActiveLocation && classes.navigationEntryActive,
                  )}
                >
                  <ListItemText
                    primary={t(i18nKey)}
                    primaryTypographyProps={{
                      className: clsx(
                        classes.navigationText,
                        isActiveLocation && classes.navigationTextActive,
                      ),
                    }}
                  />
                </ListItem>
                <Divider className={classes.divider} />
              </div>
            );
          })}
        </List>
      </div>
      <div className={classes.medicalDataContainer}>
        <div className={classes.medicalDataContainerSpacer}>{"\u00A0"}</div>
        <Paper className={classes.medicalDataMessage}>
          <Box>{t("Navigation.MedicalData")}</Box>
        </Paper>
      </div>
    </MaterialDrawer>
  );
};

export default Drawer;
