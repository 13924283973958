import axios, { AxiosError } from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { apiPaths, RESPONSE_STATUS } from "@src/constants";
import { useAuthentication } from "@src/services/authentication.service";
import { queryIdentifiers } from "../utils/constants";
import { fiveMinutes } from "./staletimes";

export const useClientsQuery = (queryParams = {}) => {
  const { isLoggedIn } = useAuthentication();

  return useQuery(
    [queryIdentifiers.clients, queryParams],
    async ({ queryKey: [url, params] }) => {
      const { data } = await axios.get(apiPaths.clients, {
        params,
      });
      return data;
    },
    {
      enabled: isLoggedIn,
      staleTime: fiveMinutes,
    },
  );
};
export const useMyClientsQuery = (queryParams = {}) => {
  const { isLoggedIn } = useAuthentication();

  return useQuery(
    [queryIdentifiers.myClients, queryParams],
    async ({ queryKey: [url, params] }) => {
      const { data } = await axios.get(apiPaths.myClients, {
        params,
      });
      return data;
    },
    {
      enabled: isLoggedIn,
      staleTime: fiveMinutes,
    },
  );
};

export const useClientQuery = (clientId) => {
  const { isLoggedIn } = useAuthentication();
  return useQuery(
    [queryIdentifiers.clients, clientId],
    async () => {
      const { data } = await axios.get(`${apiPaths.clients}/${clientId}`);

      return data;
    },
    {
      staleTime: fiveMinutes,
      enabled: isLoggedIn,
    },
  );
};

export const useClientTagsQuery = (clientId) => {
  const { isLoggedIn } = useAuthentication();
  return useQuery(
    [queryIdentifiers.clients, clientId, "tags"],
    async () => {
      const { data } = await axios.get(
        `${apiPaths.clients}/${clientId}/${queryIdentifiers.tags}`,
      );
      return data;
    },
    {
      staleTime: fiveMinutes,
      enabled: isLoggedIn,
    },
  );
};

export const useClientEmotionsOverviewQuery = (userId, questionListName) =>
  useQuery(
    [
      queryIdentifiers.clients,
      userId,
      queryIdentifiers.questionListAnswers,
      questionListName,
    ],
    async () => {
      const { data } = await axios.get(
        `${apiPaths.clients}/${userId}/${queryIdentifiers.questionListAnswers}/${questionListName}`,
      );
      return data;
    },
    {
      staleTime: fiveMinutes,
    },
  );

export const useClientCheckinOverviewQuery = (userId) =>
  useQuery(
    [queryIdentifiers.clients, userId, queryIdentifiers.themes],
    async () => {
      const { data } = await axios.get(
        `${apiPaths.clients}/${userId}/${queryIdentifiers.themes}/`,
      );
      return data;
    },
    {
      staleTime: fiveMinutes,
    },
  );

export const useClientMedicalCheckinOverviewQuery = (userId) => {
  const queryClient = useQueryClient();
  const key = [apiPaths.clients, userId, "medical-themes"];
  return useQuery<unknown, AxiosError>(
    key,
    async () => {
      const { data } = await axios.get(
        `${apiPaths.clients}/${userId}/medical-themes/`,
      );
      return data;
    },
    {
      onError: (error) => {
        if (error.response?.status === RESPONSE_STATUS.NOT_FOUND) {
          queryClient.setQueryData(key, []);
        }
      },
    },
  );
};

export const useClientThemeAnswersQuery = (userId, formName) =>
  useQuery(
    [queryIdentifiers.clients, userId, queryIdentifiers.themes, formName],
    async () => {
      const { data } = await axios.get(
        `${apiPaths.clients}/${userId}/${queryIdentifiers.themes}/${formName}`,
      );
      return data;
    },
    {
      staleTime: fiveMinutes,
    },
  );

export const useClientMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (user: any) =>
      user.id
        ? axios.put(`${apiPaths.clients}/${user.id}`, user)
        : axios.post(apiPaths.clients, user),
    {
      onSuccess: ({ data }) => {
        queryClient.setQueryData([queryIdentifiers.clients, data.id], data);
        queryClient.invalidateQueries([queryIdentifiers.clients]);
        queryClient.invalidateQueries([queryIdentifiers.themes]);
      },
    },
  );
};

export const useClientAnonymizeMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (clientId: string) =>
      axios.delete(`${apiPaths.clients}/${clientId}/anonymize`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([queryIdentifiers.clients]);
      },
    },
  );
};

export const useClientDossierNoteQuery = (clientId) =>
  useQuery(
    [queryIdentifiers.clients, clientId, queryIdentifiers.dossierNote],
    async () => {
      const { data } = await axios.get(
        `/${apiPaths.clients}/${clientId}/${queryIdentifiers.dossierNote}`,
      );
      return data;
    },
    {
      staleTime: fiveMinutes,
    },
  );

export const useClientDossierNoteMutation = (clientId) => {
  const queryClient = useQueryClient();

  return useMutation(
    (value) =>
      axios.put(
        `${apiPaths.clients}/${clientId}/${queryIdentifiers.dossierNote}`,
        {
          value,
        },
      ),
    {
      onSuccess: ({ data }) => {
        queryClient.setQueryData(
          [apiPaths.clients, clientId, queryIdentifiers.dossierNote],
          data,
        );
      },
    },
  );
};

export const useClientUnlockMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((id) => axios.put(`${apiPaths.clients}/${id}/unlock`), {
    onSuccess: () => {
      queryClient.invalidateQueries([queryIdentifiers.clients]);
      queryClient.invalidateQueries([queryIdentifiers.psychologists]);
    },
  });
};
