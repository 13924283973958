import { Container, Link, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { colors } from "@src/theme";

const useStyles = makeStyles((theme) => ({
  footerBar: {
    width: "100%",
    display: "flex",
    background: colors.primaryDarkBlue,
    [theme.breakpoints.up("md")]: {
      height: "80px",
      alignItems: "center",
    },
  },
  container: {
    display: "flex",
    height: "100%",
    paddingBottom: 0,
    color: "white",

    [theme.breakpoints.up("md")]: {
      alignItems: "center",
      justifyContent: "space-between",
    },
    [theme.breakpoints.down("md")]: {
      height: "240px",
      flexDirection: "column",
      flexWrap: "wrap",
      justifyContent: "space-evenly",
    },
  },
  links: {
    display: "flex",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      "& > :not(:last-child)": {
        marginBottom: theme.spacing(2.5),
      },
    },
    [theme.breakpoints.up("md")]: {
      "& > :not(:last-child)": {
        marginRight: theme.spacing(2.5),
      },
    },
  },
  icons: {
    display: "flex",
    flexDirection: "row",
    "& > :not(:last-child)": {
      marginRight: theme.spacing(1.5),
    },
    [theme.breakpoints.down("md")]: {
      flexBasis: "100%",
      marginTop: theme.spacing(2),
      justifyContent: "flex-end",
    },
  },
  commonFontProperties: {
    fontWeight: "500",
    color: "white",
  },
  commonTextProperties: {
    textDecorationLine: "underline",
    opacity: "0.7",
  },
  socialLink: {
    borderRadius: "50%",
    width: "40px",
    height: "40px",
    border: `1px solid white`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "white",
  },
}));

const FooterBar = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div className={classes.footerBar}>
      <Container className={classes.container}>
        <Typography variant="body2" className={classes.commonFontProperties}>
          {t("Footbar.Text.Copyright")}
        </Typography>
        <div className={classes.links}>
          <Link
            href={t("Footer.Text.Link.OpenUp")}
            target="_blank"
            variant="body2"
            className={clsx(
              classes.commonFontProperties,
              classes.commonTextProperties,
            )}
            underline="hover"
          >
            {t("Footbar.Text.OpenUp")}
          </Link>
          <Link
            href={t("Footer.Text.Link.Privacy.myOpenUp")}
            target="_blank"
            variant="body2"
            className={clsx(
              classes.commonFontProperties,
              classes.commonTextProperties,
            )}
            underline="hover"
          >
            {t("Footer.Text.Privacy.myOpenUp")}
          </Link>
        </div>

        <div className={classes.icons}>
          <a
            href={t("Footer.Icons.Linkedin")}
            target="_blank"
            className={classes.socialLink}
            rel="noreferrer"
          >
            <LinkedInIcon />
          </a>
          <a
            href={t("Footer.Icons.Instagram")}
            target="_blank"
            className={classes.socialLink}
            rel="noreferrer"
          >
            <InstagramIcon />
          </a>
        </div>
      </Container>
    </div>
  );
};

export default FooterBar;
