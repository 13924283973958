import {
  Button,
  Dialog,
  Divider,
  Paper,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useClientDossierNoteMutation,
  useClientDossierNoteQuery,
} from "@src/queries/clients";
import { TypographyWithIcon } from "./utils/TypographyWithIcon";
import { withSuspense } from "./wrappers/Suspendable";
import { DialogActionButtons, DialogHeader } from "./DialogToolkit";

const useStyles = makeStyles(({ spacing }) => ({
  wrapper: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  textArea: {
    width: "100%",
    flexGrow: 1,
    marginBottom: spacing(2),
  },
  button: {
    width: "100%",
  },
  divider: {
    margin: spacing(2, 0),
  },
  dividerFullWidth: {
    marginLeft: spacing(-3),
    marginRight: spacing(-3),
  },
}));

const ClientDossierNote = ({ clientId }) => {
  const { data: dossierNote } = useClientDossierNoteQuery(clientId);
  const { mutateAsync: updateDossierNote } =
    useClientDossierNoteMutation(clientId);
  const { t } = useTranslation();
  const classes = useStyles();
  const [value, setValue] = useState(dossierNote.value || "");
  const [showDialog, setShowDialog] = useState(false);

  return (
    <>
      <Paper className={classes.wrapper} elevation={0} sx={{ padding: "24px" }}>
        <TypographyWithIcon iconLigature="library_books" outlined>
          {t("Dossier.DossierNote")}
        </TypographyWithIcon>
        <Typography variant="body2">{dossierNote.value}</Typography>
        <Divider className={classes.divider} />
        <Button
          className={classes.button}
          color="secondary"
          variant="contained"
          onClick={() => setShowDialog(true)}
        >
          {t("Dossier.DossierNoteEdit")}
        </Button>
      </Paper>
      <Dialog
        open={showDialog}
        onClose={() => {
          setValue(dossierNote.value || "");
          setShowDialog(false);
        }}
      >
        <DialogHeader
          iconLigature="description"
          title={t("Dossier.DossierNoteEdit")}
        />

        <TextareaAutosize
          value={value}
          className={classes.textArea}
          onChange={(e) => setValue(e.target.value)}
        />
        <DialogActionButtons
          onSubmit={() => {
            updateDossierNote(value);
            setShowDialog(false);
          }}
          onCancel={() => {
            setValue(dossierNote.value || "");
            setShowDialog(false);
          }}
        />
      </Dialog>
    </>
  );
};

export default withSuspense(ClientDossierNote);
