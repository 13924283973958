import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Icon,
  TablePagination,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import TypographyWithIcon from "@openup/shared/components/TypographyWithIcon/TypographyWithIcon";
import { smallTablePageSize, STATUS } from "@src/constants";
import {
  formatFirstUpperCase,
  formatSessionType,
  formatStandardDate,
} from "@src/utils/formatting";
import { getConsultType } from "@src/utils/helpers";
import { ConsultWithShortNotesResponse } from "@src/models/Consults/ConsultWithShortNotesResponse";
import EditSessionDetailsDialog from "./SessionNotes/EditSessionDetailsDialog";

interface PastSessionsTableProps {
  clientId: string;
  consults: Array<ConsultWithShortNotesResponse>;
  canAddSession?: boolean;
  viewNotesColumn?: boolean;
}

const PastSessionsTable: React.FC<PastSessionsTableProps> = ({
  clientId,
  consults,
  canAddSession = true,
  viewNotesColumn = true,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [isAddingSession, setIsAddingSession] = useState(false);
  const [page, setPage] = useState(0);

  const columns = [
    {
      id: "When",
      minWidth: 130,
    },
    {
      id: t("Consults.Psychologist"),
      minWidth: 190,
    },
    {
      id: t("Common.Type"),
      minWidth: 120,
    },
    {
      id: t("Common.Status"),
      minWidth: 200,
    },
  ];
  if (viewNotesColumn) {
    columns.push({
      id: "Notes",
      minWidth: 120,
    });
  }
  const dateCell = (consult) => {
    return (
      <TableCell>
        <p className="body1">
          {formatStandardDate(consult.startedAt, "Europe/Amsterdam")}
        </p>
      </TableCell>
    );
  };

  const psychologistCell = (consult) => {
    return (
      <TableCell>
        <p className="body1">{consult.employeeFullName}</p>
      </TableCell>
    );
  };

  const sessionTypeCell = (consult) => {
    return (
      <TableCell>
        <p className="body1">{formatSessionType(getConsultType(consult))}</p>
      </TableCell>
    );
  };

  const sessionStatusCell = (consult) => {
    return (
      <TableCell>
        <p className="body1">{formatFirstUpperCase(consult.status)}</p>
      </TableCell>
    );
  };

  const navigateToNotesPage = (consult) => {
    if (!viewNotesColumn) return;
    if (
      !(
        consult.status === STATUS.COMPLETED ||
        consult.status === STATUS.SCHEDULED
      )
    )
      return;
    navigate(`/clients/${consult.userId}/session-notes/${consult.id}`);
  };

  const notesCell = (consult) => {
    if (
      !(
        consult.status === STATUS.COMPLETED ||
        consult.status === STATUS.SCHEDULED
      )
    ) {
      return (
        <TableCell>
          <p className="body1">{t("Client.Notes.NotRequired")}</p>
        </TableCell>
      );
    }

    if (!consult.shortDiscussedNotes?.length) {
      return (
        <TableCell>
          <Button
            className="bg-green-500 rounded-md space-x-2 h-12 text-white"
            onClick={() => navigateToNotesPage(consult)}
            data-cy="add-notes"
          >
            <Icon>description</Icon>
            <p className="subtitle2 text-white">
              {t("Client.Sessions.AddNotes")}
            </p>
          </Button>
        </TableCell>
      );
    }

    return (
      <TableCell>
        <Box alignItems="center" className="flex flex-row justify-between mb-2">
          <p data-cy="notes-short" className="sub1" />
          {consult.shortDiscussedNotes}
          <Icon className="mr-2 text-base leading-4">arrow_forward_ios</Icon>
        </Box>
      </TableCell>
    );
  };

  return (
    <Paper className="p-6 mb-8">
      <Box className="flex flex-row  w-full">
        <TypographyWithIcon
          classNameText="h5 leading-6"
          icon={<Icon className="mr-2">event_available_outlined</Icon>}
        >
          {t("Client.Sessions.Past")}
        </TypographyWithIcon>

        {canAddSession && (
          <Button
            onClick={() => setIsAddingSession(true)}
            data-cy="add-session"
            className="ml-auto"
          >
            <TypographyWithIcon
              className="underline mb-0 h5 text-base"
              icon={<Icon className="mr-1">note_add_outlined</Icon>}
            >
              {t("Client.Sessions.AddSession")}
            </TypographyWithIcon>
          </Button>
        )}

        <TablePagination
          component="div"
          count={consults.length}
          rowsPerPage={smallTablePageSize}
          rowsPerPageOptions={[smallTablePageSize]}
          page={page}
          onPageChange={(_, newPage) => setPage(newPage)}
        />
      </Box>
      <TableContainer>
        <Table data-cy="past-sessions">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  style={{ minWidth: column.minWidth }}
                >
                  <p className="subtitle1">{column.id}</p>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {consults
              .slice(page * smallTablePageSize, (page + 1) * smallTablePageSize)
              .map((consult) => (
                <TableRow
                  hover
                  role="checkbox"
                  data-cy="consult-row"
                  tabIndex={-1}
                  key={consult.id}
                  id={consult.id}
                  onClick={() => navigateToNotesPage(consult)}
                >
                  {dateCell(consult)}
                  {psychologistCell(consult)}
                  {sessionTypeCell(consult)}
                  {sessionStatusCell(consult)}
                  {viewNotesColumn && notesCell(consult)}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* Dialogs are rendered even if the isOpen flag is false, the redundancy here is desired */}
      {canAddSession && isAddingSession && (
        <EditSessionDetailsDialog
          isOpen={isAddingSession}
          clientId={clientId}
          consult={null}
          onClose={() => setIsAddingSession(false)}
        />
      )}
    </Paper>
  );
};

export default PastSessionsTable;
