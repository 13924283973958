import { Box, List, ListItem, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import FiberManualRecord from "@mui/icons-material/FiberManualRecord";

const useStyles = makeStyles(({ colors, spacing }) => ({
  bullet: {
    fontSize: 6,
    marginRight: spacing(1),
    marginTop: spacing(0.5),
    alignSelf: "flex-start",
  },
  listItem: {
    padding: spacing(0),
  },
  informativeBox: {
    backgroundColor: colors.primarySkin,
    padding: spacing(1.5),
    margin: spacing(1, 2, 3),
  },
}));

const AdviceTextList = ({ text }) => {
  const classes = useStyles();

  if (!text) {
    return null;
  }
  return (
    <ListItem disableGutters className={classes.listItem}>
      <FiberManualRecord className={classes.bullet} />
      <Typography variant="caption">{text}</Typography>
    </ListItem>
  );
};

const AdviceBox = ({ texts = [] }) => {
  const classes = useStyles();

  // if all strings within AdviceBox are empty strings (or nulls/undefined) then we do not render the box
  if (!texts || !texts.some(Boolean)) {
    return null;
  }

  return (
    <Box className={classes.informativeBox} mb={3}>
      <List disablePadding>
        {texts.map((text) => (
          <AdviceTextList text={text} />
        ))}
      </List>
    </Box>
  );
};

export default AdviceBox;
