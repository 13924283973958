import { Suspense } from "react";
import ErrorBoundary from "./ErrorBoundary";
import Loader from "../Loader";

const Suspendable = ({ children, nothingFoundTranslationKey }) => (
  <ErrorBoundary nothingFoundTranslationKey={nothingFoundTranslationKey}>
    <Suspense fallback={<Loader />}>{children}</Suspense>
  </ErrorBoundary>
);

export const withSuspense =
  (WrappedComponent, { nothingFoundTranslationKey } = {}) =>
  (props) => (
    <Suspendable nothingFoundTranslationKey={nothingFoundTranslationKey}>
      <WrappedComponent {...props} />
    </Suspendable>
  );

export default Suspendable;
