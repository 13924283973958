import { createRef, useState } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";

import { makeStyles } from "@mui/styles";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import {
  AuthenticatorTwoFactorCodeRequestMethod,
  backspaceKey,
  enterKey,
} from "@src/constants";
import authenticationService, {
  useAuthentication,
} from "@src/services/authentication.service";
import { OpenUpRoles } from "@src/services/OpenUpRoles";
import { colors } from "@src/theme";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    justifyContent: "center",
    gap: theme.spacing(2),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  button: {
    padding: theme.spacing(1.5, 6),
    backgroundColor: colors.primaryBlue,
    width: 360,
    borderRadius: 28,
    marginTop: theme.spacing(3),
    "&:hover": {
      backgroundColor: colors.primaryBlue,
    },
  },
  textButton: {
    color: "white",
    letterSpacing: "1.25px",
  },
  error: {
    color: colors.red,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  inputClass: {
    textAlign: "center",
  },
}));

const CodeInput = ({ codeLength, moveToNextStep }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [inputCode, setInputCode] = useState("");
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const { setLoggedIn, hasRole } = useAuthentication();

  const onInput = (_inputCode) => {
    setInputCode(_inputCode);
  };
  const onFocus = () => {
    setError(false);
  };

  const inputElements = [];
  const inputRefList = [];

  const handleInput = (event, index) => {
    onInput(inputRefList.map((ref) => ref.current.value).join(""));
    if (event.nativeEvent.data === null) return;
    moveToNextInputField(index);
  };

  const handleKeyDown = (event, index) => {
    if (event.keyCode === backspaceKey && !event.target.value)
      moveToPreviousInputField(index);
    onInput(inputRefList.map((ref) => ref.current.value).join(""));
    if (event.keyCode === enterKey) onSubmit();
  };

  const handleOnFocus = () => {
    onFocus();
  };

  const moveToNextInputField = (currentInputIndex) => {
    if (currentInputIndex === codeLength - 1) return;
    inputRefList[currentInputIndex + 1].current.focus();
  };

  const moveToPreviousInputField = (currentInputIndex) => {
    if (currentInputIndex === 0) return;
    inputRefList[currentInputIndex - 1].current.focus();
  };

  const onPaste = (e) => {
    e.nativeEvent.preventDefault();
    const codeFromClipboard = e.clipboardData.getData("Text");
    codeFromClipboard.split("").forEach((char, index) => {
      if (!inputRefList[index]) return;
      inputRefList[index].current.value = char;
    });

    const focusIndex =
      codeFromClipboard.length < codeLength
        ? codeFromClipboard.length
        : codeLength - 1;
    inputRefList[focusIndex].current.focus();
  };

  for (let i = 0; i < codeLength; i++) {
    const inputRef = createRef();

    inputRefList.push(inputRef);
    inputElements.push(
      <TextField
        autoFocus={i === 0}
        error={error}
        onKeyDown={(e) => handleKeyDown(e, i)}
        onInput={(e) => handleInput(e, i)}
        onFocus={handleOnFocus}
        type="text"
        inputProps={{ maxLength: 1 }}
        InputProps={{ classes: { input: classes.inputClass } }}
        variant="standard"
        inputRef={inputRef}
        key={i}
      />,
    );
  }

  async function onSubmit() {
    await authenticationService
      .authenticateTwoFactorCode(
        inputCode,
        AuthenticatorTwoFactorCodeRequestMethod,
      )
      .then(() => {
        if (
          !hasRole(OpenUpRoles.Expert, OpenUpRoles.Support, OpenUpRoles.Host)
        ) {
          moveToNextStep();
          return;
        }

        setLoggedIn();
        navigate(hasRole(OpenUpRoles.Expert) ? "/clients" : "/");
      })
      .catch(() => {
        setError(true);
      });
  }

  return (
    <form onPaste={onPaste}>
      <Box className={classes.form}>{inputElements}</Box>
      {error && (
        <Typography variant="caption" className={classes.error}>
          {t("LoginView.GoogleAuthenticatorPage.Error")}
        </Typography>
      )}
      <Button
        onClick={onSubmit}
        className={classes.button}
        disabled={inputCode.length !== codeLength}
      >
        <Typography variant="subtitle2" className={classes.textButton}>
          {t("LoginView.GoogleAuthenticatorPage.ButtonText")}
        </Typography>
      </Button>
    </form>
  );
};

export default CodeInput;
