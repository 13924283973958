import { Box, Card, Container, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Logo from "@src/components/logos/OpenUp";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(10),
    position: "relative",
    borderBottomLeftRadius: 16,
    borderBottomRightRadius: 16,
    padding: theme.spacing(8, 11),
    backgroundColor: "white",
  },
  titleTextBox: {
    textAlign: "center",
    marginTop: theme.spacing(4),
  },
}));

const LoginContainer = ({ children, title, variant }) => {
  const classes = useStyles();

  return (
    <Container maxWidth="sm">
      <Card className={classes.container}>
        <Box display="flex" justifyContent="center">
          <Logo />
        </Box>
        {title && (
          <Box className={classes.titleTextBox}>
            <Typography variant={variant || "h1"}>{title}</Typography>
          </Box>
        )}
        {children}
      </Card>
    </Container>
  );
};

export default LoginContainer;
