import { Grid } from "@mui/material";

interface HeaderProps {
  headerStyle: string;
  leftChildren: React.ReactNode;
  centerChildren: React.ReactNode;
  rightChildren: React.ReactNode;
}

/**
 * Generic component that divides items into three horizontal sections
 */
const Header: React.FC<HeaderProps> = ({
  headerStyle,
  leftChildren,
  centerChildren,
  rightChildren,
}) => {
  return (
    <Grid
      container
      spacing={1}
      justifyContent="space-between"
      alignItems="center"
      columns={12}
      className={headerStyle}
    >
      <Grid item xs="auto">
        {leftChildren}
      </Grid>
      <Grid item xs="auto">
        {centerChildren}
      </Grid>
      <Grid item xs="auto" display="flex">
        {rightChildren}
      </Grid>
    </Grid>
  );
};

export default Header;
