/* eslint-disable react/prop-types */
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { Checkbox, FormControlLabel } from "@mui/material";
import { getTranslatedThemeName } from "@src/utils/languageHelpers";

const CustomCheckbox = ({
  t,
  value = "",
  onCheck = undefined,
  isChecked = false,
}) => (
  <FormControlLabel
    key={value}
    value={value}
    label={t ? t(getTranslatedThemeName(t, value)) : value}
    onChange={(event) => {
      const {
        // eslint-disable-next-line no-shadow
        target: { value },
      } = event;
      // eslint-disable-next-line no-unused-expressions
      onCheck && onCheck(value);
    }}
    control={
      <Checkbox
        icon={<CheckBoxOutlineBlankIcon />}
        checkedIcon={<CheckBoxIcon />}
        checked={isChecked}
      />
    }
  />
);

export default CustomCheckbox;
