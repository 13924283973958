import { Box, Button, Icon, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import clsx from "clsx";
import { colors } from "@src/theme";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    alignItems: "center",
  },
  icon: {
    fontSize: "20px",
    marginRight: theme.spacing(2),
    "&>span": {
      fontSize: "20px",
    },
  },
  rightButton: {
    marginLeft: "auto",
    padding: 0,
  },
  addButton: {
    color: colors.primaryBlue,
    marginLeft: theme.spacing(1),
  },
}));

export const TypographyWithIcon = ({
  mb = 3,
  variant = "caption",
  iconLigature,
  boxClass = "",
  classesIcon = undefined,
  typographyClass = "",
  children,
  outlined = false,
  rightButtonText = undefined,
  rightButtonProps = undefined,
  ...props
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box mb={mb} display="flex" className={boxClass}>
      <Typography
        variant={variant}
        className={clsx(classes.wrapper, typographyClass)}
        {...props}
      >
        <Icon className={classesIcon || classes.icon}>
          {outlined ? (
            <span className="material-icons-outlined">{iconLigature}</span>
          ) : (
            iconLigature
          )}
        </Icon>
        {children}
      </Typography>
      {rightButtonProps && (
        <Button
          size="medium"
          className={classes.rightButton}
          {...rightButtonProps}
        >
          {rightButtonText || (
            <Box display="flex">
              <AddOutlinedIcon />
              <Typography variant="subtitle2" className={classes.addButton}>
                {t("Common.Add")}
              </Typography>
            </Box>
          )}
        </Button>
      )}
    </Box>
  );
};
