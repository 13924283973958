import { useEffect, useState } from "react";
import {
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import moment from "moment";
import { QUESTIONS_KEYS } from "@src/constants";
import {
  useClientCheckinOverviewQuery,
  useClientMedicalCheckinOverviewQuery,
} from "@src/queries/clients";
import { withSuspense } from "./wrappers/Suspendable";
import { TypographyWithIcon } from "./utils/TypographyWithIcon";
import ClientCheckinsEmpty from "./ClientCheckinsEmpty";
import ClientCheckinsOverviewFillTableCell from "./ClientCheckinsOverviewFillTableCell";
import ClientCheckinsOverviewEmptyTableCell from "./ClientCheckinsOverviewEmptyTableCell";
import ClientCheckinsOverviewAllTableCell from "./ClientCheckinsOverviewAllTableCell";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
    marginTop: theme.spacing(4),
  },
  greyedOut: {
    opacity: 0.5,
  },
  theme: {
    display: "flex",
    alignItems: "center",
    "& > b": {
      marginRight: theme.spacing(1),
    },
  },
  cell: {
    padding: theme.spacing(2, 0),
  },
  cellBorder: {
    padding: theme.spacing(2, 0),
    border: 0,
  },
}));

const groupBy = (xs, key) => {
  return xs.reduce((rv, x) => {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

const columns = [
  {
    id: "",
    minWidth: 70,
  },
  {
    id: "Checkins.Theme",
    minWidth: 250,
  },
  {
    id: "Checkins.Score",
    minWidth: 130,
  },
  {
    id: "Checkins.Date",
    minWidth: 200,
  },
  {
    id: "Checkins.Change",
    minWidth: 100,
  },
];

// eslint-disable-next-line react/prop-types
const CheckinOverview = ({ clientId }) => {
  const { data } = useClientCheckinOverviewQuery(clientId);
  const { data: medicalThemeData } =
    useClientMedicalCheckinOverviewQuery(clientId);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [completeData, setCompleteData] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    if (
      medicalThemeData?.length &&
      data &&
      !data.some((d) => d.key === QUESTIONS_KEYS.MEDICAL)
    ) {
      const flattenedQuestionLists = medicalThemeData
        .map((theme) => theme.questionLists)
        .flat(1);

      const groupedQuestionLists = Object.values(
        groupBy(flattenedQuestionLists, "createdAt"),
      );
      const scoredQuestionLists = groupedQuestionLists.map((questionLists) => ({
        key: QUESTIONS_KEYS.MEDICAL,
        score: questionLists.reduce((sum, element) => sum + element.score, 0),
        createdAt: questionLists[0].createdAt,
      }));

      data.push({
        createdAt: medicalThemeData[0].createdAt,
        isFocused: medicalThemeData[0].isFocused,
        isTrackedTheme: medicalThemeData[0].isTrackedTheme,
        key: QUESTIONS_KEYS.MEDICAL,
        questionLists: scoredQuestionLists,
        userId: medicalThemeData[0].userId,
        score: 0,
      });
    }
    setCompleteData(data);
  }, [data, medicalThemeData]);

  const [openFocus, setOpenFocus] = useState(true);
  const [openOthers, setOpenOthers] = useState(true);

  const dataCheckins = completeData.filter(
    (theme) => theme.questionLists.length > 0,
  );
  const focusedThemes = completeData.filter(
    (theme) => theme.isFocused === true,
  );
  const unfocusedThemes = completeData.filter(
    (theme) => theme.isFocused === false,
  );

  // Only if there are no focused themes, do we show all the checkins under one category ("All")
  const isAllDataPresent = focusedThemes.length === 0;

  const getCorrectDate = (time) => {
    if (moment().subtract(7, "days").valueOf() > moment(time).valueOf()) {
      return moment(time).format("lll");
    }
    if (moment().subtract(1, "days").valueOf() > moment(time).valueOf()) {
      return moment(time).calendar();
    }
    return moment(time).fromNow();
  };

  const themeInformation = (theme) =>
    navigate(t(`route.ThemeKey.${theme.key}`));

  return (
    <TableContainer component={Paper}>
      <div className="p-6">
        <TypographyWithIcon variant="h5" iconLigature="fact_check" outlined>
          {t("Common.Checkin")}
        </TypographyWithIcon>

        {dataCheckins.length > 0 ? (
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                {columns.map((column, index) => (
                  <TableCell
                    key={column.id}
                    style={{ minWidth: column.minWidth }}
                    className={index === 0 ? classes.cellBorder : classes.cell}
                  >
                    <Typography variant="subtitle1">{t(column.id)}</Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {isAllDataPresent ? (
                completeData.map((theme, index) =>
                  theme.questionLists.map((question, indexQuestion) => (
                    <ClientCheckinsOverviewAllTableCell
                      question={question}
                      message={t("Checkins.All")}
                      getCorrectDate={getCorrectDate}
                      index={index}
                      data={dataCheckins}
                      indexQuestion={indexQuestion}
                      onClick={() => themeInformation(theme)}
                    />
                  )),
                )
              ) : (
                <>
                  {focusedThemes &&
                    focusedThemes.map((theme, index) =>
                      theme.questionLists.length > 0 ? (
                        theme.questionLists.map((question, indexQuestion) => (
                          <ClientCheckinsOverviewFillTableCell
                            question={question}
                            message={t("Checkins.Focus")}
                            getCorrectDate={getCorrectDate}
                            index={index}
                            data={dataCheckins}
                            open={openFocus}
                            setOpen={setOpenFocus}
                            indexQuestion={indexQuestion}
                            onClick={() => themeInformation(theme)}
                          />
                        ))
                      ) : (
                        <ClientCheckinsOverviewEmptyTableCell
                          theme={theme}
                          message={t("Checkins.Focus")}
                          index={index}
                          open={openFocus}
                          setOpen={setOpenFocus}
                        />
                      ),
                    )}
                  {unfocusedThemes &&
                    unfocusedThemes.map((theme, index) =>
                      theme.questionLists.length > 0 ? (
                        theme.questionLists.map((question, indexQuestion) => (
                          <ClientCheckinsOverviewFillTableCell
                            question={question}
                            message={t("Checkins.Others")}
                            getCorrectDate={getCorrectDate}
                            index={index}
                            data={dataCheckins}
                            open={openOthers}
                            setOpen={setOpenOthers}
                            indexQuestion={indexQuestion}
                            onClick={() => themeInformation(theme)}
                          />
                        ))
                      ) : (
                        <ClientCheckinsOverviewEmptyTableCell
                          theme={theme}
                          message={t("Checkins.Others")}
                          index={index}
                          open={openOthers}
                          setOpen={setOpenOthers}
                        />
                      ),
                    )}
                </>
              )}
            </TableBody>
          </Table>
        ) : (
          <ClientCheckinsEmpty />
        )}
      </div>
    </TableContainer>
  );
};

export default withSuspense(CheckinOverview, {
  nothingFoundTranslationKey: "Clients.CheckinOverview.NotFound",
});
