import { Box, IconButton, TableCell, TableRow } from "@mui/material";
import { makeStyles } from "@mui/styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { QUESTIONS_KEYS } from "@src/constants";
import { colors } from "@src/theme";
import { themeValue } from "@src/utils/formatting";
import TableCellData from "./TableCellData";

const useStyles = makeStyles((theme) => ({
  cellBorder: ({ open }) => {
    return {
      padding: theme.spacing(2, 0),
      border: 0,
      color: colors.primaryBlue,
      textDecoration: open && "underline",
      cursor: "pointer",
    };
  },
  iconColor: {
    color: colors.primaryBlue,
  },
}));

const ClientCheckinsOverviewFillTableCell = ({
  question,
  message,
  getCorrectDate,
  index,
  data,
  open,
  setOpen,
  indexQuestion,
  onClick,
}) => {
  const valueQuestion = Object.values(QUESTIONS_KEYS).find(
    (value) => value === question.key,
  );
  const dataTheme = data.find(
    (theme) => theme.key === themeValue(valueQuestion),
  );
  const dataThemeQuestionLists = dataTheme.questionLists;

  const secondScore = dataThemeQuestionLists
    .map(
      (question, indexQuestionScore) =>
        indexQuestionScore === indexQuestion + 1 && question.score,
    )
    .find((result) => typeof result === "number");

  const classes = useStyles({ open });

  return (
    <>
      {indexQuestion === 0 && (
        <TableRow
          role="checkbox"
          tabIndex={-1}
          key={question.key}
          onClick={onClick}
        >
          {index === 0 ? (
            <Box
              display="flex"
              alignItems="center"
              height={97}
              onClick={(event) => {
                event.stopPropagation(); // to prevent the onlick event of the parent (row)
                setOpen(!open);
              }}
            >
              <TableCell className={classes.cellBorder}>{message}</TableCell>
              <IconButton aria-label="expand row" size="small">
                {open ? (
                  <KeyboardArrowUpIcon className={classes.iconColor} />
                ) : (
                  <KeyboardArrowDownIcon className={classes.iconColor} />
                )}
              </IconButton>
            </Box>
          ) : open ? (
            <TableCell className={classes.cellBorder} />
          ) : null}
          {open ? (
            <TableCellData
              question={question}
              getCorrectDate={getCorrectDate}
              secondScore={secondScore}
            />
          ) : (
            index === 0 && (
              <TableCellData
                question={question}
                getCorrectDate={getCorrectDate}
                secondScore={secondScore}
              />
            )
          )}
        </TableRow>
      )}
    </>
  );
};

export default ClientCheckinsOverviewFillTableCell;
