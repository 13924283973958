import {
  Grid,
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
  Box,
  Divider,
  TextareaAutosize,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIcon from "@mui/icons-material/ArrowForwardIos";
import IconButton from "@mui/material/IconButton";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { colors } from "@src/theme";
import {
  useGetAvailableSlotsForCurrentPsychologist,
  useSchedulerGetAvailableSlotsForPsychologist,
} from "@src/queries/scheduler";
import { getPsychologistTimezone } from "@src/utils/timezone";
import "moment-timezone";
import {
  formatWeek,
  formatWeekWithYear,
  getYearTime,
  moveBackwardWeeks,
  moveForwardWeeks,
  getWeekday,
  formatDateWithMonth,
} from "@src/utils/formatting";
import { withSnackbar } from "@src/components/SnackBarComponent";
import { useParams } from "react-router";
import TimeZoneSelector from "./TimeZoneSelector";
import SessionsContainer from "../SessionsContainer";
import NoAvailableTimeSlots from "./NoAvailableTimeSlots";
import DayTab from "./DayTab";

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  container: {
    [breakpoints.down("xs")]: {
      marginBottom: spacing(16),
    },
    borderRadius: "16px",
    backgroundColor: "white",
  },
  cardActions: {
    justifyContent: "center",
    borderTop: `solid 2px rgba(21, 35, 97, .05);`,
    width: "100%",
    [breakpoints.down("xs")]: {
      paddingRight: spacing(2),
      position: "fixed",
      bottom: 0,
      right: 0,
      backgroundColor: "#ffffff",
      padding: spacing(0),
      boxShadow: "0px 0 16px rgba(21, 35, 97, 30% )",
      display: "block",
    },
  },
  continueButton: {
    margin: spacing(2),
    width: 360,
    borderRadius: "24px",
    boxShadow: "none",
    color: "white",
    [breakpoints.down("xs")]: {
      width: "100%",
    },
    "&:hover": {
      color: "white",
      backgroundColor: colors.primaryBlue,
      boxShadow: "none",
    },
  },
  continueButtonText: {
    color: "white",
  },
  textMessage: {
    opacity: "0.7",
    display: "flex",
    justifyContent: "center",
    marginTop: spacing(4),
    [breakpoints.down("xs")]: {
      marginTop: spacing(0),
      marginBottom: spacing(1.5),
      marginLeft: spacing(4),
      marginRight: spacing(4),
      width: "100%",
      textAlign: "center",
    },
  },
  cardContent: {
    padding: spacing(4, 3),
    [breakpoints.down("md")]: {
      padding: spacing(3, 2),
    },
  },
  topTabs: {
    borderBottom: 1,
    borderColor: "divider",
    backgroundColor: colors.primaryGreen,
    margin: spacing(-3),
    marginTop: spacing(-4),
  },
  dateBox: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    paddingTop: spacing(5),
    marginBottom: spacing(4),
  },
  arrowBack: {
    color: "white",
    fontSize: 16,
  },
  arrowDisabled: {
    color: "white",
    fontSize: 16,
    opacity: 0.5,
  },
  arrowForward: {
    color: "white",
    fontSize: 16,
  },
  date: {
    color: "white",
  },
  rootTabPanel: {
    [breakpoints.down("xs")]: {
      "&>div": {
        padding: spacing(0),
        paddingTop: spacing(3),
      },
    },
  },
  button: {
    border: "1px solid",
    borderRadius: "8px",
    "&:hover": {
      backgroundColor: colors.primaryDarkBlue,
      color: "white",
    },
  },
  unselectedButton: {
    width: 126,
    backgroundColor: "white",
    color: colors.secondaryBlue,
    [breakpoints.down("xs")]: {
      width: 94,
    },
  },
  buttonSelect: {
    width: 125,
    backgroundColor: colors.primaryDarkBlue,
    color: "white",
    [breakpoints.down("xs")]: {
      width: 95,
    },
  },
  buttonOldTime: {
    width: 125,
    backgroundColor: colors.primaryBlue,
    color: "white",
    [breakpoints.down("xs")]: {
      width: 95,
    },
  },
  divider: {
    margin: spacing(1.5, -3),
  },
  reasonReschedule: {
    opacity: 1,
    marginTop: spacing(3),
    marginBottom: spacing(1.5),
  },
  textArea: {
    width: "100%",
    border: 0,
    backgroundColor: colors.primarySkin,
    resize: "none",
  },
}));

const isoStringDate = (date) => {
  return moment(date).utc().toISOString();
};

const TabPanel = ({ children, value, index, ...other }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

const TimeSlotPicker = ({
  onBookSession,
  sessionType,
  language,
  consult,
  setReasonReschedule,
  clientFirstName,
  ...props
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { psychologistId } = useParams();

  const [timeZoneValue, setTimeZoneValue] = useState(
    consult?.clientTimezone ?? getPsychologistTimezone(),
  );
  const [startFetchDate, setStartFetchDate] = useState(
    isoStringDate(consult?.startedAt),
  );
  const [endFetchDate, setEndFetchDate] = useState(
    consult
      ? moment(consult.startedAt).endOf("isoWeek").toISOString()
      : undefined,
  );

  const [currentDateTime, setCurrentDateTime] = useState(
    consult
      ? isoStringDate(consult.startedAt)
      : moment.utc().startOf("day").toISOString(),
  );
  const [weekDaysAvailabilityStatus, setWeekDaysAvailabilityStatus] = useState(
    [],
  );
  const [firstTimePageLanding, setFirstTimePageLanding] = useState(true);
  const [arrowBackDisabled, setArrowBackDisabled] = useState(false);
  const [arrowForwardDisabled, setArrowForwardDisabled] = useState(false);
  const [startTimes, setStartTimes] = useState([]);

  const [next, setNext] = useState(
    consult ? moment(consult.startedAt).week() - moment.utc().week() : 0,
  );

  const [selectedTime, setSelectedTime] = useState({
    startTime: consult ? isoStringDate(consult.startedAt) : undefined,
  });

  const {
    isFetching: isFetchingCurrentPsychologist,
    data: availableSlotsForCurrentPsychologist,
  } = useGetAvailableSlotsForCurrentPsychologist(
    timeZoneValue,
    startFetchDate,
    endFetchDate,
  );

  const {
    isFetching: isFetchingFollowUpSessionPsychologist,
    data: availableSlotsForFollowUpSessionPsychologist,
  } = useSchedulerGetAvailableSlotsForPsychologist(
    timeZoneValue,
    startFetchDate,
    endFetchDate,
    consult?.employee.id,
  );

  const {
    isFetching: isFetchingSessionPsychologist,
    data: availableSlotsForSessionPsychologist,
  } = useSchedulerGetAvailableSlotsForPsychologist(
    timeZoneValue,
    startFetchDate,
    endFetchDate,
    psychologistId,
  );

  const availableSlotsInWeek = !psychologistId
    ? availableSlotsForFollowUpSessionPsychologist ||
      availableSlotsForCurrentPsychologist
    : availableSlotsForSessionPsychologist;
  const isFetching = !psychologistId
    ? isFetchingCurrentPsychologist || isFetchingFollowUpSessionPsychologist
    : isFetchingSessionPsychologist;

  const sortDays = (days) => {
    days.sort((dayA, dayB) => moment(dayA).unix() - moment(dayB).unix());
  };

  const setAvailableDate = (date) => {
    setCurrentDateTime(moment(date).utc().toISOString());

    if (moment(date).isoWeek() > moment(startFetchDate).isoWeek()) {
      setNext(moment(date).isoWeek() - moment(startFetchDate).isoWeek());
    }
  };

  const resetSelectedTime = () => {
    setSelectedTime({ startTime: undefined });
  };

  const goNextWeek = (date) => {
    const nextWeekDay = moment(date).utc().add(1, "weeks");
    setCurrentDateTime(nextWeekDay.toISOString());
    resetSelectedTime();
    setStartFetchDate(nextWeekDay.startOf("isoWeek").toISOString());
    setEndFetchDate(nextWeekDay.endOf("isoWeek").toISOString());
    setNext(next + 1);
  };

  const getCurrentDateWithTimeSlots = () => {
    let currentDateWithTimeSlots = { date: currentDateTime, timeSlots: [] };
    if (firstTimePageLanding) {
      if (consult) {
        const consultDay = availableSlotsInWeek.find((day) =>
          moment(day.date).isSame(consult.startedAt, "day"),
        );
        if (
          !consultDay.timeSlots.find((slot) =>
            moment(slot.startTime).isSame(consult.startedAt, "time"),
          )
        ) {
          consultDay.timeSlots.push({
            startTime: isoStringDate(consult.startedAt),
          });
        }
        currentDateWithTimeSlots = consultDay;
        setFirstTimePageLanding(false);
      } else {
        const daysWithAvailableTimeSlots = availableSlotsInWeek.filter(
          (weekDay) => weekDay.timeSlots.length > 0,
        );
        if (daysWithAvailableTimeSlots.length > 0) {
          currentDateWithTimeSlots = daysWithAvailableTimeSlots[0];
          setFirstTimePageLanding(false);
        } else {
          goNextWeek(startFetchDate);
          return;
        }
      }
    } else {
      currentDateWithTimeSlots = availableSlotsInWeek.find((weekDay) =>
        moment(weekDay.date).utc().isSame(startFetchDate, "day"),
      );
    }
    return currentDateWithTimeSlots;
  };

  const initializeWithAllUnavailableSlots = () => {
    setAvailableDate(startFetchDate);
    setStartTimes([]);
    setWeekDaysAvailabilityStatus([
      false,
      false,
      false,
      false,
      false,
      false,
      false,
    ]);
    setArrowBackDisabled(true);
    setArrowForwardDisabled(true);
    props.snackbarShowMessage(t("Booking.TimeSlot.NoAvailableSlots"));
  };

  const setTimeSlots = (timeSlots) => {
    const slots = timeSlots.map((availableSlot) => {
      return {
        startTime: availableSlot.startTime,
        timezone: timeZoneValue,
      };
    });
    slots.sort(
      (a, b) => moment(a.startTime).unix() - moment(b.startTime).unix(),
    );

    setStartTimes(slots);
  };

  useEffect(() => {
    if (availableSlotsInWeek) {
      sortDays(availableSlotsInWeek);

      const currentDateWithTimeSlots = getCurrentDateWithTimeSlots();
      if (!currentDateWithTimeSlots) return;
      setAvailableDate(currentDateWithTimeSlots.date);
      setTimeSlots(currentDateWithTimeSlots.timeSlots);

      setWeekDaysAvailabilityStatus(
        availableSlotsInWeek.map((item) => item.timeSlots.length > 0),
      );
      setArrowBackDisabled(
        moment(availableSlotsInWeek[0].date)
          .utc()
          .isSameOrBefore(moment.utc(), "day"),
      );
    } else {
      initializeWithAllUnavailableSlots();
    }
  }, [availableSlotsInWeek]);

  useEffect(() => {
    if (!firstTimePageLanding && availableSlotsInWeek) {
      resetSelectedTime();
      const currentDayOfWeek = availableSlotsInWeek.find((weekDay) =>
        moment(weekDay.date).utc().isSame(currentDateTime),
      );
      setAvailableDate(currentDayOfWeek.date);
      setTimeSlots(currentDayOfWeek.timeSlots);
    }
  }, [timeZoneValue]);

  const handleButtonClick = (data) => {
    setSelectedTime(data);
  };

  const handleNextClick = () => {
    goNextWeek(currentDateTime);
  };
  const handleBackClick = () => {
    resetSelectedTime();
    const prevWeekDay = moment(currentDateTime).utc().add(-1, "weeks");
    setCurrentDateTime(prevWeekDay.toISOString());
    setStartFetchDate(prevWeekDay.startOf("isoWeek").toISOString());
    setEndFetchDate(prevWeekDay.endOf("isoWeek").toISOString());
    setNext(next - 1);
  };

  const updateCurrentDateTime = (updatedDateTimeString) => {
    const updatedDateTime = moment(updatedDateTimeString).utc();
    if (!moment(selectedTime).utc().isSame(updatedDateTime)) {
      resetSelectedTime();
    }
    setCurrentDateTime(updatedDateTime.toISOString());
    if (availableSlotsInWeek) {
      const dayWithTimeSlots = availableSlotsInWeek.find((weekDay) =>
        moment(weekDay.date).utc().isSame(updatedDateTime),
      );
      if (dayWithTimeSlots) {
        setTimeSlots(dayWithTimeSlots.timeSlots);
        return;
      }
    }
    setTimeSlots([]);
  };

  const handleChangeTextArea = (event) => {
    setReasonReschedule(event.target.value);
  };

  const matchesConsultStart = (timeslot) => {
    return moment(timeslot?.startTime).utc().isSame(consult?.startedAt);
  };

  let weekStart = moveForwardWeeks(next);
  const weekEnd = formatWeekWithYear(moveBackwardWeeks(next));
  const yearWeekStart = getYearTime(weekStart);
  const yearWeekEnd = getYearTime(weekEnd);
  weekStart =
    yearWeekStart !== yearWeekEnd
      ? formatWeekWithYear(weekStart)
      : formatWeek(weekStart);
  const week = moment().add(next, "weeks").format("WW");

  const currentWeekday = getWeekday(currentDateTime);
  const currentDayWithMonth = formatDateWithMonth(currentDateTime);

  const title = clientFirstName
    ? t("Booking.ForClient.TimeSlot.Title", { firstname: clientFirstName })
    : t("Booking.TimeSlot.Title");

  return (
    <SessionsContainer title={title} isMediumContainerWanted>
      <Card className={classes.container}>
        <CardContent className={classes.cardContent}>
          <Box sx={{ width: "100%" }}>
            <Box
              sx={{ borderBottom: 1, borderColor: "divider" }}
              className={classes.topTabs}
            >
              <Box className={classes.dateBox}>
                <IconButton
                  className={classes.iconButton}
                  disabled={arrowBackDisabled || isFetching}
                  onClick={() => handleBackClick()}
                  data-cy="previous-week"
                  size="large"
                >
                  <ArrowBackIcon
                    className={
                      arrowBackDisabled || isFetching
                        ? classes.arrowDisabled
                        : classes.arrowBack
                    }
                  />
                </IconButton>
                <Typography variant="body1" className={classes.date}>
                  {t("Booking.TimeSlot.CurrentDateWeek", {
                    weekday: currentWeekday,
                    today: currentDayWithMonth,
                    weekNumber: week,
                  })}
                </Typography>
                <IconButton
                  className={classes.iconButton}
                  disabled={arrowForwardDisabled || isFetching}
                  onClick={() => handleNextClick()}
                  data-cy="next-week"
                  size="large"
                >
                  <ArrowForwardIcon
                    className={
                      arrowForwardDisabled || isFetching
                        ? classes.arrowDisabled
                        : classes.arrowForward
                    }
                    data-cy="arrow-forward"
                  />
                </IconButton>
              </Box>

              <DayTab
                next={next}
                currentDateTime={currentDateTime}
                setCurrentDateTime={updateCurrentDateTime}
                weekDaysAvailabilityStatus={weekDaysAvailabilityStatus}
              />
            </Box>
            <TabPanel value={0} index={0} className={classes.rootTabPanel}>
              <TimeZoneSelector
                sessionType={sessionType}
                language={language}
                timeZoneValue={timeZoneValue}
                setTimeZoneValue={setTimeZoneValue}
              />
              {startTimes.length === 0 ? (
                <NoAvailableTimeSlots />
              ) : (
                <Grid container spacing={2}>
                  {startTimes.map((timeSlot) => (
                    <Grid item xs={4} md={2} key={timeSlot.startTime}>
                      <Button
                        className={`${classes.button} ${
                          matchesConsultStart(timeSlot)
                            ? classes.buttonOldTime
                            : timeSlot.startTime === selectedTime?.startTime
                              ? classes.buttonSelect
                              : classes.unselectedButton
                        }`}
                        onClick={() => handleButtonClick(timeSlot)}
                        disabled={
                          moment(timeSlot.startTime).tz(timeSlot.timezone) <=
                          moment.utc().tz(timeSlot.timezone)
                        }
                      >
                        {moment(timeSlot.startTime)
                          .tz(timeSlot.timezone)
                          .format("HH:mm")}
                      </Button>
                    </Grid>
                  ))}
                </Grid>
              )}
            </TabPanel>
          </Box>
          {!!setReasonReschedule && (
            <>
              <Divider className={classes.divider} />
              <Box>
                <Typography
                  variant="subtitle2"
                  className={classes.reasonReschedule}
                >
                  {t("Booking.TimeSlot.ReasonReschedule")}
                </Typography>
                <TextareaAutosize
                  onChange={(event) => handleChangeTextArea(event)}
                  placeholder={t("Booking.TimeSlot.TextArea.Placeholder")}
                  className={classes.textArea}
                  minRows={5}
                />
              </Box>
            </>
          )}
        </CardContent>
        <CardActions className={classes.cardActions}>
          <Button
            variant="contained"
            className={classes.continueButton}
            disabled={
              !selectedTime?.startTime || matchesConsultStart(selectedTime)
            }
            onClick={() => onBookSession(selectedTime)}
          >
            <Typography
              variant="subtitle2"
              className={classes.continueButtonText}
            >
              {t("Booking.TimeSlot.BookingSession")}
            </Typography>
          </Button>
        </CardActions>
      </Card>
      <Typography variant="body2" className={classes.textMessage}>
        {t("Booking.TimeSlot.MessageTermsOfService")}
      </Typography>
    </SessionsContainer>
  );
};
export default withSnackbar(TimeSlotPicker);
