import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import LoginContainer from "./LoginContainer";

const useStyles = makeStyles(() => ({
  textUnderline: {
    textDecoration: "underline",
  },
}));
const RequestAccessDashboard = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <LoginContainer title={t("LoginView.RequestPage")} variant="h2">
      <Box>
        <Box display="flex" mt={4} textAlign="center" justifyContent="center">
          <Typography variant="body1">
            {t("LoginView.RequestPage.Text.Part1")}
            <Typography variant="body1" className={classes.textUnderline}>
              {t("LoginView.RequestPage.Text.Part2")}
            </Typography>
            {t("LoginView.RequestPage.Text.Part3")}
          </Typography>
        </Box>
        <Box mt={3} textAlign="center">
          <Typography variant="caption">
            {t("LoginView.RequestPage.NB")}
          </Typography>
        </Box>
      </Box>
    </LoginContainer>
  );
};

export default RequestAccessDashboard;
