import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { withSnackbar } from "@src/components/SnackBarComponent";
import TopBar from "./TopBar";

const FlowLayout = (props) => {
  const [step, setStep] = useState(0);
  const [stepsToSkip, setStepsToSkip] = useState([]);
  const [preventGoingBack, setPreventGoingBack] = useState(false);
  const [url, setUrl] = useState();
  const [snackbarMessage, setSnackbarMessage] = useState();

  const navigate = useNavigate();

  const nextStep = () => {
    let stepToGoTo = step + 1;

    while (stepsToSkip.includes(stepToGoTo)) {
      stepToGoTo += 1;
    }

    setStep(stepToGoTo);
  };

  const previousStep = () => {
    if (preventGoingBack) return;

    let stepToGoTo = step - 1;

    while (stepsToSkip.includes(stepToGoTo)) {
      stepToGoTo -= 1;
    }

    if (stepToGoTo >= 0) {
      setStep(stepToGoTo);
    } else {
      navigate(-1);
    }
  };

  const goToStep = (newStep) => setStep(newStep);

  const addStepToSkip = (stepToSkip) => {
    stepsToSkip.push(stepToSkip);
    setStepsToSkip(stepsToSkip);
  };

  const removeStepToSkip = (stepToSkip) => {
    const newArray = stepsToSkip.filter((item) => item !== stepToSkip);
    setStepsToSkip(newArray);
  };

  const navigateToUrl = (newUrl) => {
    setUrl(newUrl);
  };

  useEffect(() => {
    if (!url) return;

    setStepsToSkip([]);
    setPreventGoingBack(false);
    setStep(0);
    navigate(url, { replace: true });
  }, [url]);

  const showSnackbarMessage = (message) => {
    setSnackbarMessage(message);
  };

  useEffect(() => {
    if (!snackbarMessage) return;

    props.snackbarShowMessage(snackbarMessage);
  }, [snackbarMessage]);

  return (
    <div>
      <TopBar step={step} moveToPrevStep={previousStep} />
      <Outlet
        context={{
          step,
          nextStep,
          previousStep,
          goToStep,
          navigateToUrl,
          addStepToSkip,
          removeStepToSkip,
          setPreventGoingBack,
          showSnackbarMessage,
        }}
      />
    </div>
  );
};

export default withSnackbar(FlowLayout);
