import { TableCell, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { formatFirstUpperCase } from "@src/utils/formatting";

const useStyles = makeStyles((theme) => ({
  cell: {
    padding: theme.spacing(2, 0),
  },
}));

const TableCellNoData = ({ theme }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <TableCell className={classes.cell}>
        <Typography variant="body1">
          {formatFirstUpperCase(theme.key)}
        </Typography>
      </TableCell>
      <TableCell className={classes.cell}>
        <Typography variant="body1" className={classes.cell}>
          {t("Checkins.Empty")}
        </Typography>
      </TableCell>
      <TableCell className={classes.cell}>
        <Typography variant="body1">{t("Checkins.Empty")}</Typography>
      </TableCell>
      <TableCell className={classes.cell}>{t("Checkins.Empty")}</TableCell>
    </>
  );
};

export default TableCellNoData;
