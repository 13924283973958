import {
  FileUploadHandler,
  FileUploadManager,
} from "@azure/communication-react";
import { apiPathsWithParams } from "@src/constants";
import axios from "axios";
import FormData from "form-data";

const uploadChatFile = async (
  fileUpload: FileUploadManager,
  chatThreadId: string,
  uploadFailedMessage: string,
) => {
  const formData = new FormData();
  formData.append("file", fileUpload.file, fileUpload.file?.name);
  try {
    const response = await axios
      .request({
        method: "post",
        url: apiPathsWithParams.uploadChatFile(chatThreadId),
        data: formData,
        headers: {
          "Content-Type": `multipart/form-data`,
        },
        onUploadProgress: (p) => {
          if (p.total)
            fileUpload.notifyUploadProgressChanged(p.loaded / p.total);
        },
      })
      .catch((e) => {
        const errors = Object.values<[]>(e.response?.data?.errors).flat(2) as {
          message: string;
        }[];
        if (errors.length === 0)
          fileUpload.notifyUploadFailed(uploadFailedMessage);
        else
          errors.forEach((error) => {
            fileUpload.notifyUploadFailed(error.message);
          });
      });
    const fileExtension = fileUpload.file?.name.split(".").pop() ?? "";
    // 1 means the file upload progress is 100%. Similarly, 0.5 would be 50%.
    fileUpload.notifyUploadProgressChanged(1);
    fileUpload.notifyUploadCompleted({
      id: response?.data.url,
      name: fileUpload.file?.name ?? "",
      extension: fileExtension,
      url: response?.data.url,
    });
  } catch (error) {
    fileUpload.notifyUploadFailed(uploadFailedMessage);
    throw error;
  }
};

export const getAcsFileUploadHandler: (
  chatThreadId: string,
  uploadFailedMessage: string,
) => FileUploadHandler =
  (chatThreadId: string, uploadFailedMessage: string) =>
  async (userId: string, fileUploads: FileUploadManager[]): Promise<void> => {
    fileUploads.forEach(async (fileUpload) => {
      await uploadChatFile(fileUpload, chatThreadId, uploadFailedMessage);
    });
  };
