import { useContext } from "react";
import { Box, TextField, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import Autocomplete from "@mui/material/Autocomplete";
import { useTranslation } from "react-i18next";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs from "dayjs";
import FormikDateTimePicker from "@src/components/form/FormikDatePicker";
import TagSelector from "@src/components/TagSelector";
import PsychologistsAutocomplete from "@src/components/form/PsychologistsAutocomplete";
import { getLanguageNameBasedOnAbbreviation } from "@src/utils/languageHelpers";
import Loader from "@src/components/Loader";
import { SessionDetailsContext } from "@src/utils/contexts";

const useStyles = makeStyles(({ spacing, colors }) => ({
  tableHeaderContainer: {
    borderBottom: "none",
    backgroundColor: colors.almostWhite,
    padding: spacing(2, 4, 1, 3),
  },
  tableHeaderText: {
    fontWeight: 500,
  },
  editingIndicatorText: {
    fontStyle: "italic",
    color: colors.primaryDarkGrayWithTransparency,
  },
  formContainer: {
    backgroundColor: colors.almostWhite,
  },
  gridItem: {
    paddingLeft: spacing(3),
  },
  rightPadding: {
    paddingRight: spacing(3),
  },
  cancelButtonContainer: {
    marginRight: spacing(1.5),
  },
  tagSelectors: {
    flexBasis: "100%",
    minHeight: 100,
    marginLeft: spacing(3),
  },
  sessionTypeHeaderText: {
    fontWeight: 500,
    margin: spacing(1, 0, 0),
  },
  loaderContainer: {
    margin: spacing(6, 0, 6),
  },
  rightInputWidth: { paddingRight: "24px" },
}));

/**
 * Form for editing session details
 */
const SessionDetailsEditing = ({ formikDetails, isNewConsult }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { allSessionTypeTags, allLanguageTags } = useContext(
    SessionDetailsContext,
  );

  if (!allLanguageTags?.tags || !allSessionTypeTags) {
    return null;
  }

  return (
    <div className={classes.formContainer}>
      {formikDetails.isSubmitting ? (
        <div className={classes.loaderContainer}>
          <Loader />
        </div>
      ) : (
        <Grid container spacing={1} style={{ width: "100%", margin: "0px" }}>
          <Grid item xs={6}>
            <div className={classes.gridItem}>
              <FormikDateTimePicker
                name="startedAt"
                formik={formikDetails}
                readOnly={!isNewConsult}
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div
              className={`${classes.gridItem} ${classes.rightPadding}`}
              data-cy="time-picker"
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  ampm={false}
                  ampmInClock={false}
                  label={t("Common.Time")}
                  minutesStep={30}
                  readOnly={!isNewConsult}
                  onChange={(newValue) =>
                    formikDetails.setFieldValue(
                      "startedAt",
                      newValue.toISOString(),
                    )
                  }
                  value={dayjs(formikDetails.values.startedAt)}
                />
              </LocalizationProvider>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className={classes.gridItem}>
              <PsychologistsAutocomplete
                error={!!formikDetails.errors.employeeId}
                required
                onChange={(psychologist) => {
                  formikDetails.setFieldValue("employeeId", psychologist?.id);
                  formikDetails.setFieldValue(
                    "employeeName",
                    psychologist?.firstName,
                  );
                }}
                value={formikDetails.values.employeeId || null}
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className={classes.gridItem}>
              <Autocomplete
                id="language-selector"
                data-cy="language-selector"
                onChange={(_, languageTag) => {
                  formikDetails.setFieldValue("consultLanguage", languageTag);
                }}
                value={formikDetails.values.consultLanguage}
                options={allLanguageTags.tags.map((tag) => tag.name)}
                getOptionLabel={(tag) =>
                  getLanguageNameBasedOnAbbreviation(t, tag)
                }
                className={classes.rightInputWidth}
                renderInput={(params) => (
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  <TextField {...params} label="Language" variant="standard" />
                )}
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <Box className={classes.tagSelectors}>
              <Typography className={classes.sessionTypeHeaderText}>
                {t("Tags.SessionTypeTagsTitle")}
              </Typography>
              <TagSelector
                tagState={[formikDetails.values.consultType]}
                setTagState={(selectedSessionType) => {
                  formikDetails.setFieldValue(
                    "consultType",
                    selectedSessionType[0],
                  );
                }}
                tagType={allSessionTypeTags}
                error={!!formikDetails.errors.consultType}
                formikValue="consultType"
                formik={formikDetails}
                singleChoice
              />
            </Box>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

SessionDetailsEditing.propTypes = {
  formikDetails: PropTypes.object.isRequired,
  isNewConsult: PropTypes.bool,
};

SessionDetailsEditing.defaultProps = {
  formikDetails: {},
  isNewConsult: false,
};

export default SessionDetailsEditing;
