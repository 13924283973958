import {
  withSnackbar,
  WithSnackbarProps,
} from "@src/components/SnackBarComponent";
import React from "react";
import {
  Dialog,
  DialogContent,
  TextField,
  Typography,
  FormControl,
  alpha,
} from "@mui/material";
import { Client } from "@src/models";
import {
  DialogHeader,
  DialogActionButtons,
} from "@src/components/DialogToolkit";
import { useTranslation } from "react-i18next";
import { ReactComponent as AnonymizeClientIcon } from "@src/resources/icons/anonymize-client.svg";
import { useFormik } from "formik";
import { useClientAnonymizeMutation } from "@src/queries/clients";
import { makeStyles } from "@mui/styles";
import { colors } from "@src/theme";

const useStyles = makeStyles(({ spacing }) => ({
  dialogContent: {
    padding: spacing(3),
    marginLeft: spacing(-3),
    marginRight: spacing(-3),
    marginTop: spacing(-3),
    backgroundColor: alpha(colors.primaryDarkBlue, 0.03),
  },
}));

interface AnonymizeClientDialogProps extends WithSnackbarProps {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  client: Client;
  snackbarShowMessage: (message: string, variant?: "success" | "error") => void;
}

const AnonymizeClientDialog: React.FC<AnonymizeClientDialogProps> = ({
  open,
  onClose,
  onSuccess,
  client,
  snackbarShowMessage,
}) => {
  const { t } = useTranslation();
  const { mutateAsync: anonymizeClient } = useClientAnonymizeMutation();
  const formik = useFormik({
    initialValues: { email: "" },
    validate(values) {
      if (!values.email) {
        return { email: t("AnonymizeClient.Email.Error.Required") };
      }
      if (values.email.toUpperCase() !== client.email.toUpperCase()) {
        return { email: t("AnonymizeClient.Email.Error.Match") };
      }
      return {};
    },

    onSubmit: async () => {
      try {
        await anonymizeClient(client.id);
        snackbarShowMessage(t("AnonymizeClient.Message.Success"), "success");
        onSuccess();
      } catch (error) {
        snackbarShowMessage(t("Common.GenericErrorMessage"), "error");
      }
    },
  });

  const classes = useStyles();

  if (!client) {
    return null;
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogHeader
        iconLigature={<AnonymizeClientIcon />}
        title={t("AnonymizeClient.Title")}
        outlined
        onDelete={undefined}
        onFilter={undefined}
        textRightButton={undefined}
      />

      <DialogContent className={classes.dialogContent}>
        <form onSubmit={formik.handleSubmit}>
          <FormControl fullWidth>
            <Typography variant="subtitle1" paddingBottom={1}>
              {t("AnonymizeClient.Email.Label")}
            </Typography>

            <TextField
              error={!formik.isValid}
              label={t("AnonymizeClient.Email.Description")}
              name="email"
              id="email"
              type="email"
              fullWidth
              variant="standard"
              value={formik.values.email}
              helperText={formik.errors.email}
              autoFocus
              InputLabelProps={{ shrink: true }}
              onChange={(e) => {
                formik.setValues({ email: e.target.value });
              }}
            />
          </FormControl>
        </form>
      </DialogContent>
      <DialogActionButtons
        onCancel={(e) => {
          formik.handleReset(e);
          onClose();
        }}
        onSubmit={formik.submitForm}
        onCancelText={t("AnonymizeClient.Cancel.Button")}
        onSubmitText={t("AnonymizeClient.Confirm.Button")}
        disabled={undefined}
      />
    </Dialog>
  );
};
export default withSnackbar(AnonymizeClientDialog);
