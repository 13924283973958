import { Box, Link, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { isWindows, isMacOs, isAndroid, isIOS } from "react-device-detect";
import { ReactComponent as GoogleAuthenticatorImage } from "@src/components/images/logoGoogleAuthentificator.svg";
import { ReactComponent as GoogleAuthenticatorExample } from "@src/components/images/googleAutheticatorAccount.svg";
import CodeInput from "./CodeInput";
import LoginContainer from "./LoginContainer";

const useStyles = makeStyles((theme) => ({
  textList: {
    marginLeft: theme.spacing(3),
  },
  googleImage: {
    marginRight: theme.spacing(1),
  },
  textUnderline: {
    cursor: "pointer",
    textDecoration: "underline",
    fontWeight: "bold",
    display: "inline-flex",
    verticalAlign: "top",
    alignItems: "center",
    marginRight: theme.spacing(1),
  },
  imageGoogleExample: {
    display: "flex",
    marginLeft: "auto",
    marginRight: "auto",
  },
}));

const GoogleAuthenticator = ({ moveToNextStep }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [link, setLink] = useState();

  useEffect(() => {
    if (isWindows || isAndroid)
      setLink(t("LoginView.FactorAuthentificationPage.WindowsLink"));
    if (isMacOs || isIOS)
      setLink(t("LoginView.FactorAuthentificationPage.MacLink"));
  }, [t]);

  return (
    <LoginContainer title={t("LoginView.GoogleAuthenticatorPage")} variant="h2">
      <Box>
        <Box display="flex" mt={3} alignItems="baseline">
          <Typography variant="body1">
            {t("LoginView.GoogleAuthenticatorPage.FirstElementList")}
          </Typography>
          <Typography variant="body1" className={classes.textList}>
            {t("LoginView.GoogleAuthenticatorPage.FirstElementText")}
            <Link
              target="_blank"
              href={link}
              variant="body1"
              className={classes.textUnderline}
              underline="hover"
            >
              <GoogleAuthenticatorImage className={classes.googleImage} />
              {t("LoginView.GoogleAuthenticatorPage.FirstElementBold")}
            </Link>
            {t("LoginView.GoogleAuthenticatorPage.FirstElementText2")}
          </Typography>
        </Box>
        <Box mt={1} mb={1}>
          <GoogleAuthenticatorExample className={classes.imageGoogleExample} />
        </Box>
        <Box display="flex" mt={2}>
          <Typography variant="body1">
            {t("LoginView.GoogleAuthenticatorPage.SecondElementList")}
          </Typography>
          <Typography variant="body1" className={classes.textList}>
            {t("LoginView.GoogleAuthenticatorPage.SecondElementText")}
            <Box display="inline" ml={0.5} fontWeight="bold">
              {t("LoginView.GoogleAuthenticatorPage.SecondElementTextBold")}
            </Box>
            {t("LoginView.GoogleAuthenticatorPage.SecondElementText2")}
          </Typography>
        </Box>
        <CodeInput codeLength={6} moveToNextStep={moveToNextStep} />
      </Box>
    </LoginContainer>
  );
};

export default GoogleAuthenticator;
