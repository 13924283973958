import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link } from "react-router-dom";
import { colors } from "@src/theme";
import Logo from "@src/components/Logo";
import LanguageSelector from "@src/components/LanguageSelector";

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  root: {
    [breakpoints.up("md")]: {
      paddingTop: spacing(6.25),
      marginTop: 0,
    },
    [breakpoints.down("xs")]: {
      paddingTop: spacing(3),
      marginTop: 0,
    },
  },
  arrowIcon: {
    height: 48,
    width: 48,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: colors.primarySkin,
    borderRadius: "24px",
    cursor: "pointer",
    [breakpoints.up("md")]: {
      marginRight: spacing(33),
    },
    [breakpoints.down("xs")]: {
      marginRight: spacing(8),
    },
  },
  languageSelectorWelcome: {
    [breakpoints.up("md")]: {
      marginLeft: spacing(28),
    },
    [breakpoints.down("xs")]: {
      marginLeft: spacing(5),
    },
  },
  logoCenter: {
    minHeight: 28,
  },
}));

const TopBar = ({ moveToPrevStep }) => {
  const classes = useStyles();

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      className={classes.root}
    >
      <Box className={classes.arrowIcon} onClick={moveToPrevStep}>
        <ArrowBackIcon />
      </Box>
      <Link to="/">
        <Logo className={classes.logoCenter} />
      </Link>
      <LanguageSelector className={classes.languageSelectorWelcome} />
    </Box>
  );
};

export default TopBar;
