import { useState } from "react";
import {
  Icon,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { useQuestionnairesQuery } from "@src/queries/questionnaires";
import { isEmptyQueryResult } from "@src/queries/utils";
import { withSuspense } from "./wrappers/Suspendable";
import { TypographyWithIcon } from "./utils/TypographyWithIcon";
import FormattedDate from "./FormattedDate";
import AddQuestionnaireDialog from "./AddQuestionnaireDialog";
import { withSnackbar } from "./SnackBarComponent";

const useClasses = makeStyles(({ spacing }) => ({
  copyButton: {
    cursor: "pointer",
    textTransform: "underline !important",
    textDecoration: "underline",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  icon: {
    marginRight: spacing(1),
  },
}));

const ClientQuestionnaireOverview = ({ clientId, ...props }) => {
  const { data } = useQuestionnairesQuery(clientId);
  const { t } = useTranslation();
  const classes = useClasses();

  const [showAddQuestionnaireDialog, setShowAddQuestionnaireDialog] =
    useState(false);

  const onCloseDialog = () => {
    setShowAddQuestionnaireDialog(false);
  };

  const onShareCopyClick = (questionnaire) => {
    navigator.clipboard.writeText(questionnaire.shareLink).then(() => {
      props.snackbarShowMessage(t("Questionnaires.LinkCopyMessage"), "success");
    });
  };

  const onResultClick = (questionnaire) => {
    const win = window.open(questionnaire.resultLink, "_blank");
    win.focus();
  };

  return (
    <>
      <Paper sx={{ padding: "24px" }}>
        <TypographyWithIcon
          iconLigature="summarize"
          variant="h5"
          outlined
          rightButtonProps={{
            onClick: () => setShowAddQuestionnaireDialog(true),
          }}
        >
          {t("Questionnaires.Title")}
        </TypographyWithIcon>
        {isEmptyQueryResult(data) ? (
          <Typography variant="body1">
            {t("Questionnaires.NothingFound")}
          </Typography>
        ) : (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t("Common.CreatedDate")}</TableCell>
                  <TableCell>{t("Questionnaires.Type")}</TableCell>
                  <TableCell align="right">
                    {t("Questionnaires.Result")}
                  </TableCell>
                  <TableCell align="right">
                    {t("Questionnaires.ShareLink")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data &&
                  data
                    .sort(
                      (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
                    )
                    .map((questionnaire) => (
                      <TableRow key={questionnaire.id}>
                        <TableCell>
                          <FormattedDate date={questionnaire.createdAt} />
                        </TableCell>

                        <TableCell>{questionnaire.type}</TableCell>

                        <TableCell align="right">
                          <Typography
                            variant="body2"
                            className={classes.copyButton}
                            onClick={(e) => {
                              e.stopPropagation();
                              onResultClick(questionnaire);
                            }}
                          >
                            <Icon className={classes.icon}>open_in_new</Icon>
                            {t("Common.Open")}
                          </Typography>
                        </TableCell>

                        <TableCell align="right">
                          <Typography
                            variant="body2"
                            className={classes.copyButton}
                            onClick={(e) => {
                              e.stopPropagation();
                              onShareCopyClick(questionnaire);
                            }}
                          >
                            <Icon className={classes.icon}>content_copy</Icon>
                            {t("Common.Copy")}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Paper>
      <AddQuestionnaireDialog
        open={showAddQuestionnaireDialog}
        clientId={clientId}
        onClose={onCloseDialog}
      />
    </>
  );
};

export default withSnackbar(withSuspense(ClientQuestionnaireOverview));
