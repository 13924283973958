import { TableCell, TableRow } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { QUESTIONS_KEYS } from "@src/constants";
import { colors } from "@src/theme";
import { themeValue } from "@src/utils/formatting";
import TableCellData from "./TableCellData";

const useStyles = makeStyles((theme) => ({
  cellBorder: {
    padding: theme.spacing(2, 0),
    border: 0,
  },
  cell: {
    padding: theme.spacing(2, 0),
  },
  darkCell: {
    padding: theme.spacing(2, 0),
    color: colors.primaryDarkBlue,
  },
  blueCell: {
    padding: theme.spacing(2, 0),
    color: colors.primaryBlue,
  },
  greenCell: {
    padding: theme.spacing(2, 0),
    color: colors.primaryGreen,
  },
}));

const ClientCheckinsOverviewAllTableCell = ({
  question,
  message,
  getCorrectDate,
  index,
  data,
  indexQuestion,
  onClick,
}) => {
  const valueQuestion = Object.values(QUESTIONS_KEYS).find(
    (value) => value === question.key,
  );
  const dataTheme = data.find(
    (theme) => theme.key === themeValue(valueQuestion),
  );
  const dataThemeQuestionLists = dataTheme.questionLists;
  const secondScore = dataThemeQuestionLists
    .map(
      (question, indexQuestionScore) =>
        indexQuestionScore === indexQuestion + 1 && question.score,
    )
    .find((result) => typeof result === "number");

  const classes = useStyles();

  return (
    <>
      {indexQuestion === 0 && (
        <TableRow
          role="checkbox"
          tabIndex={-1}
          key={question.key}
          onClick={onClick}
        >
          <TableCell className={classes.cellBorder}>
            {index === 0 ? message : ""}
          </TableCell>
          <TableCellData
            question={question}
            getCorrectDate={getCorrectDate}
            secondScore={secondScore}
          />
        </TableRow>
      )}
    </>
  );
};

export default ClientCheckinsOverviewAllTableCell;
