import { Box, Breadcrumbs } from "@mui/material";
import { FC, useEffect, useMemo, useRef, useState } from "react";
import { formatConsultDateExtendedWritten } from "@src/utils/formatting";
import { Link, To, useParams } from "react-router-dom";
import { useConsultQuery, useClientConsultsQuery } from "@src/queries/consults";
import {
  EditOutlined as EditOutlinedIcon,
  WarningAmberOutlined as WarningIcon,
} from "@mui/icons-material";
import { SessionDetailsContext } from "@src/utils/contexts";
import { useThemesQuery } from "@src/queries/themes";
import {
  sessionLanguageTagName,
  sessionTypeTagName,
  topicsTagName,
  useConsultTagsQuery,
  useMultipleTagsQueries,
} from "@src/queries/tags";
import { sessionHasNotes } from "@src/utils/helpers";
import { usePsychologistsQuery } from "@src/queries/experts";
import { TAG_TYPES } from "@src/constants";
import { ConsultWithShortNotesResponse } from "@src/models/Consults/ConsultWithShortNotesResponse";
import { format, isBefore, parseISO } from "date-fns";
import { useTranslation } from "react-i18next";
import SessionNotesPaper from "./SessionNotesPaper";
import SessionDetailsPaper from "./SessionDetailsPaper";
import { SecondaryButton } from "./components/SecondaryButton";
import PastSessionsTable from "../PastSessionsTable";
import EditSessionNotesDialog from "./EditSessionNotesDialog";
import EditSessionDetailsDialog from "./EditSessionDetailsDialog";

const SessionNotesView: FC = () => {
  const { clientId, consultId } = useParams();

  const { data: allSessionThemes } = useThemesQuery();

  const { taggedData } = useMultipleTagsQueries([
    sessionTypeTagName,
    topicsTagName,
    sessionLanguageTagName,
  ]);

  const allSessionTypeTags = useMemo(
    () => taggedData[sessionTypeTagName],
    [taggedData],
  );
  const allTopicsTags = useMemo(() => taggedData[topicsTagName], [taggedData]);
  const allLanguageTags = useMemo(
    () => taggedData[sessionLanguageTagName],
    [taggedData],
  );

  const {
    data: { data: allPsychologists },
  } = usePsychologistsQuery({
    limit: 10000,
  });

  const { data: consult, refetch: refetchConsult } = useConsultQuery(
    clientId,
    consultId,
  );
  const { data: consults, refetch: refetchConsults } =
    useClientConsultsQuery(clientId);
  const { consultTags: consultTopics } = useConsultTagsQuery(
    consult.user.id,
    consult.id,
    TAG_TYPES.TOPICS,
  );

  const notesRef = useRef<HTMLElement>(null);
  const [pastSessions, setPastSessions] =
    useState(Array<ConsultWithShortNotesResponse>());
  const [isEditingNotes, setIsEditingNotes] = useState(false);
  const [isEditingDetails, setIsEditingDetails] = useState(false);

  useEffect(() => {
    const now = new Date();
    setPastSessions(
      consults.filter((c) => isBefore(parseISO(c.startedAt), now)),
    );
  }, [consults]);
  const { t } = useTranslation();
  const refetchData = () => {
    refetchConsult();
    refetchConsults();
  };

  useEffect(() => {
    if (notesRef.current) {
      notesRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [consultId]);

  const breadcrumb = (text: string, url: To) => {
    return (
      <Link underline="hover" color="inherit" to={url}>
        {text}
      </Link>
    );
  };

  if (!allTopicsTags?.tags) {
    return null;
  }

  return (
    <SessionDetailsContext.Provider
      value={{
        allConsults: consults,
        allSessionThemes,
        allSessionTypeTags,
        allLanguageTags,
        allPsychologists,
        allTopicsTags: allTopicsTags.tags,
      }}
    >
      <Box className="ml-3" ref={notesRef}>
        {consult && (
          <Box>
            <Breadcrumbs separator="<">
              {breadcrumb("", "/clients")},{breadcrumb("Clients", "/clients")},
              {breadcrumb(
                `${consult.user.lastName}, ${consult.user.firstName}`,
                `/clients/${consult.user.id}`,
              )}
              ,
              {breadcrumb(
                `Session ${format(parseISO(consult.startedAt), "dd-MM-yy")}`,
                `/clients/${consult.user.id}/session-notes/${consult.id}`,
              )}
            </Breadcrumbs>
            <p className="mb-12 h1">
              {formatConsultDateExtendedWritten(consult)}
            </p>
            {consult.convertedToMarkDown && (
              <div className="flex items-center p-4 mb-4 rounded-lg bg-yellow-100 text-yellow-700">
                <WarningIcon className="w-6 h-6 mr-3 fill-orange-500" />
                <div className="flex-grow whitespace-pre-line">
                  {t("SessionNotesView.NotesConverted")}
                </div>
              </div>
            )}
            <Box className="flex flex-row w-full">
              <Box className="mr-3 grow">
                <SessionNotesPaper
                  consult={consult}
                  openNotesDialog={() => setIsEditingNotes(true)}
                />
                {sessionHasNotes(consult) && (
                  <SecondaryButton
                    additionalStylesButtonContainer="mt-2 mb-6 ml-0 px-6"
                    value="Edit Notes"
                    action={() => setIsEditingNotes(true)}
                    icon={<EditOutlinedIcon className="mr-1" />}
                  />
                )}
              </Box>
              <Box>
                <SessionDetailsPaper consult={consult} topics={consultTopics} />
                <SecondaryButton
                  additionalStylesButtonContainer="mt-2 mb-6 ml-0 px-6"
                  value="Edit Details"
                  action={() => setIsEditingDetails(true)}
                  icon={<EditOutlinedIcon className="mr-1" />}
                />
              </Box>
            </Box>
            <Box className="w-full">
              <PastSessionsTable
                clientId={clientId!}
                consults={pastSessions}
                canAddSession={false}
              />
            </Box>
          </Box>
        )}
      </Box>
      <EditSessionDetailsDialog
        isOpen={isEditingDetails}
        clientId={consult.user.id}
        consult={consult}
        onClose={() => setIsEditingDetails(false)}
        refetch={refetchData}
      />

      <EditSessionNotesDialog
        isOpen={isEditingNotes}
        consult={consult}
        onClose={() => setIsEditingNotes(false)}
        refetch={refetchData}
      />
    </SessionDetailsContext.Provider>
  );
};

export default SessionNotesView;
