import { LANGUAGE_TYPES, THEME_KEY } from "@src/constants";
import i18next from "i18next";

export const LanguageCodeDutchNl = "nl-NL";
export const LanguageCodeEnglishUs = "en-GB";
export const LanguageCodeGermanDe = "de-DE";
export const LanguageCodeFrenchFr = "fr-FR";

const translateDutch = i18next.getFixedT("nl");
const translateEnglish = i18next.getFixedT("en");
const translateGerman = i18next.getFixedT("de");

/**
 * Returns translated language name based on 4 letter [languageKey]
 * @param {method} t - translation function provided by useTranslation()
 * @param {string} languageCode - 4 letter language code
 * @return {string} Translated language name
 */
export const getLanguageNameBasedOnAbbreviation = (t, languageCode) => {
  if (!t) {
    return `SessionLanguage.${languageCode}`;
  }
  if (Object.values(LANGUAGE_TYPES).includes(languageCode)) {
    return t(`SessionLanguage.${languageCode}`);
  }
  return t("SessionLanguage.en-GB");
};

/**
 * Returns translated theme name based on theme key
 * @param {method} t - translation function provided by useTranslation()
 * @param {string} themeKey - theme key
 * @return {string} Translated theme name
 */
export const getTranslatedThemeName = (t, themeKey) => {
  if (!t || !themeKey) {
    return "";
  }
  if (Object.values(THEME_KEY).includes(themeKey)) {
    return t(`Themes.${themeKey}.Name`);
  }
  return "";
};

/**
 * Returns translated topic name based on topic key
 * @param {method} t - translation function provided by useTranslation()
 * @param {string} topicKey - topic key
 * @return {string} Translated topic name
 */
export const getTranslatedTopicName = (t, topicKey) => {
  if (!t || !topicKey) return "";

  return t(`Topics.${topicKey}.Name`);
};

/**
 * Returns a forced translation in a specific language that can be different from the application language
 * @param {string} lokaliseKey - Lokalise key string
 * @param {string} languageCode - Language code of the session
 * @return {string} Translated lokalise key value
 */
export const getTranslatedKey = (lokaliseKey, languageCode) => {
  if (languageCode === LanguageCodeDutchNl) {
    return translateDutch(lokaliseKey);
  }
  if (languageCode === LanguageCodeGermanDe) {
    return translateGerman(lokaliseKey);
  }
  return translateEnglish(lokaliseKey);
};
