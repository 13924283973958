import { useEffect, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { formatUserName } from "@src/utils/formatting";
import { sortPsychologistsByLastNameThenFirstName } from "@src/utils/psychologistHelpers";
import { usePsychologistsQuery } from "@src/queries/experts";
import { withSuspense } from "../wrappers/Suspendable";

const PsychologistsAutocomplete = ({
  onChange,
  value,
  error,
  required,
  textFieldStyle,
}) => {
  const { t } = useTranslation();
  const {
    data: { data: allPsychologists },
  } = usePsychologistsQuery({
    limit: 10000,
  });
  const [sortedPsychologists, setSortedPsychologists] = useState([]);

  useEffect(() => {
    setSortedPsychologists(
      sortPsychologistsByLastNameThenFirstName(allPsychologists),
    );
  }, [allPsychologists]);
  return (
    <Autocomplete
      data-cy="psychologist-autocomplete"
      options={sortedPsychologists}
      getOptionLabel={(user) => formatUserName(user, false) || ""}
      onChange={(_, newValue) => onChange(newValue)}
      value={
        allPsychologists.find((psychologist) => psychologist.id === value) || ""
      }
      renderInput={(params) => (
        <TextField
          className={textFieldStyle}
          style={{ padding: "0px" }}
          {...params}
          label={t("Common.Psychologist")}
          required={required}
          error={!!error}
          variant="standard"
        />
      )}
    />
  );
};

export default withSuspense(PsychologistsAutocomplete);
