import { apiPathsWithParams, pageSize } from "@src/constants";
import axios from "axios";
import { useQuery } from "react-query";
import { PaginatedResult } from "@src/models/PaginatedResult";
import { fiveMinutes } from "@src/queries/staletimes";
import { ClientSpace } from "@src/models/ClientSpace";

export const useGetClientSpacesQuery = (
  clientId: string,
  page: number = 0,
  limit: number = pageSize,
) =>
  useQuery<PaginatedResult<ClientSpace>>({
    queryKey: [apiPathsWithParams.getClientSpaces(clientId), page, limit],
    queryFn: async (): Promise<PaginatedResult<ClientSpace>> => {
      const { data } = await axios.get<PaginatedResult<ClientSpace>>(
        `${apiPathsWithParams.getClientSpaces(
          clientId,
        )}?Page=${page}&Limit=${limit}`,
      );

      return data;
    },
    staleTime: fiveMinutes,
  });
