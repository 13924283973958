/* eslint-disable react/prop-types */
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { Breadcrumbs } from "@src/components/Breadcrumbs";
import ClientCheckinsOverview from "@src/components/ClientCheckinsOverview";
import ClientDetails from "@src/components/ClientDetails";
import ClientDossierNote from "@src/components/ClientDossierNote";
import ClientEmotionsOverview from "@src/components/ClientEmotionsOverview";
import ClientQuestionnaireOverview from "@src/components/ClientQuestionnaireOverview";
import PageTitle from "@src/components/PageTitle";
import { withSuspense } from "@src/components/wrappers/Suspendable";
import { useClientQuery } from "@src/queries/clients";
import { useClientConsultsQuery } from "@src/queries/consults";
import { usePsychologistsQuery } from "@src/queries/experts";
import {
  sessionLanguageTagName,
  sessionTypeTagName,
  topicsTagName,
  useMultipleTagsQueries,
} from "@src/queries/tags";
import { useThemesQuery } from "@src/queries/themes";
import authenticationService from "@src/services/authentication.service";
import { SessionDetailsContext } from "@src/utils/contexts";
import ClientSessionsSection from "@src/views/ClientPage/ClientSessionsSection";
import EmployerReferralToolkitBanner from "@src/views/employer/EmployerReferralToolkitBanner";

const useStyles = makeStyles(({ spacing }) => ({
  backButton: {
    opacity: 0.7,
    marginBottom: spacing(3),
    letterSpacing: 0.1,
  },
  link: {
    textDecoration: "none",
  },
}));

const ClientDetailView = () => {
  const classes = useStyles();
  const { clientId } = useParams();
  const { t } = useTranslation();

  const { data: allSessionThemes } = useThemesQuery();
  const { data: consults, refetch } = useClientConsultsQuery(clientId);

  const { taggedData } = useMultipleTagsQueries([
    sessionTypeTagName,
    topicsTagName,
    sessionLanguageTagName,
  ]);
  const allSessionTypeTags = taggedData[sessionTypeTagName];
  const allTopicsTags = taggedData[topicsTagName];
  const allLanguageTags = taggedData[sessionLanguageTagName];

  const {
    data: { data: allPsychologists },
  } = usePsychologistsQuery({
    limit: 10000,
  });

  const { isManagerOrAdmin } = authenticationService;
  const { data: client } = useClientQuery(clientId);
  const hasFullAccess =
    isManagerOrAdmin() || client.isInRelationWithCurrentPsychologist === true;

  return (
    <SessionDetailsContext.Provider
      value={{
        allSessionThemes,
        allSessionTypeTags,
        allLanguageTags,
        allPsychologists,
        allTopicsTags,
        allConsults: consults,
        refetchConsults: refetch,
      }}
    >
      <PageTitle title="Client" />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Breadcrumbs
            crumbs={[{ link: "/clients", text: t("MyClients.Pagetitle") }]}
          />
        </Grid>
        <Grid item xs={9}>
          <Grid container spacing={2}>
            {client?.employerId && !client?.isPartner && (
              <Grid item xs={12}>
                <EmployerReferralToolkitBanner
                  employerId={client?.employerId}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <ClientSessionsSection
                clientId={clientId}
                clientName={client?.firstName}
                canAddSession={hasFullAccess}
                viewNotesColumn={hasFullAccess}
                canJoinSession={hasFullAccess}
              />
            </Grid>
            {hasFullAccess && (
              <>
                <Grid item xs={12}>
                  <ClientEmotionsOverview clientId={clientId} />
                </Grid>
                <Grid item xs={12}>
                  <ClientCheckinsOverview clientId={clientId} />
                </Grid>
                <Grid item xs={12}>
                  <ClientQuestionnaireOverview clientId={clientId} />
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <ClientDetails
            clientId={clientId}
            className={classes.details}
            canEditClient={hasFullAccess}
          />
          {hasFullAccess && (
            <Grid
              item
              xs={12}
              sx={{ marginTop: "24px" }}
              data-cy="client-notes-section"
            >
              <ClientDossierNote clientId={clientId} />
            </Grid>
          )}
        </Grid>
      </Grid>
    </SessionDetailsContext.Provider>
  );
};

export default withSuspense(ClientDetailView);
