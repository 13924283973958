import { useQuery } from "react-query";
import axios from "axios";
import { apiPaths } from "@src/constants";

export const useEmployersQuery = () =>
  useQuery([`employers`], async () => {
    const { data } = await axios.get(apiPaths.employers);
    return data;
  });

export const updateUserLanguage = (languageCode) =>
  axios.put(apiPaths.updateLanguage, { languageCode });

export default useEmployersQuery;
