import { useMediaQuery, Container, Box, Typography } from "@mui/material";

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  titleBoxExpanded: ({ message }) => {
    return {
      textAlign: "center",
      marginBottom: message ? theme.spacing(2) : theme.spacing(4),
      [theme.breakpoints.up("md")]: {
        marginTop: theme.spacing(4),
      },
      [theme.breakpoints.down("md")]: {
        marginTop: theme.spacing(1.5),
      },
      marginLeft: -theme.spacing(12),
      marginRight: -theme.spacing(12),
    };
  },
  titleBox: {
    textAlign: "center",
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(4),
    },
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(1.5),
    },
  },
  titleText: {
    display: "flex",
    justifyContent: "center",
  },
  messageBox: {
    [theme.breakpoints.up("md")]: {
      marginBottom: theme.spacing(6),
    },
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(4),
    },
    textAlign: "center",
  },
  messageText: {
    display: "flex",
    justifyContent: "center",
  },
}));

const BookingContainer = ({
  title,
  children,
  isMediumContainerWanted = false,
  message,
}) => {
  const isSmallScreen = useMediaQuery((x) => x.breakpoints.down("sm"));
  const classes = useStyles({ message });

  return (
    <Container maxWidth={isMediumContainerWanted ? "md" : "sm"}>
      <Container maxWidth="sm">
        {title && (
          <Box
            mb={1}
            className={
              !isSmallScreen ? classes.titleBoxExpanded : classes.titleBox
            }
          >
            <Typography
              variant={isSmallScreen ? "h2" : "h1"}
              className={classes.titleText}
            >
              {title}
            </Typography>
          </Box>
        )}
        {message && (
          <Box mb={1} className={classes.messageBox}>
            <Typography
              variant={isSmallScreen ? "h4" : "h3"}
              className={classes.messageText}
            >
              {message}
            </Typography>
          </Box>
        )}
      </Container>
      {children}
    </Container>
  );
};

export default BookingContainer;
