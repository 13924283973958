import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
} from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import { makeStyles } from "@mui/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import { Outlet, useLocation } from "react-router";

import NotificationDialog from "@src/components/NotificationDialog";
import Suspendable from "@src/components/wrappers/Suspendable";
import { colors } from "@src/theme";
import Drawer from "./Drawer";
import AppBar from "./AppBar";
import FooterBar from "./FooterBar";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
    },

    content: ({ backgroundColor = colors.primarySkin }) => ({
      flexGrow: 1,
      padding: theme.spacing(3),
      paddingTop: theme.spacing(0),
      backgroundColor,
      minHeight: "100vh",
    }),
    title: {
      color: "white",
    },
    toolbar: {
      minHeight: 96,
    },
  }),
);

const createMainLayoutTheme = (theme) =>
  createTheme({
    ...theme,
    props: {
      ...theme.props,
    },
    overrides: {
      ...theme.overrides,
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundColor: "white",
            padding: "32px 24px",
          },
          rounded: {
            borderRadius: "16px",
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          paper: {
            padding: 0,
            borderRadius: 0,
          },
        },
      },
    },
  });

const contentColors = {
  "/": {
    backgroundColor: colors.primaryGreen,
  },
  "/profile": {
    backgroundColor: colors.primaryGreen,
  },
};

export default function AdminDashboardLayout() {
  const { pathname } = useLocation();
  const colors = contentColors[pathname] || {};
  const classes = useStyles(colors);

  return (
    <div>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar />
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={createMainLayoutTheme}>
            <Suspendable>
              <Drawer />
            </Suspendable>
            <main className={classes.content}>
              <NotificationDialog />
              <Toolbar className={classes.toolbar} />
              <Suspendable>
                <Outlet />
              </Suspendable>
            </main>
          </ThemeProvider>
        </StyledEngineProvider>
      </div>
      <FooterBar />
    </div>
  );
}
