import { isArray } from "lodash";

export const sortPsychologistsByLastNameThenFirstName = (psychologists) => {
  if (isArray(psychologists))
    psychologists.sort((psychologistA, psychologistB) => {
      const lowerCaseLastNameA = psychologistA.lastName
        ? psychologistA.lastName.toLowerCase()
        : "";
      const lowerCaseLastNameB = psychologistB.lastName
        ? psychologistB.lastName.toLowerCase()
        : "";
      const lowerCaseFirstNameA = psychologistA.firstName
        ? psychologistA.firstName.toLowerCase()
        : "";
      const lowerCaseFirstNameB = psychologistB.firstName
        ? psychologistB.firstName.toLowerCase()
        : "";

      if (lowerCaseLastNameA > lowerCaseLastNameB) return 1;
      if (lowerCaseLastNameA < lowerCaseLastNameB) return -1;

      if (lowerCaseFirstNameA > lowerCaseFirstNameB) return 1;
      if (lowerCaseFirstNameA < lowerCaseFirstNameB) return -1;

      return 0;
    });

  return psychologists;
};
