import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import Suspendable from "./components/wrappers/Suspendable";
import App from "./App";
import "./i18n";

import "@openup/shared/tailwind.css";

const root = createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <BrowserRouter>
    <Suspendable>
      <App />
    </Suspendable>
  </BrowserRouter>,
);
