import { useTranslation } from "react-i18next";

const FormattedDate = ({ date }) => {
  const {
    i18n: { language },
  } = useTranslation();
  const dateObject = new Date(date);
  const formatted = dateObject.toLocaleDateString(language);

  return formatted;
};

export default FormattedDate;
