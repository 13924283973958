import { enGB, nl } from "date-fns/locale";
import i18n from "./i18n";

const DateFnslocaleMapper = () => {
  switch (i18n.language) {
    case "nl":
    case "nl-NL":
      return nl;
    case "en":
    case "en-GB":
      return enGB;
    default:
      return nl;
  }
};

export { DateFnslocaleMapper };
