import { Dialog } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { usePsychologistMutation } from "@src/queries/experts";
import { DialogActionButtons, DialogHeader } from "./DialogToolkit";
import PsychologistsAddEmployers from "./PsychologistsAddEmployers";
import { withSnackbar } from "./SnackBarComponent";

const useStyles = makeStyles({
  form: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  formItem: {
    flexBasis: "calc(50% - 12px)",
    height: 72,
  },
});

const AddPsychologistDialog = ({ client = {}, isEdit = false, ...props }) => {
  const { t } = useTranslation();
  const { mutateAsync: addPsychologist } = usePsychologistMutation();
  const classes = useStyles();

  const formik = useFormik({
    initialValues: {
      userId: client.id ?? "",
    },
    onSubmit: async (values) => {
      try {
        formik.setSubmitting(true);
        await addPsychologist(values.userId);

        props.onClose();
      } catch (e) {
        if (e.response.data.errors === undefined) {
          throw e;
        }

        for (const [key, value] of Object.entries(e.response.data.errors)) {
          props.snackbarShowMessage(`${key}: ${value}`);
        }

        formik.setSubmitting(false);
      }
    },
    enableReinitialize: true,
  });

  return (
    <Dialog
      {...props}
      onClose={() => {
        formik.handleReset();
        props.onClose();
      }}
    >
      <DialogHeader
        iconLigature="person_add"
        title={t("Psychologist.Add")}
        outlined
      />

      <form onSubmit={formik.handleSubmit} className={classes.form} noValidate>
        <PsychologistsAddEmployers
          onChange={(user) => {
            formik.setFieldValue("userId", user.id);
          }}
        />
      </form>
      <DialogActionButtons
        onCancel={() => {
          formik.handleReset();
          props.onClose();
        }}
        onSubmit={formik.submitForm}
      />
    </Dialog>
  );
};

export default withSnackbar(AddPsychologistDialog);
