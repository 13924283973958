import { useState, useContext, useMemo } from "react";
import {
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Autocomplete from "@mui/material/Autocomplete";
import { useTranslation } from "react-i18next";
import {
  getTranslatedThemeName,
  getTranslatedKey,
  getTranslatedTopicName,
} from "@src/utils/languageHelpers";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { SessionDetailsContext } from "@src/utils/contexts";
import {
  getExternalHealthProviders,
  getNextStepsForSessionNotes,
} from "@src/utils/constants";
import Loader from "@src/components/Loader";
import { isIntroductorySession } from "@src/utils/helpers";
import { HELP_NEEDED_VALUES } from "@src/constants";
import AdviceBox from "@src/components/AdviceBox";
import { formatFirstUpperCase } from "@src/utils/formatting";
import { colors } from "@src/theme";
import DefaultMarkdownEditor from "@src/components/DefaultMarkdownEditor";
import { Consult } from "@src/models/Consult";
import { FormikProps } from "formik";
import CancelWhileEditModal from "./components/CancelWhileEditModal";

const hrStyle = "my-1 mx-3 border-t border-slate-300";
const headerStyle = "sub1 px-3 py-2";

const useStyles = makeStyles(({ spacing }) => ({
  multiLineInput: {
    width: "100%",
    marginTop: 0,
    padding: spacing(0, 3, 2),
  },
  autocomplete: {
    width: "100%",
    padding: spacing(0, 3, 2),
  },
  radioGroupBig: {
    padding: spacing(0, 3, 2),
  },
  radioSubGroup: {
    marginLeft: spacing(6),
  },
  rootRadio: {
    alignItems: "flex-start",
  },
  rootLabel: {
    marginTop: spacing(0.5),
  },
  otherText: {
    width: "65%",
    marginLeft: spacing(10),
  },
  loaderContainer: {
    margin: spacing(6, 0, 6),
  },
}));

interface NotesFormValues {
  themeKey: string;
  topics: { name: string }[];
  reasonForBooking: string;
  discussedInSession: string;
  canWeHelp: string;
  referredToDifferentHealthProvider: string;
  healthProviderName: string;
  nextSteps: string;
}
interface SessionNotesEditingProps {
  consult: Consult;
  formikNotes: FormikProps<NotesFormValues>;
  onClose: () => void;
}

const SessionNotesEditing: React.FC<SessionNotesEditingProps> = ({
  consult,
  formikNotes,
  onClose,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isCancelEditingDialog, setIsCancelEditingDialog] = useState(false);
  const { allConsults, allSessionThemes, allTopicsTags } = useContext(
    SessionDetailsContext,
  );

  // placeholder text, so that language of the placeholder is dependent on the consult language instead of dashboard
  const customLanguagePlaceholder = ({
    value,
    lokaliseKeyIntroductory,
    lokaliseKeyFollowUp,
  }) => {
    if (value) {
      return value;
    }

    if (isIntroductorySession(allConsults, consult)) {
      return getTranslatedKey(lokaliseKeyIntroductory, consult.consultLanguage);
    }

    return getTranslatedKey(lokaliseKeyFollowUp, consult.consultLanguage);
  };

  // for use within CancelWhileEditModal
  // if the user cancels without saving, reset the form and turn off editing
  const handleCancelWithoutSaving = () => {
    setIsCancelEditingDialog(false);
    formikNotes.resetForm();
    onClose();
  };

  const selectedSessions = useMemo(
    () =>
      allSessionThemes.find(
        (theme) => theme.key === formikNotes.values.themeKey,
      ) || null,
    [allSessionThemes, formikNotes.values.themeKey],
  );

  const selectedTopicsTags = useMemo(
    () =>
      allTopicsTags.filter((topic) =>
        formikNotes.values.topics.some(
          (selectedTopic) => selectedTopic.name === topic.name,
        ),
      ),
    [allTopicsTags, formikNotes.values.topics],
  );

  return (
    <>
      {formikNotes.isSubmitting ? (
        <div className={classes.loaderContainer}>
          <Loader />
        </div>
      ) : (
        <>
          <p className={headerStyle}>
            1. {t("Consults.SessionDetails.ThemeOfSession")} *
          </p>
          <Autocomplete
            data-cy="session-theme"
            id="session-themes"
            options={allSessionThemes.filter((theme) =>
              theme.tags.some((tag) => tag.name === consult.consultType),
            )}
            getOptionLabel={(option) => getTranslatedThemeName(t, option.key)}
            onChange={(_, newValue) => {
              formikNotes.setFieldValue("themeKey", newValue?.key);
            }}
            value={selectedSessions}
            className={classes.autocomplete}
            // eslint-disable-next-line react/jsx-props-no-spreading
            renderInput={(params) => (
              <TextField variant="standard" {...params} />
            )}
          />
          <hr className={hrStyle} />
          <p className={headerStyle}>
            2. {t("Consults.SessionDetails.TopicsOfSession")}
          </p>
          <Autocomplete
            data-cy="session-topics"
            id="session-topics"
            disableCloseOnSelect
            multiple
            options={allTopicsTags}
            getOptionLabel={(option) => getTranslatedTopicName(t, option.name)}
            value={selectedTopicsTags}
            className={classes.autocomplete}
            onChange={(_event, value, reason) => {
              if (
                reason === "selectOption" ||
                reason === "removeOption" ||
                reason === "clear"
              ) {
                formikNotes.setFieldValue("topics", value);
              }
            }}
            renderOption={(props, { name }, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon />}
                  checkedIcon={<CheckBoxIcon />}
                  style={{ color: colors.primaryDarkBlue }}
                  checked={selected}
                />
                {getTranslatedTopicName(t, name)}
              </li>
            )}
            renderTags={(options) =>
              options.map((option) => (
                <p key={option.name}>
                  {getTranslatedTopicName(t, option.name)},{" "}
                </p>
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("Consults.SessionDetails.SelectTopics")}
              />
            )}
          />
          <hr className={hrStyle} />
          <p className={headerStyle}>
            3. {t("Consults.SessionDetails.ReasonForSession")} *
          </p>
          <div
            className={classes.multiLineInput}
            id="reason-for-session"
            data-cy="reason-for-session"
          >
            <DefaultMarkdownEditor
              markdown={customLanguagePlaceholder({
                value: formikNotes.values.reasonForBooking,
                lokaliseKeyIntroductory: `Consults.SessionDetails.ReasonForSession.${formatFirstUpperCase(
                  consult.consultType,
                )}.IntroductoryTemplate`,
                lokaliseKeyFollowUp: `Consults.SessionDetails.ReasonForSession.${formatFirstUpperCase(
                  consult.consultType,
                )}.FollowUpTemplate`,
              })}
              onChange={(content) => {
                formikNotes.setFieldValue("reasonForBooking", content || "");
              }}
            />
          </div>
          <AdviceBox
            texts={[
              t("Consults.SessionDetails.ReasonForSessionTip1"),
              t("Consults.SessionDetails.ReasonForSessionTip2"),
            ]}
          />
          <hr className={hrStyle} />
          <p className={headerStyle}>
            4. {t("Consults.SessionDetails.SessionDiscussed")} *
          </p>
          <div
            className={classes.multiLineInput}
            id="discussed-in-session"
            data-cy="discussed-in-session"
          >
            <DefaultMarkdownEditor
              markdown={customLanguagePlaceholder({
                value: formikNotes.values.discussedInSession,
                lokaliseKeyIntroductory: `Consults.SessionDetails.SessionDiscussed.${formatFirstUpperCase(
                  consult.consultType,
                )}.IntroductoryTemplate`,
                lokaliseKeyFollowUp: `Consults.SessionDetails.SessionDiscussed.${formatFirstUpperCase(
                  consult.consultType,
                )}.FollowUpTemplate`,
              })}
              onChange={(content) => {
                formikNotes.setFieldValue("discussedInSession", content || "");
              }}
            />
          </div>
          <AdviceBox
            texts={[
              t("Consults.SessionDetails.SessionDiscussedTip1"),
              t("Consults.SessionDetails.SessionDiscussedTip2"),
            ]}
          />
          <hr className={hrStyle} />
          <p className={headerStyle}>
            5. {t("Consults.SessionDetails.HelpClient")}
          </p>
          <RadioGroup
            data-cy="can-we-help"
            className={classes.radioGroupBig}
            value={formikNotes.values.canWeHelp}
            onChange={formikNotes.handleChange}
            name="canWeHelp"
          >
            <FormControlLabel
              value={HELP_NEEDED_VALUES.TRUE}
              classes={{ root: classes.rootRadio, label: classes.rootLabel }}
              control={<Radio color="primary" />}
              label={t("Consults.SessionDetails.HelpClient.Yes")}
            />
            <FormControlLabel
              value={HELP_NEEDED_VALUES.NO_REFER_TO_IPRACTICE}
              classes={{ root: classes.rootRadio, label: classes.rootLabel }}
              control={<Radio color="primary" />}
              label={t("Consults.SessionDetails.HelpClient.noReferToiPractice")}
            />
            <FormControlLabel
              value={HELP_NEEDED_VALUES.NO_REFER_TO_OTHER_PROVIDER}
              classes={{ root: classes.rootRadio, label: classes.rootLabel }}
              control={<Radio color="primary" />}
              label={t(
                "Consults.SessionDetails.HelpClient.noReferToOtherProvider",
              )}
            />
            {/* only show the following radio group when the user is to be referred to another health provider */}
            {formikNotes.values.canWeHelp ===
              HELP_NEEDED_VALUES.NO_REFER_TO_OTHER_PROVIDER && (
              <RadioGroup
                className={classes.radioSubGroup}
                value={formikNotes.values.referredToDifferentHealthProvider}
                onChange={formikNotes.handleChange}
                name="referredToDifferentHealthProvider"
              >
                {getExternalHealthProviders(t).map((provider) => (
                  <FormControlLabel
                    key={provider.value}
                    value={provider.value}
                    control={<Radio color="primary" />}
                    label={provider.label}
                  />
                ))}
              </RadioGroup>
            )}
            {/* only show the following when the user is to be referred to another health provider not in our list of providers */}
            {formikNotes.values.referredToDifferentHealthProvider === "other" &&
              formikNotes.values.canWeHelp ===
                HELP_NEEDED_VALUES.NO_REFER_TO_OTHER_PROVIDER && (
                <TextField
                  variant="standard"
                  className={classes.otherText}
                  label={t(
                    "Consults.SessionDetails.HelpClient.AnotherProvider",
                  )}
                  onChange={(event) => {
                    formikNotes.setFieldValue(
                      "healthProviderName",
                      event.target.value || "",
                    );
                  }}
                  error={!!formikNotes.errors.healthProviderName}
                />
              )}
            <FormControlLabel
              value={HELP_NEEDED_VALUES.NO_CLIENT_AT_RISK}
              classes={{ root: classes.rootRadio, label: classes.rootLabel }}
              control={<Radio color="primary" />}
              label={t("Consults.SessionDetails.HelpClient.ClientAtRisk")}
            />
          </RadioGroup>
          <hr className={hrStyle} />
          <p className={headerStyle}>
            6. {t("Consults.SessionDetails.NextSteps")}
          </p>
          <p className="body2 px-3">
            {t("Consults.SessionDetails.NextSteps.WeAgreed")}
          </p>
          <RadioGroup
            data-cy="next-step"
            className={classes.radioGroupBig}
            value={formikNotes.values.nextSteps}
            onChange={formikNotes.handleChange}
            name="nextSteps"
          >
            {getNextStepsForSessionNotes(t).map((nextSession) => (
              <FormControlLabel
                key={nextSession.value}
                classes={{ root: classes.rootRadio, label: classes.rootLabel }}
                value={nextSession.value}
                control={<Radio color="primary" />}
                label={nextSession.label}
              />
            ))}
          </RadioGroup>
        </>
      )}

      <CancelWhileEditModal
        isOpen={isCancelEditingDialog}
        handleCancelWithoutSaving={handleCancelWithoutSaving}
        handleContinueEditing={() => setIsCancelEditingDialog(false)}
      />
    </>
  );
};

export default SessionNotesEditing;
