import { useNavigate, useParams } from "react-router";
import { useOutletContext } from "react-router-dom";
import { useState, useEffect } from "react";
import TimeSlotPicker from "@src/views/sessions/TimeSlot/TimeSlotPicker";
import {
  useSchedulerBookConsultWithClient,
  useSchedulerBookConsultWithClientAndPsychologist,
} from "@src/queries/scheduler";
import { TAG_TYPES } from "@src/constants";
import { useGetClosestClientConsult } from "@src/queries/consults";
import {
  usePsychologistTagsByIdQuery,
  usePsychologistTagsQuery,
} from "@src/queries/tags";
import { useTranslation } from "react-i18next";
import { useClientQuery } from "@src/queries/clients";
import SessionType from "./components/SessionType";
import Language from "./components/Language";

const BookSessionFlow = () => {
  const { step, nextStep, addStepToSkip, showSnackbarMessage } =
    useOutletContext();
  const { clientId, psychologistId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedLanguage, setSelectedLanguage] = useState();
  const [selectedSessionType, setSelectedSessionType] = useState();

  const { data: client } = useClientQuery(clientId);
  const { data: closestConsult } = useGetClosestClientConsult(clientId);
  const { data: loggedInPsychologistTags } = usePsychologistTagsQuery();
  const { data: psychologistTags } =
    usePsychologistTagsByIdQuery(psychologistId);
  const { mutateAsync: bookSession } = useSchedulerBookConsultWithClient();
  const { mutateAsync: bookSessionWithPsychologist } =
    useSchedulerBookConsultWithClientAndPsychologist();

  useEffect(() => {
    if (!closestConsult) return;

    if (!!closestConsult.consultLanguage && step === 0) {
      setSelectedLanguage(closestConsult.consultLanguage);
      addStepToSkip(0);
      nextStep();
    }
  }, [addStepToSkip, closestConsult, nextStep, step]);

  useEffect(() => {
    !client && navigate(-1);
  }, [client, navigate]);

  const handleBookSessionClick = async (selectedTime) => {
    const time = selectedTime.startTime;
    const { timezone } = selectedTime;
    const tags = [
      {
        tagName: selectedSessionType,
        tagType: TAG_TYPES.SESSION_TYPE,
      },
      {
        tagName: selectedLanguage,
        tagType: TAG_TYPES.SESSION_LANGUAGE,
      },
    ];
    if (!psychologistId) {
      bookSession({ time, timezone, tags, clientId })
        .then(() => {
          window.OpenUpMobile?.closeWebView();
          window.webkit?.messageHandlers?.OpenUpMobile?.postMessage(
            "closeWebview",
          );
          navigate(-1);
        })
        .catch(() => {
          showSnackbarMessage(t("Common.GenericErrorMessage"));
        });
    } else {
      bookSessionWithPsychologist({
        time,
        timezone,
        tags,
        clientId,
        psychologistId,
      })
        .then(() => {
          window.OpenUpMobile?.closeWebView();
          window.webkit?.messageHandlers?.OpenUpMobile?.postMessage(
            "closeWebview",
          );
          navigate(-1);
        })
        .catch(() => {
          showSnackbarMessage(t("Common.GenericErrorMessage"));
        });
    }
  };

  const selectBookingStep = () => {
    switch (step) {
      case 0:
        return (
          <Language
            setLanguage={setSelectedLanguage}
            moveToNextStep={nextStep}
            psychologistTags={
              !psychologistId ? loggedInPsychologistTags : psychologistTags
            }
            clientFirstName={client.firstName}
          />
        );
      case 1:
        return (
          <SessionType
            moveToNextStep={nextStep}
            setSessionType={setSelectedSessionType}
            psychologistTags={
              !psychologistId ? loggedInPsychologistTags : psychologistTags
            }
            consult={closestConsult}
            clientFirstName={client.firstName}
          />
        );
      case 2:
        return (
          <TimeSlotPicker
            onBookSession={handleBookSessionClick}
            clientFirstName={client.firstName}
            language={selectedLanguage}
            sessionType={selectedSessionType}
          />
        );
      default:
        return <></>;
    }
  };

  return <>{!!client && selectBookingStep()}</>;
};

export default BookSessionFlow;
