import {
  Dialog,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useEffect } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { useQuestionnaireMutation } from "@src/queries/questionnaires";
import { AVAILABLE_QUESTIONNAIRES } from "@src/constants";
import { DialogActionButtons, DialogHeader } from "./DialogToolkit";
import { withSnackbar } from "./SnackBarComponent";

const useStyles = makeStyles(({ spacing }) => ({
  form: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  formItem: {
    flexBasis: "calc(50% - 12px)",
    height: 72,
  },
}));

const AddQuestionnaireDialog = ({ clientId, questionnaire, ...props }) => {
  const isEdit = !!questionnaire;
  const { t } = useTranslation();
  const classes = useStyles();
  const { mutateAsync: createQuestionnaire } =
    useQuestionnaireMutation(clientId);

  const validationSchema = Yup.object({
    type: Yup.string().isRequired,
  });

  const defaultValues = {
    type: AVAILABLE_QUESTIONNAIRES[0].value,
  };

  const formik = useFormik({
    enableReinitialize: isEdit,
    initialValues: {
      type: questionnaire?.type ?? defaultValues.type,
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        formik.setSubmitting(true);
        await createQuestionnaire(values);
        props.onClose();
      } catch (e) {
        props.snackbarShowMessage(e.response.data);
      } finally {
        formik.setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    if (!isEdit) {
      formik.resetForm({ values: defaultValues });
    }
  }, [isEdit]);

  return (
    <Dialog
      {...props}
      onClose={() => {
        formik.handleReset();
        props.onClose();
      }}
    >
      <DialogHeader
        iconLigature="question_answer"
        title={isEdit ? t("Questionnaires.Edit") : t("Questionnaires.Add")}
        outlined
      />

      <form
        onSubmit={formik.handleSubmit}
        className={classes.form}
        autoComplete="off"
        noValidate
      >
        <FormControl variant="standard" className={classes.formItem} required>
          <InputLabel id="type-label">{t("Questionnaires.Type")}</InputLabel>
          <Select
            variant="standard"
            name="type"
            labelId="type-label"
            id="type"
            onChange={formik.handleChange}
            value={formik.values.type}
          >
            {AVAILABLE_QUESTIONNAIRES.map(({ label, value }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </form>
      <DialogActionButtons
        onCancel={() => {
          formik.handleReset();
          props.onClose();
        }}
        onSubmit={formik.submitForm}
      />
    </Dialog>
  );
};

export default withSnackbar(AddQuestionnaireDialog);
