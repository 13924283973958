import { Dialog, Typography, Divider } from "@mui/material";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { SecondaryButton } from "./SecondaryButton";
import { PrimaryButton } from "./PrimaryButton";
import Header from "./Header";
import Footer from "./Footer";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    position: "absolute",
    padding: 0,
  },
  headerContainer: {
    padding: theme.spacing(2, 3),
  },
  headerText: {
    fontWeight: 500,
    fontSize: "16px",
  },
  bodyText: {
    padding: theme.spacing(4, 3),
  },
}));

/**
 * Modal for alerting the user if they cancel with unsaved changes
 */
const CancelWhileEditModal = ({
  isOpen,
  handleCancelWithoutSaving,
  handleContinueEditing,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Dialog open={isOpen} classes={{ paper: classes.dialogPaper }}>
      <Header
        headerStyle={classes.headerContainer}
        leftChildren={
          <Typography className={classes.headerText}>
            {t("SessionNotes.UnsavedChangesWarningHeader")}
          </Typography>
        }
      />
      <Divider style={{ width: "100%" }} />
      <Typography variant="body1" className={classes.bodyText}>
        {t("SessionNotes.UnsavedChangesWarningBody")}
      </Typography>
      <Divider style={{ width: "100%" }} />

      <Footer
        footerStyle={classes.footerContainer}
        leftChildren={
          <SecondaryButton
            value={t("SessionNotes.UnsavedChangesGoBack")}
            action={handleContinueEditing}
          />
        }
        rightChildren={
          <PrimaryButton
            value={t("SessionNotes.UnsavedChangesDiscard")}
            action={handleCancelWithoutSaving}
          />
        }
      />
    </Dialog>
  );
};

CancelWhileEditModal.propTypes = {
  isOpen: PropTypes.bool,
  handleCancelWithoutSaving: PropTypes.func,
  handleContinueEditing: PropTypes.func,
};
CancelWhileEditModal.defaultProps = {
  isOpen: false,
  handleCancelWithoutSaving: () => {},
  handleContinueEditing: () => {},
};

export default CancelWhileEditModal;
