import { useOutletContext } from "react-router-dom";
import { RESPONSE_STATUS } from "@src/constants";
import { useNavigate, useParams } from "react-router";
import { useRescheduleSession } from "@src/queries/scheduler";
import { useState } from "react";
import { usePsychologistsConsultQuery } from "@src/queries/experts";
import { useTranslation } from "react-i18next";
import TimeSlotPicker from "./TimeSlot/TimeSlotPicker";

const RescheduleSessionFlow = () => {
  const { showSnackbarMessage } = useOutletContext();
  const { consultId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [reasonReschedule, setReasonReschedule] = useState("");

  const { data: consult } = usePsychologistsConsultQuery(consultId);
  const { mutateAsync: rescheduleSession } = useRescheduleSession();

  const handleBookSessionClick = (selectedTime) => {
    const consultId = consult.id;
    const { startTime } = selectedTime;
    const { endTime } = selectedTime;
    const { timezone } = selectedTime;
    rescheduleSession({
      consultId,
      startTime,
      endTime,
      reasonReschedule,
      timezone,
    })
      .then((response) => {
        if (response.status === RESPONSE_STATUS.OK) {
          showSnackbarMessage(t("Reschedule.Result.Success"));
          navigate(-1);
        } else {
          showSnackbarMessage(t("Common.GenericErrorMessage"));
        }
      })
      .catch(() => {
        showSnackbarMessage(t("Common.GenericErrorMessage"));
      });
  };

  return (
    <>
      {!!consult && (
        <TimeSlotPicker
          onBookSession={handleBookSessionClick}
          sessionType={consult.consultType}
          language={consult.consultLanguage}
          consult={consult}
          setReasonReschedule={setReasonReschedule}
        />
      )}
    </>
  );
};

export default RescheduleSessionFlow;
