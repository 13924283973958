import { useQueryClient } from "react-query";
import {
  FC,
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { getCurrentUser } from "@src/services/authentication.service";
import { getUserLanguage } from "@src/i18n";
import { updateUserLanguage } from "@src/queries/common";

const LanguageContext = createContext({
  currentCulture: "",
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setCurrentCulture: () => {},
});

interface LanguageProviderProps {
  children: ReactNode;
}

const LanguageProvider: FC<LanguageProviderProps> = ({ children }) => {
  const [contextCulture, setContextCulture] = useState(getUserLanguage());
  const queryClient = useQueryClient();
  const { i18n: i18nInstance } = useTranslation();

  const values = useMemo(
    () => ({
      currentCulture: contextCulture,
      setCurrentCulture: (culture: string) => {
        setContextCulture(culture);
      },
    }),
    [contextCulture],
  );

  useEffect(() => {
    // Persist to local storage
    sessionStorage.setItem("language", contextCulture);
    const updateLanguage = async () => {
      if (contextCulture === i18nInstance.language) return;
      try {
        await i18nInstance.changeLanguage(contextCulture);
        await queryClient.invalidateQueries();
        if (getCurrentUser()) await updateUserLanguage(contextCulture);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    };
    updateLanguage();
  }, [
    contextCulture,
    updateUserLanguage,
    i18nInstance,
    queryClient,
    getCurrentUser,
  ]);

  return (
    <LanguageContext.Provider value={values}>
      {children}
    </LanguageContext.Provider>
  );
};
export const useLanguage = () => useContext(LanguageContext);
export default LanguageProvider;
