import axios from "axios";
import { apiPaths } from "@src/constants";

const formatName = (user) =>
  user.lastName ? `${user.firstName} ${user.lastName}` : user.firstName;

export const createChat = async (user) => {
  const response = await axios.post(apiPaths.createChat, {
    name: formatName(user),
    is_distinct: true,
    is_public: false,
    is_super: false,
    is_ephemeral: false,
    is_chat_enabled: true,
    client_ids: [user.id],
  });

  return response.data;
};
const service = {
  createChat,
};

export default service;
