import { Typography, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useTranslation } from "react-i18next";
import BookingCard from "./BookingCard";

const useStyles = makeStyles((theme) => ({
  messageText: {
    opacity: 1,
    maxWidth: 240,
    height: 24,
  },
  arrow: {
    fontSize: 20,
    color: theme.colors.primaryBlue,
  },
  image: ({ isSmallScreen }) => ({
    display: "flex",
    height: 80,
    width: 80,
    borderRadius: theme.spacing(3),
    borderBottomRightRadius: 0,
    marginTop: isSmallScreen ? theme.spacing(3) : theme.spacing(0),
  }),
}));

const LanguageCard = ({ languageCode }) => {
  const isSmallScreen = useMediaQuery((x) => x.breakpoints.down("sm"));
  const { t } = useTranslation();
  const classes = useStyles({ isSmallScreen });
  const [src, setSrc] = useState(null);

  useEffect(() => {
    const importIcon = async () => {
      try {
        const flagUrl = `/resources/schedulerFlags/${languageCode}.png`;
        setSrc(flagUrl);
      } catch (err) {
        setSrc(null);
      }
    };
    importIcon();
  }, [languageCode]);

  return (
    <BookingCard
      isLanguageFlag
      image={<img src={src} className={classes.image} alt={src} />}
      isMultipleCardsShown
    >
      <Typography variant="h5" className={classes.messageText}>
        {t(`SessionLanguage.${languageCode}`)}
      </Typography>
      <ArrowForwardIcon className={classes.arrow} />
    </BookingCard>
  );
};

export default LanguageCard;
