import { useMemo, useState } from "react";
import { Dialog, DialogContent, DialogActions, Divider } from "@mui/material";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { TypographyWithIcon } from "@src/components/utils/TypographyWithIcon";
import { withSnackbar } from "@src/components/SnackBarComponent";
import { validationSchemaSessionDetails } from "@src/utils/validationHelpers";
import { useFormik } from "formik";
import { LANGUAGE_TYPES } from "@src/constants";
import { useConsultMutation } from "@src/queries/consults";
import { getCurrentUser } from "@src/services/authentication.service";
import dayjs from "dayjs";
import CancelWhileEditModal from "./components/CancelWhileEditModal";
import SessionDetailsEditing from "./SessionDetailsEditing";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { PrimaryButton } from "./components/PrimaryButton";
import { SecondaryButton } from "./components/SecondaryButton";

const useStyles = makeStyles((theme) => ({
  dialogContainer: {},
  dialogPaper: {
    position: "absolute",
    top: "4vh",
    maxHeight: "92vh",
    width: "45vw",
    padding: 0,
  },
  sessionDetailsTitle: {
    padding: theme.spacing(3, 0, 0, 2),
  },
  dialogContentContainer: {
    padding: 0,
  },
}));

/**
 * Modal for adding or updating a new consult
 */
const EditSessionDetailsDialog = ({
  isOpen,
  clientId,
  consult,
  refetch,
  onClose,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const currentUser = getCurrentUser();

  const [isCancelEditingDialog, setIsCancelEditingDialog] = useState(false);
  const {
    updateConsult: { mutateAsync: updateConsult },
    createConsult: { mutateAsync: createConsult },
  } = useConsultMutation(clientId);

  const getCorrectStartedAt = () => {
    if (consult) return dayjs(consult.startedAt).toISOString();

    const start = dayjs().second(0);
    const remainder = start.minute() % 30;
    return start.add(-remainder, "minute").toISOString();
  };

  const startedAt = useMemo(() => getCorrectStartedAt(), []);

  const formikDetails = useFormik({
    enableReinitialize: true,
    validationSchema: validationSchemaSessionDetails,
    initialValues: {
      startedAt,
      userId: consult?.user.id || clientId,
      employeeId: consult?.employee.id || currentUser.id,
      status: consult?.status || "Completed",
      consultType: consult?.consultType || "general",
      consultLanguage: consult?.consultLanguage || LANGUAGE_TYPES.ENGLISH,
      employeeName: consult?.employeeFullName || "",
      clientTimezone: consult?.clientTimezone || "Europe/Amsterdam",
    },
    onSubmit: async () => {
      formikDetails.values.startedAt = dayjs(
        formikDetails.values.startedAt,
      ).toISOString();

      try {
        formikDetails.setSubmitting(true);
        // update the existing consult
        if (!consult?.id) {
          await createConsult({ ...formikDetails.values }).then(() => {
            refetch();
          });
        } else {
          await updateConsult({
            ...formikDetails.values,
            id: consult?.id,
          }).then(() => {
            refetch();
          });
        }

        setIsCancelEditingDialog(false);
        onClose();
      } catch (e) {
        console.error(e);
      } finally {
        formikDetails.setSubmitting(false);
      }
    },
  });

  // [Invoke] If the main dialog cancel button is pressed
  // If the form has not been edited, turn off edit view and do nothing
  // If the form has been edited, show the CancelWhileEditModal dialog
  const handleCancelButtonDialog = () => {
    if (!formikDetails.dirty) {
      onClose();
    } else {
      setIsCancelEditingDialog(true);
    }
  };

  // for use within CancelWhileEditModal
  // if the user cancels without saving, reset the form and turn off editing
  const handleCancelWithoutSaving = () => {
    formikDetails.resetForm();
    setIsCancelEditingDialog(false);
    onClose();
  };

  const handleKeyPress = (event) => {
    if (event.key === "Escape") handleCancelButtonDialog();
  };

  const onSubmitMainDialog = async () => {
    // if the form has not been changed for an existing consult
    if (!formikDetails.dirty && !!consult) {
      onClose();
      return;
    }

    await formikDetails.submitForm();
  };

  return (
    <Dialog
      open={isOpen}
      scroll="paper"
      className={classes.dialogContainer}
      classes={{ paper: classes.dialogPaper }}
      onKeyDown={handleKeyPress}
    >
      <Header
        headerStyle=""
        rightChildren={<div />}
        centerChildren={<div />}
        leftChildren={
          <TypographyWithIcon
            variant="h6"
            iconLigature="question_answer"
            boxClass={classes.sessionDetailsTitle}
          >
            {t("Consults.SessionDetails")}
          </TypographyWithIcon>
        }
      />
      <DialogContent dividers className={classes.dialogContentContainer}>
        <Divider light />
        <SessionDetailsEditing
          formikDetails={formikDetails}
          isNewConsult={!consult}
        />
      </DialogContent>
      <DialogActions style={{ justifyContent: "space-between" }}>
        <Footer
          footerStyle=""
          leftChildren={
            <SecondaryButton value="Cancel" action={handleCancelButtonDialog} />
          }
          centerChildren={<div />}
          rightChildren={
            <PrimaryButton
              disabled={
                formikDetails.isSubmitting ||
                !validationSchemaSessionDetails.isValidSync(
                  formikDetails.values,
                )
              }
              value="Submit"
              action={onSubmitMainDialog}
              type="submit"
            />
          }
        />
      </DialogActions>
      <CancelWhileEditModal
        isOpen={isCancelEditingDialog}
        handleCancelWithoutSaving={handleCancelWithoutSaving}
        handleContinueEditing={() => setIsCancelEditingDialog(false)}
      />
    </Dialog>
  );
};

EditSessionDetailsDialog.propTypes = {
  clientId: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  consult: PropTypes.object,
  refetch: PropTypes.func,
  onClose: PropTypes.func,
};

EditSessionDetailsDialog.defaultProps = {
  isOpen: false,
  consult: {},
  refetch: () => {},
  onClose: () => {},
};

export default withSnackbar(EditSessionDetailsDialog);
