declare global {
  interface Window {
    env: any;
  }
}

class EnvType {
  REACT_APP_GOOGLE_REFRESH_TOKEN: string = "";

  REACT_APP_GOOGLE_CLIENT_ID: string = "";

  REACT_APP_API_SUBDOMAIN: string = "";

  REACT_APP_APPINSIGHTS_CONNECTION_STRING: string = "";

  REACT_APP_VERSION: string = "";

  REACT_APP_CHAT_FEATURE_ENABLED: boolean = false;
}

const env: any =
  typeof window !== "undefined" && window.env
    ? { ...processEnv, ...window.env }
    : processEnv;

const typedEnv = new EnvType();
Object.keys(typedEnv).forEach((key: string) => {
  if (
    typeof env[key] !== "undefined" &&
    typeof env[key] !==
      typeof Object.entries(typedEnv).find(([k]) => k === key)?.[0]
  ) {
    env[key] = JSON.parse(env[key]);
  }
});

export { env };
