import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import PersonAddOutlined from "@mui/icons-material/PersonAddOutlined";
import AddOrUpdateClientDialog from "./AddOrUpdateClientDialog";

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: 24,
    padding: theme.spacing(0, 4),
    textTransform: "none",
  },
}));

const AddClientButton = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <Button
        className={classes.button}
        variant="contained"
        color="secondary"
        startIcon={<PersonAddOutlined />}
        onClick={() => setOpen(true)}
      >
        {t("Clients.Add")}
      </Button>
      <AddOrUpdateClientDialog open={open} onClose={() => setOpen(false)} />
    </>
  );
};

export default AddClientButton;
