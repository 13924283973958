import { Button, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import PersonAddOutlined from "@mui/icons-material/PersonAddOutlined";
import { colors } from "@src/theme";
import AddPsychologistDialog from "./AddPsychologistDialog";

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: 24,
    padding: theme.spacing(0, 4),
    textTransform: "none",
    "&:hover": {
      backgroundColor: colors.primaryBlue,
    },
  },
  buttonText: {
    color: "white",
  },
}));

const AddPsychologistButton = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <Button
        className={classes.button}
        variant="contained"
        color="secondary"
        startIcon={<PersonAddOutlined />}
        onClick={() => setOpen(true)}
      >
        <Typography variant="subtitle2" className={classes.buttonText}>
          {t("Psychologist.Add")}
        </Typography>
      </Button>
      <AddPsychologistDialog open={open} onClose={() => setOpen(false)} />
    </>
  );
};

export default AddPsychologistButton;
