import { Box, Button, Icon, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { hasGrantedAnyScopeGoogle, useGoogleLogin } from "@react-oauth/google";
import GoogleLogo from "@src/components/images/google-logo.svg";
import { withSnackbar } from "@src/components/SnackBarComponent";
import { timeSnackbar } from "@src/constants";
import authenticationService from "@src/services/authentication.service";
import { colors } from "@src/theme";
import LoginContainer from "./LoginContainer";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    marginTop: theme.spacing(3),
  },
  startIcon: {
    display: "flex",
    alignItems: "center",
    overflow: "visible",
  },
  titleTextBox: {
    marginBottom: theme.spacing(3),
  },
  buttonText: {
    fontFamily: "Roboto, sans-serif",
    letterSpacing: "1.25px",
  },
  button: {
    borderRadius: 28,
    border: `2px solid ${colors.primaryDarkBlue}`,
    width: 360,
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "white",
    },
  },
  signUpTextBox: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(1),
  },
}));

const WelcomePage = ({ moveToNextStep, ...props }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const calendarScope = "https://www.googleapis.com/auth/calendar";

  const onFailure = async (error) => {
    console.error(error);
    props.snackbarShowMessage(error.error, "error", 10000);
  };

  const onSuccessLogin = async (result) => {
    await authenticationService
      .loginExternalUser(result.code, "Google")
      .then((response) => {
        props.snackbarShowMessage(response?.message || "", "success");
        setTimeout(() => {
          moveToNextStep();
        }, timeSnackbar);
      })
      .catch((error) => {
        props.snackbarShowMessage(error);
      });
  };

  const onSuccessSignUp = async (res) => {
    await authenticationService
      .registerExternalUser(res.code, "Google")
      .then((response) => {
        props.snackbarShowMessage(response?.message || "", "success");
        setTimeout(() => {
          moveToNextStep();
        }, timeSnackbar);
      })
      .catch((error) => {
        props.snackbarShowMessage(error);
      });
  };

  const checkScopeOnLogin = async (result) => {
    const hasAccess = hasGrantedAnyScopeGoogle(result, calendarScope);
    if (hasAccess) {
      await onSuccessLogin(result);
    } else {
      props.snackbarShowMessage(t("WelcomePage.Error"), "error", 600000);
    }
  };

  const checkScopeOnSignUp = async (result) => {
    const hasAccess = hasGrantedAnyScopeGoogle(result, calendarScope);
    if (hasAccess) {
      await onSuccessSignUp(result);
    } else {
      props.snackbarShowMessage(t("WelcomePage.Error"), "error", 600000);
    }
  };

  const logInPsychologist = useGoogleLogin({
    onSuccess: (response) => checkScopeOnLogin(response),
    scope: calendarScope,
    flow: "auth-code",
    onError: (error) => onFailure(error),
  });

  const signUpPsychologist = useGoogleLogin({
    onSuccess: (response) => checkScopeOnSignUp(response),
    scope: calendarScope,
    flow: "auth-code",
    onError: (error) => onFailure(error),
  });

  return (
    <LoginContainer title={t("LoginView.Welcome")}>
      <Box className={classes.container}>
        <Box className={classes.titleTextBox}>
          <Typography variant="body1">
            {t("LoginView.WelcomePage.Text")}
          </Typography>
        </Box>
        <Button
          className={classes.button}
          size="large"
          startIcon={
            <Icon className={classes.startIcon}>
              <img alt="" src={GoogleLogo} height="22px" width="22px" />
            </Icon>
          }
          onClick={() => logInPsychologist()}
        >
          <Typography variant="subtitle2" className={classes.buttonText}>
            {t("LoginView.WelcomePage.LoginGoogle")}
          </Typography>
        </Button>

        <Box className={classes.signUpTextBox}>
          <Typography variant="body1">
            {t("LoginView.WelcomePage.NewAtOpenUp")}
          </Typography>
        </Box>

        <Button
          className={classes.button}
          size="large"
          startIcon={
            <Icon className={classes.startIcon}>
              <img alt="Google" src={GoogleLogo} height="22px" width="22px" />
            </Icon>
          }
          onClick={() => signUpPsychologist()}
        >
          <Typography variant="subtitle2" className={classes.buttonText}>
            {t("LoginView.WelcomePage.GoogleRegister")}
          </Typography>
        </Button>
      </Box>
    </LoginContainer>
  );
};

export default withSnackbar(WelcomePage);
