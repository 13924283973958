import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const FormikDatePicker = ({ name, formik, readOnly = false }) => {
  const { t } = useTranslation();

  return (
    <div data-cy="date-picker">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          data-cy="date-picker"
          label={t("Common.Date")}
          onChange={(newValue) =>
            newValue.isValid() &&
            formik.setFieldValue(name, newValue.toISOString())
          }
          value={dayjs(formik.values.startedAt)}
          readOnly={readOnly}
          format="DD/MM/YYYY"
        />
      </LocalizationProvider>
    </div>
  );
};

export default FormikDatePicker;
