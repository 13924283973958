import { Button, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import clsx from "clsx";

const useStyles = makeStyles(({ colors, spacing }) => ({
  buttonContainer: {
    borderColor: colors.primaryBlack,
    color: colors.primaryBlack,
    border: "2px solid",
    borderRadius: "100px",
    padding: spacing(0.1, 2, 0.1),
    margin: spacing(2, 0, 2, 3),
  },
  buttonText: {
    fontWeight: 500,
    fontSize: "14px",
  },
}));

/**
 * Modal for activating Two Factor Authentication
 */
export const SecondaryButton = ({
  value,
  action,
  additionalStylesButtonContainer,
  additionalStylesButtonText,
  icon,
}) => {
  const classes = useStyles();

  return (
    // TODO detect if form has changed, and show dialog if it has
    <Button
      className={clsx(classes.buttonContainer, additionalStylesButtonContainer)}
      onClick={action}
    >
      {!!icon && icon}
      <Typography
        className={clsx(classes.buttonText, additionalStylesButtonText)}
      >
        {value}
      </Typography>
    </Button>
  );
};

SecondaryButton.propTypes = {
  value: PropTypes.string,
  action: PropTypes.func,
  additionalStylesButtonContainer: PropTypes.string,
  additionalStylesButtonText: PropTypes.string,
  icon: PropTypes.element,
};

SecondaryButton.defaultProps = {
  value: "",
  action: () => {},
  additionalStylesButtonContainer: "",
  additionalStylesButtonText: "",
  icon: null,
};
