/* eslint-disable react/prop-types */
import { Box, Select, MenuItem, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { TypographyWithIcon } from "@src/components/utils/TypographyWithIcon";
import { colors } from "@src/theme";
import { formatFirstUpperCase } from "@src/utils/formatting";
import { getLanguageNameBasedOnAbbreviation } from "@src/utils/languageHelpers";
import { useGetTimezones } from "@src/queries/scheduler";
import { findClosesTimezoneInListForSpecificTimezone } from "@src/utils/timezone";

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  details: {
    display: "flex",
    alignItems: "baseline",
    justifyContent: "space-between",
    marginTop: spacing(5),
    marginBottom: spacing(5),
    [breakpoints.down("xs")]: {
      display: "unset",
    },
  },
  textBox: {
    display: "flex",
    [breakpoints.down("xs")]: {
      display: "unset",
    },
  },

  boxTypography: {
    marginBottom: spacing(0),
    [breakpoints.down("xs")]: {
      marginTop: spacing(4),
      marginBottom: spacing(2.5),
    },
  },
  boxTypographyFlag: {
    marginBottom: spacing(0),
    marginLeft: spacing(2.625),
    [breakpoints.down("xs")]: {
      marginLeft: spacing(0),
      marginBottom: spacing(2.5),
    },
  },
  menuItem: {
    paddingLeft: spacing(1),
  },
  selectPlaceholderText: {
    color: colors.primaryDarkBlue,
    "&::placeholder": {
      opacity: 1,
    },
    marginLeft: spacing(1),
    marginRight: spacing(2),
  },
  selectEmpty: {
    borderRadius: "16px",
    height: 32,
    width: 250,
    [breakpoints.down("xs")]: {
      marginBottom: spacing(3),
    },
    "&>div": {
      fontSize: "12px",
      lineHeight: "16px",
      letterSpacing: "0.4px",
      backgroundColor: colors.lightGray,
      "&:focus": {
        backgroundColor: colors.lightGray,
      },
    },
  },
}));

const TimeZoneSelector = ({
  sessionType,
  language,
  firstName,
  timeZoneValue,
  setTimeZoneValue,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { data: timezones } = useGetTimezones();
  const handleChange = (event) => {
    setTimeZoneValue(event.target.value);
  };

  const getTimeZoneName = () => {
    const timezone = findClosesTimezoneInListForSpecificTimezone(
      timezones,
      timeZoneValue,
    );
    const showNameLength = 20;
    if (timezone.name.length > showNameLength) {
      return `${timezone.name.substring(0, showNameLength)} ...`;
    }
    return timezone.name;
  };

  return (
    <Box className={classes.details}>
      <Box className={classes.textBox}>
        {!!firstName && (
          <TypographyWithIcon
            iconLigature="person"
            outlined
            typographyClass={classes.typography}
            boxClass={classes.boxTypography}
          >
            {t("Booking.TimeSlot.Psychologist", { key: firstName })}
          </TypographyWithIcon>
        )}
        <TypographyWithIcon
          iconLigature="spa"
          outlined
          typographyClass={classes.typography}
          boxClass={classes.boxTypographyFlag}
        >
          {t("Booking.TimeSlot.SessionType", {
            key: formatFirstUpperCase(sessionType),
          })}
        </TypographyWithIcon>
        <TypographyWithIcon
          iconLigature="flag"
          outlined
          boxClass={classes.boxTypographyFlag}
        >
          {t("Booking.TimeSlot.Language", {
            key: getLanguageNameBasedOnAbbreviation(t, language),
          })}
        </TypographyWithIcon>
      </Box>

      <Select
        variant="standard"
        value={timeZoneValue}
        className={classes.selectEmpty}
        onChange={handleChange}
        SelectDisplayProps={{
          style: {
            borderRadius: 30,
            paddingLeft: 20,
            paddingTop: 5,
          },
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        }}
        sx={{
          "&:before, &:after": {
            display: "none",
          },
        }}
        renderValue={() => {
          return (
            <Box sx={{ display: "flex", paddingTop: 0 }}>
              <Typography
                variant="body2"
                className={classes.selectPlaceholderText}
              >
                {getTimeZoneName()}
              </Typography>
            </Box>
          );
        }}
      >
        {timezones.map((timezone) => (
          <MenuItem
            value={timezone.zoneId}
            className={classes.menuItem}
            key={timezone.zoneId}
          >
            <Typography variant="caption">{timezone.name}</Typography>
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};

export default TimeZoneSelector;
