import axios from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { queryIdentifiers } from "@src/utils/constants";
import { apiPaths } from "@src/constants";
import { hours } from "./staletimes";

export const useGetNotificationsQuery = () => {
  return useQuery(
    [queryIdentifiers.notifications],
    async () => {
      const { data } = await axios.get(apiPaths.notifications);
      return data;
    },
    {
      staleTime: hours(1),
    },
  );
};

export const useReadNotificationMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (notification: { id: number }) => {
      return axios.post(`${apiPaths.notifications}/${notification.id}`);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([queryIdentifiers.notifications]);
      },
    },
  );
};
