import { Box, FormControl, FormGroup, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { TAG_TYPES } from "@src/constants";
import { colors } from "@src/theme";
import FormControlLabelComponent from "./FormControlLabelComponent";

const useStyles = makeStyles((theme) => ({
  formItem: {
    width: "100%",
  },
  tagBox: {
    marginBottom: theme.spacing(2),
  },
  tagTypeClass: {
    color: colors.primaryBlue,
  },
  error: {
    color: colors.red,
    marginLeft: theme.spacing(4),
  },
}));

const TagSelector = ({
  tagTypeName,
  tagState,
  setTagState,
  tagType,
  singleChoice = false,
  error = false,
  formikValue = false,
  formik = false,
}) => {
  const classes = useStyles();
  const [isChecked, setIsChecked] = useState(false);
  const { t } = useTranslation();

  const arrayContains = (array, value) => {
    return array.indexOf(value) > -1;
  };

  const tagChanged = (event) => {
    const {
      target: { value },
    } = event;

    const state = tagState;
    if (state.length === 0) {
      state.push(value);
    } else if (!singleChoice) {
      if (arrayContains(state, value)) {
        state.splice(state.indexOf(value), 1);
      } else {
        state.push(value);
      }
    } else if (arrayContains(state, value)) {
      state.splice(state.indexOf(value), 1);
    } else {
      state.pop();
      state.push(value);
    }
    formik && formik.setFieldValue(formikValue, state.length > 0 ? value : "");
    setTagState(state);
  };

  const getFormControlLabelComponent = (tag) => {
    return (
      <FormControlLabelComponent
        key={tag.name}
        tag={tag}
        tagChanged={tagChanged}
        singleChoice={singleChoice}
        tagState={tagState}
        isChecked={isChecked}
        setIsChecked={setIsChecked}
      />
    );
  };

  return (
    <Box className={classes.tagBox}>
      <Typography variant="caption" className={classes.tagTypeClass}>
        {tagTypeName}
      </Typography>
      <FormControl variant="standard" className={classes.formItem} required>
        {tagType.name === TAG_TYPES.PSYCHOLOGIST_LANGUAGE ||
        tagType.name === TAG_TYPES.SESSION_LANGUAGE ? (
          <Grid container>
            <Grid item xs={6}>
              <FormGroup>
                {tagType.tags
                  .slice(0, Math.ceil(tagType.tags.length / 2))
                  .map((tag) => getFormControlLabelComponent(tag))}
                {error && (
                  <Typography variant="caption" className={classes.error}>
                    {t(`Consults.Error.${tagType.name}`)}
                  </Typography>
                )}
              </FormGroup>
            </Grid>
            <Grid item xs={6}>
              <FormGroup>
                {tagType.tags
                  .slice(Math.ceil(tagType.tags.length / 2))
                  .map((tag) => getFormControlLabelComponent(tag))}
              </FormGroup>
            </Grid>
          </Grid>
        ) : (
          <FormGroup>
            {tagType.tags.map((tag) => getFormControlLabelComponent(tag))}
            {error && (
              <Typography variant="caption" className={classes.error}>
                {t(`Consults.Error.${tagType.name}`)}
              </Typography>
            )}
          </FormGroup>
        )}
      </FormControl>
    </Box>
  );
};

export default TagSelector;
