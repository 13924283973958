import { Dialog, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import PsychologistSelectorTable from "@src/components/PsychologistSelectorTable";
import {
  useReassignPsychologistMutation,
  usePsychologistsQuery,
} from "@src/queries/experts";
import { DialogActionButtons, DialogHeader } from "./DialogToolkit";
import { withSnackbar } from "./SnackBarComponent";

const useStyles = makeStyles(() => ({
  dialog: {
    maxHeight: 655,
    width: 600,
  },
}));

const SwapPsychologistDialog = ({ setIsOpen, consult, ...props }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [selectedPsychologist, setSelectedPsychologist] = useState();
  const [isFirstStepOpen, setIsFirstStepOpen] = useState(true);

  const {
    data: { data: allPsychologists },
  } = usePsychologistsQuery({ limit: 10000 });

  const { mutateAsync: reassignPsychologist } =
    useReassignPsychologistMutation();

  const onSelection = () => {
    if (!selectedPsychologist) {
      props.snackbarShowMessage(t("ReassignPsychologist.Error"));
      return;
    }
    setIsFirstStepOpen(false);
  };

  const onSubmit = async () => {
    await reassignPsychologist({
      userId: selectedPsychologist.id,
      consultId: consult.id,
    });
    setIsOpen(false);
  };

  return (
    <>
      <Dialog open={isFirstStepOpen} classes={{ paper: classes.dialog }}>
        <DialogHeader
          iconLigature="person_add"
          title={t("ReassignPsychologist.Title")}
          outlined
        />

        <PsychologistSelectorTable
          psychologists={allPsychologists}
          setSelectedPsychologist={setSelectedPsychologist}
          selectedPsychologist={selectedPsychologist}
        />

        <DialogActionButtons
          onSubmitText={t("SharedStrings.Reassign").toUpperCase()}
          onCancelText={t("SharedStrings.Cancel").toUpperCase()}
          onCancel={() => setIsOpen(false)}
          onSubmit={onSelection}
        />
      </Dialog>
      <Dialog open={!isFirstStepOpen} classes={{ paper: classes.dialog }}>
        <Typography variant="body2">
          {t("ReassignPsychologist.Confirmation")}
        </Typography>
        <DialogActionButtons
          onSubmitText={t("SharedStrings.Confirm").toUpperCase()}
          onCancelText={t("SharedStrings.Discard").toUpperCase()}
          onCancel={() => setIsFirstStepOpen(true)}
          onSubmit={onSubmit}
        />
      </Dialog>
    </>
  );
};

export default withSnackbar(SwapPsychologistDialog);
