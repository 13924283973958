import { TypographyWithIcon } from "@src/components/utils/TypographyWithIcon";
import { Dialog, DialogActions, DialogContent } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useFormik } from "formik";
import { validationSchemaSessionNotes } from "@src/utils/validationHelpers";
import { withSnackbar } from "@src/components/SnackBarComponent";
import { RESPONSE_STATUS, TAG_TYPES } from "@src/constants";
import { createUpdateNotesRequestObject } from "@src/utils/helpers";
import { useConsultTagsQuery } from "@src/queries/tags";
import { useConsultNotesMutation } from "@src/queries/consults";
import SessionDetailsPaper from "./SessionDetailsPaper";
import CancelWhileEditModal from "./components/CancelWhileEditModal";
import SessionNotesEditing from "./SessionNotesEditing";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { SecondaryButton } from "./components/SecondaryButton";
import { PrimaryButton } from "./components/PrimaryButton";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    padding: 0,
  },
  sessionDetailsTitle: {
    padding: theme.spacing(3, 0, 0, 2),
  },
  dialogContentContainer: {
    padding: 0,
  },
}));

const EditSessionNotesDialog = ({
  isOpen,
  consult,
  onClose,
  refetch,
  ...props
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { mutateAsync: updateOrCreateNote } = useConsultNotesMutation(
    consult.user.id,
    consult.id,
  );
  const { consultTags: consultTopics, saveTags } = useConsultTagsQuery(
    consult.user.id,
    consult.id,
    TAG_TYPES.TOPICS,
  );

  const [isCancelEditingDialog, setIsCancelEditingDialog] = useState(false);

  const showSessionNotesErrorSnackbar = (e) => {
    if (
      e.response?.status === RESPONSE_STATUS.BAD_REQUEST ||
      e.response?.status === RESPONSE_STATUS.NOT_FOUND
    ) {
      props.snackbarShowMessage(e.response.data);
    } else {
      props.snackbarShowMessage(t("Consults.SessionDetails.UnhandledError"));
    }
  };

  const dossierNotesSection = (currentConsult, section, defaultAnswer = "") =>
    currentConsult?.dossierNotes?.filter((c) => c.question === section).shift()
      ?.answer || defaultAnswer;

  const formikNotes = useFormik({
    enableReinitialize: true,
    initialValues: {
      consultKey: consult.id || null, // to distinguish between sessions with the same properties
      themeKey: consult.themeKey || "",
      topics: consultTopics,
      reasonForBooking: dossierNotesSection(consult, "reasonBook"),
      discussedInSession:
        dossierNotesSection(consult, "sessionDiscussed") || consult?.notes,
      canWeHelp: dossierNotesSection(consult, "helpNeeded", "true"),
      referredToDifferentHealthProvider: dossierNotesSection(
        consult,
        "provider",
      ),
      healthProviderName: dossierNotesSection(consult, "providerName"),
      nextSteps: dossierNotesSection(consult, "nextSteps"),
    },
    validationSchema: validationSchemaSessionNotes,
    onSubmit: async (values) => {
      if (!formikNotes.dirty) {
        return;
      }

      const newNotes = createUpdateNotesRequestObject(values);

      try {
        formikNotes.setSubmitting(true);
        // call API endpoint to save notes
        await updateOrCreateNote(newNotes).then(() => {
          onClose();
          refetch();
        });

        await saveTags({
          tags: values.topics.map((topic) => topic.name),
          id: consult.id,
        });
      } catch (e) {
        showSessionNotesErrorSnackbar(e);
      } finally {
        formikNotes.setSubmitting(false);
      }
    },
  });

  const handleCancelButtonDialog = () => {
    if (formikNotes.dirty) {
      setIsCancelEditingDialog(true);
    } else {
      onClose();
    }
  };

  const handleContinueEditing = () => {
    setIsCancelEditingDialog(false);
  };

  const handleCancelWithoutSaving = () => {
    setIsCancelEditingDialog(false);
    formikNotes.resetForm();
    onClose();
  };

  const handleKeyPress = (event) => {
    if (event.key === "Escape") handleCancelButtonDialog();
  };

  const onSubmitMainDialog = async () => {
    if (!formikNotes.dirty) {
      onClose();
      return;
    }

    await formikNotes.submitForm();
    refetch();
  };

  return (
    <Dialog
      open={isOpen}
      scroll="paper"
      classes={{ paper: classes.dialogPaper }}
      onKeyDown={handleKeyPress}
      data-cy="session-notes-editing"
      fullWidth
      maxWidth="md"
    >
      <Header
        headerStyle=""
        rightChildren={<div />}
        centerChildren={<div />}
        leftChildren={
          <TypographyWithIcon
            variant="h6"
            iconLigature="question_answer"
            boxClass={classes.sessionDetailsTitle}
          >
            {t("Consults.SessionDetails.Notes")}
          </TypographyWithIcon>
        }
      />
      <DialogContent dividers className={classes.dialogContentContainer}>
        <hr className="border-t border-slate-300" />
        <SessionNotesEditing
          consult={consult}
          onClose={onClose}
          formikNotes={formikNotes}
        />
      </DialogContent>
      <DialogActions style={{ justifyContent: "space-between" }}>
        <Footer
          footerStyle=""
          leftChildren={
            <SecondaryButton value="Cancel" action={handleCancelButtonDialog} />
          }
          centerChildren={<div />}
          rightChildren={
            <PrimaryButton
              disabled={
                formikNotes.isSubmitting ||
                !validationSchemaSessionNotes.isValidSync(formikNotes.values)
              }
              value="Submit"
              type="Submit"
              action={onSubmitMainDialog}
            />
          }
        />
      </DialogActions>
      <CancelWhileEditModal
        isOpen={isCancelEditingDialog}
        handleCancelWithoutSaving={handleCancelWithoutSaving}
        handleContinueEditing={handleContinueEditing}
      />
      <SessionDetailsPaper isFixed consult={consult} topics={consultTopics} />
    </Dialog>
  );
};

export default withSnackbar(EditSessionNotesDialog);
