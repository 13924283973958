import {
  MDXEditorMethods,
  MDXEditor,
  MDXEditorProps,
  listsPlugin,
  quotePlugin,
  toolbarPlugin,
  UndoRedo,
  BoldItalicUnderlineToggles,
  ListsToggle,
} from "@mdxeditor/editor";
import React from "react";

const ToolbarContents = () => (
  <>
    <UndoRedo />
    <BoldItalicUnderlineToggles />
    <ListsToggle options={["bullet", "number"]} />
  </>
);

const DefaultMarkdownEditor: React.FC<MDXEditorProps> = (props) => {
  const ref = React.useRef<MDXEditorMethods>(null);
  const { plugins = [] } = props;

  plugins.push(
    listsPlugin(),
    quotePlugin(),
    toolbarPlugin({
      toolbarContents: ToolbarContents,
    }),
  );
  return <MDXEditor ref={ref} {...props} plugins={plugins} />;
};

export default DefaultMarkdownEditor;
