import axios from "axios";
import { useQuery } from "react-query";
import { apiPaths, SESSION_TYPE } from "@src/constants";
import { Theme } from "@src/models/Theme";

export const useThemesQuery = () =>
  useQuery([apiPaths.themes], () =>
    axios
      .get<
        Theme[]
      >(`${apiPaths.themes}?session_types=${SESSION_TYPE.MEDICAL}&session_types=${SESSION_TYPE.GENERAL}&session_types=${SESSION_TYPE.MINDFULNESS}&session_types=${SESSION_TYPE.PHYSICAL_WELLBEING}`)
      .then(({ data }) => data),
  );
