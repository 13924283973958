import {
  Breadcrumbs as MuiBreadcrumbs,
  Typography,
  Icon as MUIIcon,
  Box,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";

const useStyles = makeStyles(({ spacing }) => ({
  backButton: {
    opacity: 0.7,
    letterSpacing: 0.1,
    textDecoration: "none",
  },
  link: {
    textDecoration: "none",
  },
  firstIcon: {
    marginRight: spacing(1),
  },
}));

const Icon = ({ className }) => (
  <MUIIcon className={className}>chevron_left</MUIIcon>
);

export const Breadcrumbs = ({ crumbs }) => {
  const classes = useStyles();
  return (
    <Box display="flex" alignItems="center" className={classes.wrapper}>
      <Icon className={classes.firstIcon} />
      <MuiBreadcrumbs separator={<Icon />}>
        {crumbs.map(({ link, text }) => (
          <Link key={link} to={link} className={classes.link}>
            <Typography className={classes.backButton}>{text}</Typography>
          </Link>
        ))}
      </MuiBreadcrumbs>
    </Box>
  );
};
