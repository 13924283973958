import moment from "moment";
import {
  HELP_NEEDED_VALUES,
  NEXT_STEPS,
  QUESTIONS_KEYS,
  THEME_KEYS,
} from "@src/constants";
import { upperFirst } from "lodash";
import { format } from "date-fns-tz";
import { DateFnslocaleMapper } from "@src/DateLocaleSetup";

export const formatUserName = (user, showExtraData = false) => {
  if (!user) {
    return undefined;
  }
  const { firstName, lastName, yearOfBirth } = user;
  let formattedUserName = `${lastName}, ${firstName}`;
  if (showExtraData) {
    formattedUserName = `${formattedUserName} (${yearOfBirth || "-"})`;
  }

  return formattedUserName;
};

export const formatFirstUpperCase = (word) => {
  return word ? word.charAt(0).toUpperCase() + word.slice(1) : "";
};

export const formatNameAvatar = (firstName, lastName) => {
  return (
    firstName.charAt(0).toUpperCase() +
    (lastName ? lastName.charAt(0).toUpperCase() : "")
  );
};

export const formatLanguage = (shortTermLanguage) => {
  switch (shortTermLanguage) {
    case "EN":
      return "English";
    case "NL":
      return "Netherlands";
    default:
      return "English";
  }
};

export const dateFormat = "YYYY-MM-DDTHH:mm:ssZ";

export const formatSessionDateForSessionDetails = (consult) => {
  return moment(consult.startedAt, dateFormat)
    .tz(moment.tz.guess())
    .format("HH:mm - DD-MM-YYYY");
};

export const formatStandardDate = (date: Date, timeZone: string) => {
  return format(date, "dd-MM-yy HH:mm", {
    locale: DateFnslocaleMapper(),
    timeZone,
  });
};

export const formatClientName = (client) => {
  const lastName = client.lastName ? ` ${client.lastName}` : "";
  return `${client.firstName}${lastName}`;
};

export const formatConsultDateExtendedWritten = (consult) => {
  return moment(consult.startedAt, dateFormat)
    .tz(moment.tz.guess())
    .format("MMMM D, YYYY");
};

export const moveForwardWeeks = (weeks) => {
  return moment().utc().startOf("isoWeek").add(weeks, "weeks");
};

export const moveBackwardWeeks = (weeks) => {
  return moment().utc().endOf("isoWeek").add(weeks, "weeks");
};

export const formatWeek = (time) => {
  return time.format("D MMM");
};

export const formatWeekWithYear = (time) => {
  return time.format("D MMM YYYY");
};

export const getYearTime = (time) => {
  return moment(time).year();
};

export const getWeekday = (time) => {
  return moment(time).format("ddd");
};

export const formatDateWithMonth = (time) => {
  return moment(time).utc().format("D MMM");
};

export const getWeekStartQueryKey = (dateTime) =>
  moment(dateTime).utc().startOf("isoWeek").startOf("day").toISOString();

export const helpNeededFormatting = (t, helpValue) => {
  switch (helpValue) {
    case HELP_NEEDED_VALUES.TRUE: {
      return t("Consults.SessionDetails.HelpClient.Yes");
    }
    case HELP_NEEDED_VALUES.NO_REFER_TO_IPRACTICE: {
      return t("Consults.SessionDetails.HelpClient.noReferToiPractice");
    }
    case HELP_NEEDED_VALUES.NO_REFER_TO_OTHER_PROVIDER: {
      return t("Consults.SessionDetails.HelpClient.noReferToOtherProvider");
    }
    case HELP_NEEDED_VALUES.NO_CLIENT_AT_RISK: {
      return t("Consults.SessionDetails.HelpClient.ClientAtRisk");
    }
    default: {
      return t("Consults.SessionDetails.HelpClient.NoInput");
    }
  }
};

export const handleNextStepsText = (t, nextSteps) => {
  switch (nextSteps) {
    case NEXT_STEPS.WEEKS: {
      return t("Consults.SessionDetails.NextSteps.Weeks");
    }
    case NEXT_STEPS.MONTHS: {
      return t("Consults.SessionDetails.NextSteps.Months");
    }
    case NEXT_STEPS.BREAK: {
      return t("Consults.SessionDetails.NextSteps.Break");
    }
    default: {
      return t("Consults.SessionDetails.HelpClient.NoInput");
    }
  }
};

export const themeValue = (value) => {
  switch (value) {
    case QUESTIONS_KEYS.CONFIDENCE:
      return THEME_KEYS.CONFIDENCE;
    case QUESTIONS_KEYS.STRESS:
      return THEME_KEYS.STRESS;
    case QUESTIONS_KEYS.WORK:
      return THEME_KEYS.WORK;
    case QUESTIONS_KEYS.PURPOSE:
      return THEME_KEYS.PURPOSE;
    case QUESTIONS_KEYS.RELATIONSHIPS:
      return THEME_KEYS.RELATIONSHIPS;
    case QUESTIONS_KEYS.HEALTH:
      return THEME_KEYS.HEALTH;
    case QUESTIONS_KEYS.SLEEP:
      return THEME_KEYS.SLEEP;
    case QUESTIONS_KEYS.MINDFULNESS:
      return THEME_KEYS.MINDFULNESS;
    case QUESTIONS_KEYS.MEDICAL:
      return THEME_KEYS.MEDICAL;
    default:
      return null;
  }
};

export const formatSessionType = (consultType) => {
  return consultType && upperFirst(consultType);
};
