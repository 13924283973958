import { Button, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { colors } from "@src/theme";

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: 24,
    padding: theme.spacing(0, 4),
    textTransform: "none",
    marginRight: theme.spacing(4),
    backgroundColor: colors.primaryDarkBlue,
    "&:hover": {
      backgroundColor: colors.primaryDarkBlue,
    },
  },
  buttonText: {
    color: "white",
  },
}));

const FilterButton = ({ onClick, numberOfFilters = 0 }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Button
      className={classes.button}
      variant="contained"
      color="secondary"
      startIcon={<span className="material-icons-outlined">filter_list</span>}
      onClick={onClick}
    >
      <Typography variant="subtitle2" className={classes.buttonText}>
        {t("Search.Filter")}
        {!!numberOfFilters && ` (${numberOfFilters})`}
      </Typography>
    </Button>
  );
};

export default FilterButton;
