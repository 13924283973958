import moment from "moment";

export const getPsychologistTimezone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export const findClosesTimezoneInListForSpecificTimezone = (
  timezones,
  specificTimezone,
) => {
  let closestTimezone = timezones.find((x) => x.zoneId === specificTimezone);
  if (!closestTimezone) {
    // find closest timezone by UtcOffsetInMinutes
    const utcOffsetInMinutes = getUtcOffsetInMinutes(specificTimezone);

    closestTimezone = timezones.reduce((prev, current) => {
      return Math.abs(current.utcOffsetInMinutes - utcOffsetInMinutes) <
        Math.abs(prev.utcOffsetInMinutes - utcOffsetInMinutes)
        ? current
        : prev;
    });
  }
  return closestTimezone;
};

export const getUtcOffsetInMinutes = (timezone) => {
  const zone = moment.tz(timezone);
  return zone.utcOffset();
};
