import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import moment from "moment";
import { ReactComponent as ChildBoat } from "@src/components/images/child_boat.svg";
import { TypographyWithIcon } from "@src/components/utils/TypographyWithIcon";
import { useCancelSession } from "@src/queries/scheduler";
import { usePsychologistsConsultQuery } from "@src/queries/experts";
import { withSnackbar } from "@src/components/SnackBarComponent";
import {
  timeSnackbar,
  AmsterdamTimezone,
  STATUS,
  RESPONSE_STATUS,
} from "@src/constants";
import theme, { colors } from "@src/theme";
import { formatFirstUpperCase } from "@src/utils/formatting";
import { getLanguageNameBasedOnAbbreviation } from "@src/utils/languageHelpers";
import SessionsContainer from "../SessionsContainer";

const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  card: {
    [breakpoints.down("xs")]: {
      marginBottom: spacing(30),
    },
    borderRadius: "16px",
    backgroundColor: "white",
  },
  boxCard: {
    display: "flex",
    [breakpoints.down("xs")]: {
      display: "block",
    },
  },
  cardContent: {
    flex: 1,
    padding: spacing(4, 3),
    [breakpoints.down("xs")]: {
      padding: spacing(3, 2),
    },
  },
  cardImage: {
    flex: 1,
    "& svg:nth-child(1)": {
      float: "right",
    },
  },
  image: {
    float: "right",
    width: 250,
    height: 200,
    marginTop: theme.spacing(12.125),
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(0),
      width: 150,
      height: 150,
    },
  },
  cardActions: {
    justifyContent: "center",
    display: "block",
    padding: 0,
    borderTop: `solid 2px rgba(21, 35, 97, .05);`,
    width: "100%",
    "& button:not(:first-child)": {
      marginLeft: "auto",
    },
    [theme.breakpoints.down("md")]: {
      position: "fixed",
      bottom: 0,
      right: 0,
      backgroundColor: "#ffffff",
      boxShadow: "0px 0 16px rgba(21, 35, 97, 30% )",
    },
  },
  sessionDetailsMessage: {
    opacity: 1,
    marginBottom: spacing(3),
  },
  box: {
    maxWidth: 240,
  },
  icon: {
    fontSize: "20px",
    marginRight: theme.spacing(2),
    "&>span": {
      fontSize: "20px",
    },
  },
  reasonCancel: {
    opacity: 1,
    marginBottom: spacing(1.5),
  },
  textArea: {
    width: "100%",
    border: 0,
    backgroundColor: colors.primarySkin,
    resize: "none",
  },
  cancelButton: {
    margin: spacing(2),
    width: 504,
    display: "flex",
    marginRight: "auto",
    boxShadow: "none",
    borderRadius: "24px",
    [breakpoints.down("xs")]: {
      width: "100%",
    },
    "&:hover": {
      color: "white",
      backgroundColor: colors.primaryBlue,
      boxShadow: "none",
    },
  },
  cancelButtonText: {
    color: "white",
  },
  boxReason: {
    paddingTop: spacing(4),
    paddingLeft: spacing(3),
    paddingRight: spacing(3),
  },
}));

const CancelSession = ({ ...props }) => {
  const classes = useStyles();
  const { consultId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [reasonCancel, setReasonCancel] = useState("");
  const { mutateAsync: cancelSession } = useCancelSession();
  const [cancelDisabled, setCancelDisabled] = useState(false);

  const { data: consult } = usePsychologistsConsultQuery(consultId);

  const handleChangeTextArea = (event) => {
    setReasonCancel(event.target.value);
  };

  const handleCancelSession = () => {
    cancelSession({ consultId: consult.id, reasonCancel })
      .then(() => {
        // eslint-disable-next-line react/prop-types
        props.snackbarShowMessage("Session canceled", "success");
        setTimeout(() => {
          navigate(-1);
        }, timeSnackbar);
      })
      .catch(({ response }) => {
        if (
          response.status === RESPONSE_STATUS.BAD_REQUEST ||
          response.status === RESPONSE_STATUS.NOT_FOUND
        )
          // eslint-disable-next-line react/prop-types
          props.snackbarShowMessage(response.data);
        // eslint-disable-next-line react/prop-types
        else props.snackbarShowMessage(t("CancelSession.Error"));
      });
  };

  useEffect(() => {
    setCancelDisabled(
      !consult ||
        consult.status.toLowerCase() !== STATUS.SCHEDULED.toLowerCase(),
    );
  }, [consult]);

  return (
    <SessionsContainer title={t("CancelSession.Title")}>
      <Card className={classes.card}>
        <Box className={classes.boxCard}>
          <CardContent className={classes.cardContent}>
            <Typography
              variant="subtitle1"
              className={classes.sessionDetailsMessage}
            >
              {t("CancelSession.SessionDetails", {
                key: consult.user.firstName,
              })}
            </Typography>
            <TypographyWithIcon
              iconLigature="spa"
              outlined
              boxClass={classes.box}
            >
              {t("CancelSession.SessionType", {
                key: formatFirstUpperCase(consult.consultType),
              })}
            </TypographyWithIcon>
            <TypographyWithIcon
              iconLigature="flag"
              outlined
              boxClass={classes.box}
            >
              {t("CancelSession.Language", {
                key: getLanguageNameBasedOnAbbreviation(
                  t,
                  consult.consultLanguage,
                ),
              })}
            </TypographyWithIcon>
            <TypographyWithIcon
              iconLigature="psychology"
              outlined
              boxClass={classes.box}
            >
              {t("CancelSession.Employee", {
                key1: consult.employee.firstName,
                key2: consult.employee.lastName,
              })}
            </TypographyWithIcon>
            <TypographyWithIcon
              iconLigature="event_available"
              outlined
              boxClass={classes.box}
            >
              {moment(consult.startedAt)
                .tz(
                  consult.clientTimezone
                    ? consult.clientTimezone
                    : AmsterdamTimezone,
                )
                .format("dddd Do MMMM")}
            </TypographyWithIcon>
          </CardContent>
          <CardMedia className={classes.cardImage}>
            <ChildBoat className={classes.image} />
          </CardMedia>
        </Box>

        <CardActions className={classes.cardActions}>
          <Box className={classes.boxReason}>
            <Typography variant="subtitle2" className={classes.reasonCancel}>
              {t("CancelSession.ReasonCancel")}
            </Typography>
            <TextareaAutosize
              onChange={(event) => handleChangeTextArea(event)}
              placeholder={t("CancelSession.ReasonCancel.Placeholder")}
              className={classes.textArea}
              minRows={5}
            />
          </Box>
          <Button
            variant="contained"
            className={classes.cancelButton}
            onClick={() => handleCancelSession()}
            disabled={cancelDisabled}
          >
            <Typography
              variant="subtitle2"
              className={classes.cancelButtonText}
            >
              {t("CancelSession.ButtonCancel")}
            </Typography>
          </Button>
        </CardActions>
      </Card>
    </SessionsContainer>
  );
};

export default withSnackbar(CancelSession);
