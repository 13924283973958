import type { FC } from "react";
import SpaOutlinedIcon from "@mui/icons-material/SpaOutlined";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import { SESSION_TYPE } from "@src/utils/constants";
import { formatSessionType } from "@src/utils/formatting";

interface IconSessionTypeProps {
  consultType: string;
}

export const IconSessionType: FC<IconSessionTypeProps> = ({
  consultType,
  ...props
}) => {
  switch (formatSessionType(consultType)) {
    case SESSION_TYPE.MINDFULNESS:
      return <SpaOutlinedIcon {...props} />;
    case SESSION_TYPE.GENERAL:
      return <ChatBubbleOutlineOutlinedIcon {...props} />;
    case SESSION_TYPE.MEDICAL:
      // TODO: Not defined yet
      return null; // Explicitly return null instead of breaking without a return.
    default:
      return <ChatBubbleOutlineOutlinedIcon {...props} />;
  }
};
