import { VideoCallingToken } from "@src/models/VideoCallingToken";
import { apiPathsWithParams } from "@src/constants";
import axios from "axios";
import { UserInCall } from "@src/models";

export const getToken = async (roomId: string) => {
  if (!roomId) {
    return null;
  }
  const response = await axios.get<VideoCallingToken>(
    apiPathsWithParams.getVideoCallToken(roomId),
  );
  return response.data;
};

export const whoElseIsInCall = async (roomId: string) => {
  if (!roomId) {
    return [];
  }
  const response = await axios.get<UserInCall[]>(
    apiPathsWithParams.whoElseIsInCall(roomId),
  );
  return response.data;
};
