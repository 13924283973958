import { Button, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { colors } from "@src/theme";

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: 24,
    padding: theme.spacing(0, 4),
    textTransform: "none",
    marginRight: theme.spacing(4),
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "white",
    },
  },
}));

const ClearSearchButton = ({ refCancel, onChange }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Button
      className={classes.button}
      variant="contained"
      color="secondary"
      startIcon={
        <span
          className="material-icons-outlined"
          style={{ color: colors.primaryDarkBlue }}
        >
          filter_list_off
        </span>
      }
      onClick={() => {
        if (refCancel?.current) {
          refCancel.current.value = null;
          onChange(undefined);
        }
      }}
    >
      <Typography variant="subtitle2">{t("Search.Clear")}</Typography>
    </Button>
  );
};

export default ClearSearchButton;
